import { Form, Row, Col } from 'react-bootstrap';
import './styles.scss';

const PhoneNumber = (props) => {

    return (
        <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Row>
                <Col>
                    <div className="phone-number-wrapper">
                        <label>+61</label>
                        <input type="text"
                            className="phone-number form-control col"
                            value={props.phoneNumber}
                            disabled={props.disabled}
                            onChange={props.UpdatePhoneNumber} />
                        <Form.Text className="text-muted d-none">
                            {props.errorMessage}
                        </Form.Text>
                    </div>
                </Col>
            </Row>

        </Form.Group>
    );
}

export default PhoneNumber;