import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
import "./styles.scss";
import successIcon from "../../Assets/Images/success.gif";

const ResetSuccess = () => {

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();

  // Navigating to login page after 2.5 seconds
  
  setTimeout(() => {
    navigate("/");
  }, 2500);

  // Proceed to sign in Button Function
  function redirectToLogin() {
    navigate("/");
  }

  return (
    <>
      <div className="anonymous-login-main-div active">
        <Col lg={10} className="login-block">
          <div className="successIcon">
            <img src={successIcon} alt="Reset Success" />
          </div>
          <p className="text-center">
            <label className="text-success text-medium">
              Your password has been reset successfully
            </label>
          </p>

          <p className="text-center mt-3 login-back-link">
            <Button
              className="col-12 mb-4"
              variant="primary"
              type="button"
              onClick={redirectToLogin}
            >
              Proceed to Sign In
            </Button>
          </p>
        </Col>
      </div>
    </>
  );
};

export default ResetSuccess;
