import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import "./styles.scss";

const MfaVerification = (props) => {
  return (
    <>
        <div className="anonymous-login-main-div active">
            <Col xl={8} lg={10} md={12} className="login-block">
            <Form className="activation-form" onSubmit={(e)=>{
                e.preventDefault();
                if(props.verificationCode.length == 6){
                props.VerifyCode();
                }
            }}>
                <div>
                <h1>{props.mfaData.twoFactorAuthentication.welcomeText}</h1>
                <p>{props.mfaData.twoFactorAuthentication.teaserText}</p>

                <Form.Group className="mb-3" controlId="userName">
                    <Form.Control
                    className="otp-number"
                    type="number"
                    placeholder="Enter Verification Code"
                    value={props.verificationCode}
                    onChange={(e) => props.setVerificationCode(e.target.value)}
                    />
                </Form.Group>
                </div>
            </Form>
            <div className="mobile-login-btns">
                <div className="w-100">
                {props.verificationCode.length == 6 &&
                    <Button
                    className="col-12 mb-4"
                    variant="primary"
                    type="button"
                    onClick={props.VerifyCode}
                    >
                    Verify
                    </Button>}
                {(props.verificationCode.length < 6 || props.verificationCode.length > 6) && <Button
                    className="col-12 mb-4"
                    type="button"
                    disabled
                    variant="light"
                >
                    Verify
                </Button>}
                <p className="text-center mt-3 login-back-link">
                    <label onClick={() => props.setIsTwoFactorEnabledFlag(false)}>Back to Sign In?</label>
                </p>
                </div>
            </div>
            </Col>
        </div>
    </>
  )
}

export default MfaVerification
