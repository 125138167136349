import React , {useState,useEffect} from 'react';
import {api} from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION ,MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_EDIT_QUANTITY , MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_SUSPEND , MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ACTIVATE , MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ADD_ONS , MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS} from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import Popup from 'reactjs-popup';
import './styles.scss';
import 'reactjs-popup/dist/index.css';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

const GWManageSubsription = (props) => {
    const [tableSub, setTableSub] = useState([]);
    let ColumnName = ["Plan Name (SKU) / Subscription ID", "Billing Term" , "RRP (Inc. GST)", "Price (Ex.GST)" , "Qty", "Status"];
    const [loading, setLoading] = useState(false);

    const [updatedQuantity, setUpdatedQuantity] = useState();
    const [editQuantityError, setEditQuantityError] = useState(false);
    const [isSwitchOnState, setIsSwitchOnState] = useState(false);
    const [minQuantity, setMinQuantity] = useState();
    const [maxQuantity, setMaxQuantity] = useState();
    const [statusConfirmationBox, setStatusConfirmationBox] = useState("");


    const [columnsAddOns, setColumnsAddOns] = useState([]);
    const [tableAddOns, setTableAddOns] = useState([]);
    let ColumnNameAddOns = ["Plan Name", "SKU","Billing Term","RRP (Inc. GST)","Price (Ex. GST)"];
    const [loadingAddOns, setLoadingAddOns] = useState(false);
    const [loadingAddonTable, setLoadingAddonTable] = useState(false);


    const [addOnPlanId, setAddOnPlanId] = useState("");
    const [addOnQuantity, setAddOnQuantity] = useState("");
    const [addOnPONumber, setAddOnPONumber] = useState("");
    const [addOnMinQuantity, setAddOnMinQuantity] = useState();
    const [addOnMaxQuantity, setAddOnMaxQuantity] = useState();
    const [addOnIsChecked, setAddOnIsChecked] = useState(false);
    const [addOnErrorMessage, setAddOnErrorMessage] = useState(false);
    const [loadingConfirmOrder, setLoadingConfirmOrder] = useState(false);
    


    // let isSwitchOn = false;


    const CheckSubscriptionStatus = (a) => {
        console.log(a);
        if(a==="ACTIVE"){
            // isSwitchOn=(true);
            setIsSwitchOnState(true);
            }
        else if(a==="INACTIVE"){
            // isSwitchOn=(false);
            setIsSwitchOnState(false);
        }
    };

    const onSwitchAction = (e) => {
        if(isSwitchOnState==false){
            setStatusConfirmationBox("deactivate-subscription")
        }
        else{
            setStatusConfirmationBox("activate-subscription")
        }
      };

      useEffect(() => {
        console.log(isSwitchOnState)
      }, [isSwitchOnState])


    const GetManageSubscriptionTableData = async () => {
        setLoading(true);
        console.log("started");
        await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION + props.actionSelectionData.SubscriptionId).then(resp => {
            // handle success
            console.log("Result",resp.data);
            setUpdatedQuantity(resp.data.licenseCount);
            setMinQuantity(resp.data.minQuantity);
            setMaxQuantity(resp.data.maxQuantity)
            CheckSubscriptionStatus(resp.data.subscriptionStatus);

            setTableSub({
                "PlanName": resp.data.planName,
                "SubscriptionID": resp.data.vendorSubscriptionId,
                "BillingTerm": resp.data.billingTerm,
                "RRP": resp.data.rrpInclusiveGST,
                "Price": resp.data.customerBuyPrice,
                "Qty": resp.data.licenseCount,
                "Status": resp.data.subscriptionStatus,
                "Plancode":resp.data.planCode,
            })
            console.log("Table Sub",tableSub,resp.data);
            setLoading(false);
            if(resp.data.planType != ("iam" || "add-on")){
                GetManageSubscriptionAddOns();
            }
          })
          .catch(error => {
            // handle error
            console.log("Error");
            setLoading(false);
          });
        };

    useEffect(() => {
        GetManageSubscriptionTableData();
    }, [props.actionSelectionData.SubscriptionId]);


    async function GetManageSubscriptionAddOns() {
        setLoadingAddonTable(true);
      setLoadingAddOns(true);
      await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ADD_ONS , {
        params: {
            subscriptionId: props.actionSelectionData.SubscriptionId,
            pageNo: 1,
            pageSize: 100,
        }
      }).then(resp => {
            console.log("New Data Table :" ,resp.data.content);
            let f = [];
            if (resp.data.content.length > 1) {
              (resp.data.content).map((td) => {
                  f.push
                      (
                          {
                            "Plan Name": td.planName,
                            "SKU": td.planCode,
                            "Billing Term": td.billingTerm,
                            "RRP (Inc. GST)": td.rrpInclusiveTax,
                            "Price (Ex. GST)": td.customerBuyPrice,
                            "Plan ID": td.planId,
                            "MinQuantity": td.minQuantity,
                            "MaxQuantity": td.maxQuantity,
                          }
                      );
              })
            }
            else {
                f.push
                    (
                        {
                          "Plan Name": resp.data.content[0]["planName"],
                          "SKU": resp.data.content[0]["planCode"],
                          "Billing Term":resp.data.content[0]["billingTerm"],
                          "RRP (Inc. GST)": resp.data.content[0]["rrpInclusiveTax"],
                          "Price (Ex. GST)": resp.data.content[0]["customerBuyPrice"],
                          "Plan ID": resp.data.content[0]["planId"],
                          "MinQuantity": resp.data.content[0]["minQuantity"],
                          "MaxQuantity": resp.data.content[0]["maxQuantity"],
                        }
                    );
            }
  
            setTableAddOns(f);         // Setting Table Data
            let d = [];
            ColumnNameAddOns.map((td) => {
                d.push({
                    id: `${td}`,
                    name: `${td}`,
                    // selector: row => row[columnNameRow],
                    selector: row => row[`${td}`],
                    // sortable: true,
                    center: true,
                    wrap: true,
                })
            })
            setColumnsAddOns(d);     // Setting Column Data
            console.log("------", d);
            setLoadingAddOns(false);
      })
    .catch(error => {
      // handle error
      console.log(error);
      setLoadingAddOns(false);
    });
      
      }


      createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
      });
      
      const customStyles = {
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '600',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#29286a',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: 'white',
                textTransform: 'capitalize'
            },
        },
      };

      const handleChangePlanTableAddOn = (rows) => {
        if(rows.selectedCount>0){
          console.log("Rows",rows.selectedRows[0]["Plan ID"]);
          setAddOnPlanId(rows.selectedRows[0]["Plan ID"]);
          setAddOnMinQuantity(rows.selectedRows[0]["MinQuantity"]);
          setAddOnMaxQuantity(rows.selectedRows[0]["MaxQuantity"]);
          setAddOnIsChecked(true);
        }
        }

    const buyAddOn = async () => {
        if(addOnMaxQuantity>=addOnQuantity && addOnMinQuantity<=addOnQuantity){
            setLoadingConfirmOrder(true);
            const data = {
                    "addonPlans": [
                      {
                        "planId": addOnPlanId,
                        "purchaseOrderNumber": addOnPONumber,
                        "quantity": addOnQuantity
                      }
                    ],
                    "subscriptionId": props.actionSelectionData.SubscriptionId,
              }
              const config = { headers: { 'Content-Type': 'application/json' } };
        
              await api.post(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS, data, config)
                .then(resp => {
                  setLoadingConfirmOrder(false);
                  console.log( "Condition : ",resp.data.content[0].isSuccess);
                  alert("Add On Added Successfully");
                  GetManageSubscriptionTableData();
                })
                .catch(error => {
                  setLoadingConfirmOrder(false);
                  console.log(error);
                });
            } 
        else {
            setAddOnErrorMessage(true); 
            setTimeout(() => {
                setAddOnErrorMessage(false); 
            }, 2000);
            } 
    }


  return (
    <div>
        {/* <Popup
            trigger={<button className="button"> Open Modal </button>}
            modal
            nested
        >
            {close => (
            <div className="modal">
                <button className="close" onClick={close}>
                &times;
                </button>
                <div className="header"> Modal Title </div>
                <div className="content">
                {' '}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
                Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
                delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
                <br />
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
                commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
                explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
                </div>
                <div className="actions">
                <button
                    className="button"
                    onClick={() => {
                    console.log('modal closed ');
                    close();
                    }}
                >
                    close modal
                </button>
                </div>
            </div>
            )}
        </Popup> */}

        <Container>
        <center><h1 className='mt-10'>GW Manage Subsription</h1></center><br/>
            { !loading &&
                <Table responsive striped hover>
                    <thead className='manage-subscription-table-head'>
                        <tr>
                        {ColumnName.map((td)=>{
                            return(
                                <th className='manage-subscription-table-head-sub'>{td}</th>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='manage-subscription-table-data'>{tableSub.PlanName + "("+ tableSub.Plancode +")" + '\n' + tableSub.SubscriptionID}</td>
                            <td className='manage-subscription-table-data'>{tableSub.BillingTerm}</td>
                            <td className='manage-subscription-table-data'>{tableSub.RRP}</td>
                            <td className='manage-subscription-table-data'>{tableSub.Price}</td>
                            <td className='manage-subscription-table-data'>{tableSub.Qty}&nbsp;&nbsp;&nbsp;
                                    <Popup
                                        trigger={<Button>Edit</Button>}
                                        modal
                                        nested
                                        contentStyle={{ width: '25%' }}
                                    >
                                        {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>
                                            &times;
                                            </button>
                                            <div className="header"> Edit Quantity </div>
                                            <div className="content">
                                                <center><Form.Control className='quantity-input-field' type="text"  defaultValue={updatedQuantity} 
                                                onChange={(e)=>{
                                                    setUpdatedQuantity(e.target.value);
                                                }
                                                }/></center>
                                                <br />
                                                <center><b>{editQuantityError && <p className='edit-quantity-paragraph text-danger'>Number should be between {minQuantity} & {maxQuantity}</p>}</b></center>
                                                {!editQuantityError && <p className='empty-edit-quantity-paragraph'></p>}
                                            </div>
                                            <div className="actions">
                                            <Button
                                                className="button me-3"
                                                onClick={() => {
                                                if(updatedQuantity>=minQuantity && updatedQuantity<=maxQuantity){
                                                    const config = { headers: { 'Content-Type': 'application/json' } };
                                                    let updatedQuantityData = {
                                                        "quantity": updatedQuantity,
                                                        "subscriptionId": props.actionSelectionData.SubscriptionId,
                                                    }
                                                    api.put(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_EDIT_QUANTITY,updatedQuantityData,config).then(resp => {
                                                        console.log(resp);
                                                        GetManageSubscriptionTableData();
                                                        close();
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                      });
                                                }   
                                                else{
                                                    setEditQuantityError(true);
                                                    console.log("Quantity is out of range bond");
                                                    // alert("Error");
                                                }            
                                                }}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                className="button"
                                                onClick={() => {
                                                console.log('modal closed ');
                                                close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            </div>
                                        </div>
                                        )}
                                    </Popup></td>
                            <td className='manage-subscription-table-data'>
                                {/* {tableSub.Status} */}
                                {/* {isSwitchOnState?"ACTIVE":"INACTIVE"} */}
                                <Form.Check 
                                    type="switch"
                                    checked={isSwitchOnState}
                                    onChange={() => onSwitchAction()}
                                    id="custom-switch"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            }
            { loading &&
                <center><b><h4>Loading . . .</h4></b></center>
            }
        </Container>
                <Popup
                    open={statusConfirmationBox==="activate-subscription"}
                    modal
                    contentStyle={{ width: '25%' }}
                >
                    {close => (
                    <div className="modal">
                        <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                        &times;
                        </button>
                        <div className="header"> Deactivate Subscription </div>
                        <div className="content">
                            <center>
                                Are you sure you want to Deactivate Subscription ?
                            </center>
                        </div>
                        <div className="actions">
                        <Button
                            className="button me-3"
                            onClick={() => {
                                console.log("Dectivate Subscription");
                                let deactivateData = {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId
                                }
                                const config = { headers: { 'Content-Type': 'application/json' } };
                                api.put(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_SUSPEND,deactivateData,config).then(resp => {
                                    console.log(resp);
                                    setIsSwitchOnState(prev => !prev);
                                    close();
                                })
                                .catch(error => {
                                    console.log(error);
                                    setStatusConfirmationBox("activate-subscription");
                                  });
                            }              
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            className="button"
                            onClick={() => {
                            console.log('modal closed ');
                            setStatusConfirmationBox("");
                            close();
                            }}
                        >
                            No
                        </Button>
                        </div>
                    </div>
                    )}
                </Popup>

                <Popup
                    open={statusConfirmationBox==="deactivate-subscription"}
                    modal
                    contentStyle={{ width: '25%' }}
                >
                    {close => (
                    <div className="modal">
                        <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                        &times;
                        </button>
                        <div className="header"> Activate Subscription </div>
                        <div className="content">
                            <center>
                                Are you sure you want to Activate Subscription ?
                            </center>
                        </div>
                        <div className="actions">
                        <Button
                            className="button me-3"
                            onClick={() => {
                                console.log("Activate Subscription");
                                const config = { headers: { 'Content-Type': 'application/json' } };
                                let activateData = {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId
                                }
                                api.put(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ACTIVATE,activateData,config).then(resp => {
                                    console.log(resp);
                                    setIsSwitchOnState(prev => !prev);
                                    close();
                                })
                                .catch(error => {
                                    console.log(error);
                                    setStatusConfirmationBox("deactivate-subscription");
                                  });
                            }              
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            className="button"
                            onClick={() => {
                            console.log('modal closed ');
                            setStatusConfirmationBox("");
                            close();
                            }}
                        >
                            No
                        </Button>
                        </div>
                    </div>
                    )}
                </Popup>
        <Container>
            {loadingAddonTable && 
                <>
                    <br/><br/>
                    <center><h1>Add-ons available to the Subscription</h1></center>
                    <br />
                    <DataTable
                        columns={columnsAddOns}
                        data={tableAddOns}
                        theme="solarized"
                        customStyles={customStyles}
                        striped={true}
                        // fixedHeaderScrollHeight="60vh"
                        progressPending={loadingAddOns}
                        fixedHeader
                        selectableRows
                        onSelectedRowsChange={handleChangePlanTableAddOn}
                        selectableRowsHighlight
                        selectableRowsSingle
                    />
                    <br />
                    {addOnIsChecked && 
                        <>
                            <Form.Control className='quantity-input-field' type="number"  
                                placeholder='Quantity'
                                defaultValue={addOnQuantity} 
                                onChange={(e)=>
                                    {
                                        setAddOnQuantity(e.target.value);
                                    } 
                                }
                            />
                            <Form.Control className='quantity-input-field' type="text"  
                                placeholder='P.O. Number'
                                defaultValue={addOnPONumber} 
                                onChange={(e)=>
                                    {
                                        setAddOnPONumber(e.target.value);
                                    } 
                                }
                            />
                            <br/>
                            {!addOnErrorMessage && <p className='empty-edit-quantity-paragraph'></p>}
                            <center><b>{loadingConfirmOrder && <p className='empty-edit-quantity-paragraph text-warning'>Confirming Buying for Add On. . .</p> }</b></center>
                            <center><b>{addOnErrorMessage && <p className='edit-quantity-paragraph text-danger'>Number should be between {addOnMinQuantity} & {addOnMaxQuantity}</p>}</b></center>
                            <br/>
                            {(addOnPONumber && addOnQuantity) && <center><Button onClick={buyAddOn}>Buy Add On</Button></center>}
                            {(!addOnPONumber || !addOnQuantity) && <center><Button disabled>Buy Add On</Button></center>}
                        </>    
                    }
                </>
            }
        </Container>
    </div>
  )
}

export default GWManageSubsription
