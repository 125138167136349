import React, { useState, useEffect, useContext } from 'react'
import { Container, Form, Row, Col, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PasswordToggle from "../../../Components/PasswordToggle";
import PhoneNumber from "../../../Components/PhoneNumber";
import { userApi, api } from "../../../Utils/API";
import { PROFILE_DETAILS, MFA_UPDATE } from "../../../Utils/GlobalConstants";
import { PROFILE_CHANGE_PASSWORD } from "../../../Utils/GlobalConstants";
import "./styles.scss";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Check2Circle, XCircle, XLg } from "react-bootstrap-icons";
import TermsAndConditions from '../TermsAndConditions';

const MyProfileCustomer = (props) => {

  const navigate = useNavigate();  // React Router v6
  const [userName, setUserName] = useState("SYNNEXCLOUD"); // Username Input Field
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  // Password Usestate Input Field (Edit Password)
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // Password Validation UseState
  const [passwordLength, setPasswordLength] = useState("text-disabled");
  const [passwordFormat, setPasswordFormat] = useState("text-disabled");
  const [passwordCheck, setPasswordCheck] = useState("text-disabled");
  const [passwordMatch, setPasswordMatch] = useState("text-disabled");
  const [passwordMatchError, setPasswordMatchError] = useState(
    "Passwords are matched"
  );
  const [enableBtn, setEnableBtn] = useState(false);
  const [regExError, setRegExError] = useState(false);  // Regex check useState for password matching criteria
  const [newPwdError, setNewPwdError] = useState("");   // Error on New Password Field
  const [confirmPwdError, setConfirmPwdError] = useState(""); // Error on New Password Field

  const [showTermsAndConditionFlag, setShowTermsAndConditionFlag] = useState();   // Terms and Condition Popup shown to customer

  // Header Configuration for API Call with Authorization Token
  const config = {
    headers: {
      "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
    },
  };
  // Initial Customer Profile Data
  let initialCustomerProfile = {
    firstName: "Loading . . .",
    lastName: "Loading . . .",
    userName: "Loading . . .",
    email: "Loading . . .",
    phoneNumber: "Loading . . .",
    isTwoFactorEnabled: false,
    qrCodeImage: "",
  };

  const [customerProfile, setCustomerProfile] = useState(initialCustomerProfile);   // Customer Profile UseState
  const [isTwoFactorAuthenticationEnabled, setIsTwoFactorAuthenticationEnabled,] = useState(false); // MFA Enabled Check from backend
  const [isTwoFactorAuthenticationEnabledFlag, setIsTwoFactorAuthenticationEnabledFlag,] = useState(""); // Activate and Deactive value from Popup
  const [isProfileUpdateConfirmationBoxFlag, setIsProfileUpdateConfirmationBoxFlag,] = useState(false);  // Popup to confirm Profile Update Change
  const [isEditPasswordPopup, setIsEditPasswordPopup] = useState(false);     // Edit Password Popup Open Close

  const [updateFirstName, setUpdateFirstName] = useState(""); // Update First Name in MyProfile Section
  const [updateLastName, setUpdateLastName] = useState(""); // Update Last Name in MyProfile Section
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(""); // Update Phone Number in MyProfile Section
  const [myProfileSuccess, SetMyProfileSuccess] = useState(false); // My Profile Update Success flag
  const [myProfileError, setMyProfileError] = useState(false);    // My Profile Update failure flag
  const [myProfileToastMessage, setMyProfileToastMessage] = useState(false);  // Toast Message
  const [isFormDirty, setIsFormDirty] = useState(false);  // Form Validations
  const [isPhoneUpdated, setIsPhoneUpdated] = useState(false);  // Form Validations
  const regexPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})"); // Regex Expression for Password
  const regExMobile = /^[0-9]*$/;  // Regex Expression for Mobile validation [0-9]
  const regExString = /^[a-zA-Z]*$/; // Regex Expression for string values only [a-z,A-Z]
  const [passwordIsMatchedValidationCheckFlag, setPasswordIsMatchedValidationCheckFlag] = useState(false);  // To display condition check for matched password

  // UseEffect to run on initial load to navigate unauthorized user to Login Page
  useEffect(() => {
    // console.log("Logged in :",props.customerIsAuthenticated);
    if (props.customerIsAuthenticated === false) {
      navigate("/");
    }
  }, [])

  // Logout Function on Error 401

  function Logout() {
    // console.log("Error 401");
    props.setCustomerIsAuthenticated(false);
    if (props.customerIsAuthenticated) { navigate("/") };
    sessionStorage.clear();
    localStorage.clear();
    props.setActiveLocalKey('default')
  }

  // Get Customer Profile Details
  async function GetCustomerProfile() {
    await api.get(PROFILE_DETAILS, config).then(resp => {
      setCustomerProfile(resp.data);
      setUpdateFirstName(resp.data.firstName);
      setUpdateLastName(resp.data.lastName);
      let profileInitialCredential = (resp.data.firstName.slice(0, 1) + resp.data.lastName.slice(0, 1))
      if (profileInitialCredential !== sessionStorage.getItem("profile_initial_credential")) {
        // console.log(profileInitialCredential);          
        sessionStorage.setItem("profile_initial_credential", profileInitialCredential);
        props.setProfileInitialCredentialChange(true);
      }
      resp.data.phoneNumber !== null ? setUpdatePhoneNumber(resp.data.phoneNumber) : setUpdatePhoneNumber("");
      setIsTwoFactorAuthenticationEnabled(resp.data.isTwoFactorEnabled);
    })
      .catch(error => {
        // handle error
        // console.log(error);
        if (error.response.status == 401) {
          // console.log("Error 401");
          Logout();
        }
      });
  }

  // Two Factor data shown or not
  const TwoFactorAuthentication = () => {
    // console.log("Two Factor Authentication Changed");
    setIsTwoFactorAuthenticationEnabled(!isTwoFactorAuthenticationEnabled);
  };

  // Checking Password Validation of length and regex constaint
  const getPassword = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length > 0) {
      if (e.target.value.length > 7 && e.target.value.length < 16) {
        setPasswordLength("green");
      } else {
        setPasswordLength("red");
      }
    } else {
      setPasswordLength("text-disabled");
    }

    if (e.target.value.length > 0) {
      if (regexPassword.test(e.target.value)) {
        setPasswordFormat("green");
      } else {
        setPasswordFormat("red");
      }
    } else {
      setPasswordFormat("text-disabled");
    }

    if (e.target.value.length !== 0) {
      if (e.target.value !== oldPassword) {
        setPasswordCheck("green");
      } else {
        setPasswordCheck("red");
      }
    } else {
      setPasswordCheck("text-disabled");
    }
  };

  // Setting Old Password in edit password Popup
  const getOldPassword = (e) => {
    setOldPassword(e.target.value);
    if (e.target.value.length !== 0) {
      if (e.target.value !== newPassword) {
        setPasswordCheck("green");
      } else {
        setPasswordCheck("red");
      }
    } else {
      setPasswordCheck("text-disabled");
    }
  };

  // Password is matched or not Function
  const getPasswordMatch = (e) => {
    if (e.target.value.length <= 15) {
      setConfirmPassword(e.target.value);
    } else {
      setConfirmPassword("");
    }

    if (e.target.value === newPassword) {
      setPasswordMatch("green");
      setPasswordMatchError("Passwords are matched");

      if (oldPassword !== e.target.value) {
        // setEnableBtn(true);
      } else {
        // setEnableBtn(false);
      }

    } else {
      setPasswordMatch("red");
      setPasswordMatchError("Passwords are not matched");
      // setEnableBtn(false);
    }
  };

  useEffect(() => {
    if (confirmPassword.length > 0 && confirmPassword.length <= 15) {
      setPasswordIsMatchedValidationCheckFlag(true);
    }
    else {
      setPasswordIsMatchedValidationCheckFlag(false);
    }
  }, [confirmPassword])

  // To check Password Constraints
  const checkPwd = async () => {
    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      !regexPassword.test(newPassword)
    ) {
      setRegExError(true);
    } else {
      setRegExError(false);
    }

    // userName === '' ? setUserNameError("Please enter user name") : setUserNameError('');
    newPassword === ""
      ? setNewPwdError("Please enter valid password")
      : setNewPwdError("");
    confirmPassword === ""
      ? setConfirmPwdError("Please enter confirm password")
      : setConfirmPwdError("");

    if (
      regexPassword.test(newPassword) &&
      newPassword === confirmPassword &&
      userName !== ""
    ) {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      const config = {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
        }
      };

      await userApi
        .post(PROFILE_CHANGE_PASSWORD, data, config)
        .then((response) => {
          setMyProfileToastMessage("Password reset successfully.");
          setOldPassword("");
          setIsEditPasswordPopup(false);
          SetMyProfileSuccess(true);
          setTimeout(() => {
            SetMyProfileSuccess(false);
          }, 3000);
          // setEnableBtn(false);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // console.log("Error 401");
            Logout();
          }
          else {
            setMyProfileToastMessage(error.response.data.errors[0]);
            setMyProfileError(true);
            setTimeout(() => {
              setMyProfileError(false);
            }, 3000);
          }
        });
    } else {
    }
    setPasswordLength("text-disabled");
    setPasswordFormat("text-disabled");
    setPasswordMatch("text-disabled");
  };
  // Reset Edit Password Form on close
  const resetPwdForm = () => {
    setOldPassword("");
    setIsEditPasswordPopup(false);
    setPasswordLength("text-disabled");
    setPasswordFormat("text-disabled");
    setPasswordMatch("text-disabled");
  }
  // Setting Mobile Number in My Profile Section upto length 10
  const setMobileNumberValue = (e) => {
    if (regExMobile.test(e.target.value) && ((e.target.value).length <= 10)) {
      setUpdatePhoneNumber(e.target.value);
    }
  }

  //Use Effects Declaration

  // Get Profile Data
  useEffect(() => {
    GetCustomerProfile();
  }, []);

  // Validation Check in Edit Password
  useEffect(() => {
    if (oldPassword && confirmPassword && newPassword) {
      if ((oldPassword != newPassword) && (newPassword === confirmPassword)) {
        setEnableBtn(true);
      }
      if ((oldPassword === newPassword) || (newPassword != confirmPassword)) {
        setEnableBtn(false);
      }
      if (oldPassword === newPassword) {
        setPasswordCheck("red");
      }
      if (oldPassword != newPassword) {
        setPasswordCheck("green");
      }
      if (confirmPassword != newPassword) {
        setPasswordMatch("red");
        setPasswordMatchError("Passwords are not matched");
      }
      if (confirmPassword === newPassword) {
        setPasswordMatch("green");
        setPasswordMatchError("Passwords are matched");
      }
    }
  }, [oldPassword, confirmPassword, newPassword]);

  // Validation Check in My Profile with mobile number
  useEffect(() => {
    // console.log("isPhoneUpdated", isPhoneUpdated);

    if (updateFirstName !== customerProfile.firstName || updateLastName !== customerProfile.lastName || updatePhoneNumber !== customerProfile.phoneNumber) {
      if (isPhoneUpdated) {
        setIsFormDirty(true);
      } else {
        setIsFormDirty(false);
      }

    } else {
      setIsFormDirty(false);
    }
  }, [updateFirstName, updateLastName, updatePhoneNumber, isPhoneUpdated])

  // Validation Check in My Profile without mobile number
  useEffect(() => {
    if (updatePhoneNumber !== customerProfile.phoneNumber) {
      if (updatePhoneNumber.length === 10 || updatePhoneNumber.length === 0) {
        setIsPhoneUpdated(true);
      } else {
        setIsPhoneUpdated(false);
      }
    } else {
      setIsPhoneUpdated(true);
    }
  }, [updateFirstName, updateLastName, updatePhoneNumber])

  return (
    <>
      {(showTermsAndConditionFlag != sessionStorage.getItem("Accepted_Version")) && <TermsAndConditions />}
      <div className={`myprofile-alert ${myProfileSuccess ? "active" : ""}`}>
        <Alert variant="success">
          <div className='d-flex align-items-center'>
            <Check2Circle size={24} color="#1D874C" />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>

      <div className={`myprofile-alert ${myProfileError ? "active" : ""}`}>
        <Alert variant="danger">
          <div className='d-flex align-items-center'>
            <XCircle size={24} color="#9C3B35" />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>
      <Container fluid className="main-content">
        <div className="raise-ticket">
          <div className="myprofileheading">
            <h2>My Profile</h2>
            <div className="inner-block py-3 mb-5 mt-5 sub-pages-block-inner">
              <div className="px-5 profile-details-block pb-4">
                <Form>
                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="emailId">
                          <Form.Label>Email ID</Form.Label>
                          <Form.Control
                            type="Text"
                            placeholder="Enter Email"
                            value={customerProfile.email}
                            disabled
                          />
                          <Form.Text className="text-muted d-none">
                            Please enter valid email
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type={"password"}
                            value={"********"}
                            disabled
                          />
                          <Form.Text className="text-muted d-none">
                            Please enter valid email
                          </Form.Text>
                          <span
                            className="float-right cpointer text-primary text-small mt-1"
                            onClick={() => {
                              setIsEditPasswordPopup(true);
                            }}
                          >
                            Edit Password
                          </span>
                        </Form.Group>


                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="Text"
                            placeholder="First Name"
                            value={updateFirstName}
                            onChange={(e) => (regExString.test(e.target.value) ? setUpdateFirstName(e.target.value) : "")}
                            maxLength="50"
                          />
                          <Form.Text className="text-muted d-none">
                            Please enter valid first name
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="Text"
                            placeholder="Enter Last Name"
                            value={updateLastName}
                            onChange={(e) => (regExString.test(e.target.value) ? setUpdateLastName(e.target.value) : "")}
                            maxLength="50"
                          />
                          <Form.Text className="text-muted d-none">
                            Please enter valid last name
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <PhoneNumber
                          countryCode="+61"
                          phoneNumber={updatePhoneNumber}
                          UpdatePhoneNumber={setMobileNumberValue}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Row>
                    <Form.Group className='mt-5 mb-3'>
                      <Col className="justify-content-end my-xxl-2 d-flex">
                        {(updateFirstName != customerProfile.firstName ||
                          updateLastName != customerProfile.lastName ||
                          updatePhoneNumber != customerProfile.phoneNumber) && (
                            <Button
                              className="btn btn-border-light btn-lg mx-3"
                              variant="light"
                              // type="reset"
                              onClick={() => {
                                setUpdateFirstName(customerProfile.firstName);
                                setUpdateLastName(customerProfile.lastName);
                                setUpdatePhoneNumber(customerProfile.phoneNumber);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        {updateFirstName === customerProfile.firstName &&
                          updateLastName === customerProfile.lastName &&
                          updatePhoneNumber === customerProfile.phoneNumber && (
                            <Button
                              className="btn btn-border-light btn-lg mx-3"
                              variant="light"
                              // type="reset"
                              disabled
                            >
                              Cancel
                            </Button>
                          )}

                        {
                          isFormDirty && (
                            <Button
                              className="btn btn-lg"
                              onClick={() =>
                                setIsProfileUpdateConfirmationBoxFlag(true)
                              }
                            >
                              Save
                            </Button>
                          )
                        }
                        {
                          !isFormDirty && (
                            <Button className="btn btn-lg" disabled variant="light">
                              Save
                            </Button>
                          )
                        }
                      </Col>
                    </Form.Group>
                  </Row>
                </Form>

              </div>
              <div className='mfa-information pb-4 pt-5 mt-4'>
                <div className="px-5 ">
                  <h2 className="mb-2">Two Factor Authentication</h2>
                  {!isTwoFactorAuthenticationEnabled && <p className="mb-3 mt-0 text-small text-faded">Note: MFA is a standard security practice we will recommend to keep it enabled.</p>}
                  <Form className='mt-xxl-4'>
                    <label className="text-strong">MFA Settings</label>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      className="d-inline-block ml-3"
                      label={isTwoFactorAuthenticationEnabled ? "On (Toggle to switch MFA Off)" : "Off (Toggle to switch MFA On)"}
                      checked={isTwoFactorAuthenticationEnabled}
                      onChange={() => {
                        if (!isTwoFactorAuthenticationEnabled) {
                          setIsTwoFactorAuthenticationEnabledFlag("activate-mfa");
                        } else {
                          setIsTwoFactorAuthenticationEnabledFlag("deactivate-mfa");
                        }
                      }}
                    />
                  </Form>
                </div>
              </div>
              <Popup
                open={isTwoFactorAuthenticationEnabledFlag === "activate-mfa"}
                className="custom-modal custom-modal-md"
                modal
                onClose={() => setIsTwoFactorAuthenticationEnabledFlag("")}
              >
                {(close) => (
                  <div className="modal">
                    <div className="header">
                      <h2>Activate MFA</h2>
                      <XLg
                        size={18}
                        className="cpointer text-strong"
                        onClick={() => {
                          close();
                          setIsTwoFactorAuthenticationEnabledFlag("");
                        }}
                        color="#6A6A6A"
                      />
                    </div>
                    <Container>
                      <p>Are you sure you want to Activate MFA ?</p>
                      <div className="actions">
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-lg mx-3 btn-border-light"
                            variant="light"
                            onClick={() => {
                              // console.log("modal closed ");
                              setIsTwoFactorAuthenticationEnabledFlag("");
                              close();
                            }}
                          >
                            No
                          </Button>
                          <Button
                            className="btn btn-md"
                            onClick={() => {
                              // console.log("Activate MFA");
                              const config = {
                                headers: {
                                  'Content-Type': 'application/json',
                                  "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
                                }
                              };
                              const data = true;
                              api
                                .post(MFA_UPDATE, data, config)
                                .then((resp) => {
                                  // console.log(resp);
                                  TwoFactorAuthentication();
                                  setIsTwoFactorAuthenticationEnabledFlag("");
                                  setMyProfileToastMessage(
                                    "MFA Activated Successfully."
                                  );
                                  SetMyProfileSuccess(true);
                                  close();
                                  setTimeout(() => {
                                    SetMyProfileSuccess(false);
                                  }, 3000);
                                })
                                .catch((error) => {
                                  if (error.response.status == 401) {
                                    // console.log("Error 401");
                                    Logout();
                                  }
                                  else {
                                    setMyProfileToastMessage(
                                      "There is an error in Activating MFA. Please try it again."
                                    );
                                    setMyProfileError(true);
                                    close();
                                    setTimeout(() => {
                                      setMyProfileError(false);
                                    }, 3000);
                                  }
                                });
                              // TwoFactorAuthentication();
                            }}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    </Container>
                  </div>
                )}
              </Popup>
              <Popup
                open={isTwoFactorAuthenticationEnabledFlag === "deactivate-mfa"}
                modal
                className="custom-modal custom-modal-md"
                onClose={() => setIsTwoFactorAuthenticationEnabledFlag("")}
              >
                {(close) => (
                  <div className="modal">
                    <div className="header">
                      <h2>Deactivate MFA</h2>
                      <XLg
                        size={16}
                        className="cpointer text-strong"
                        onClick={() => {
                          close();
                          setIsTwoFactorAuthenticationEnabledFlag("");
                        }}
                        color="#6A6A6A"
                      />
                    </div>
                    <Container>
                      <p>Are you sure you want to Deactivate MFA ?</p>
                      <div className="actions">
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-lg mx-3 btn-border-light"
                            variant="light"
                            onClick={() => {
                              // console.log("modal closed ");
                              setIsTwoFactorAuthenticationEnabledFlag("");
                              close();
                            }}
                          >
                            No
                          </Button>
                          <Button
                            className="btn btn-md"
                            onClick={() => {
                              // console.log("Deactivate MFA");
                              const config = {
                                headers: {
                                  'Content-Type': 'application/json',
                                  "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
                                }
                              };
                              const data = false;
                              api
                                .post(MFA_UPDATE, data, config)
                                .then((resp) => {
                                  // console.log(resp);
                                  TwoFactorAuthentication();
                                  setIsTwoFactorAuthenticationEnabledFlag("");
                                  setMyProfileToastMessage(
                                    "MFA Deactivated Successfully."
                                  );
                                  SetMyProfileSuccess(true);
                                  close();
                                  setTimeout(() => {
                                    SetMyProfileSuccess(false);
                                  }, 3000);
                                })
                                .catch((error) => {
                                  if (error.response.status == 401) {
                                    // console.log("Error 401");
                                    Logout();
                                  }
                                  else {
                                    setMyProfileToastMessage(
                                      "There is an error in Deactivating MFA. Please try it again."
                                    );
                                    setMyProfileError(true);
                                    close();
                                    setTimeout(() => {
                                      setMyProfileError(false);
                                    }, 3000);
                                  }
                                });
                              // TwoFactorAuthentication();
                              // setIsTwoFactorAuthenticationEnabledFlag("");
                            }}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    </Container>
                  </div>
                )}
              </Popup>
              <Popup
                open={isProfileUpdateConfirmationBoxFlag}
                modal
                className="custom-modal custom-modal-md"
                onClose={() => setIsProfileUpdateConfirmationBoxFlag(false)}
              >
                {(close) => (
                  <div className="modal">
                    <div className="header p-4 py-3">
                      <h2>Update Profile</h2>
                      <XLg
                        size={16}
                        className="cpointer text-strong"
                        onClick={() => {
                          close();
                          setIsProfileUpdateConfirmationBoxFlag(false);
                        }}
                        color="#6A6A6A"
                      />
                    </div>

                    <Container className="p-4">
                      <p>Are you sure you want Update your Profile ?</p>
                      <div className="actions">
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-lg mx-3 btn-border-light"
                            variant="light"
                            onClick={() => {
                              // console.log("modal closed ");
                              setIsTwoFactorAuthenticationEnabledFlag("");
                              close();
                            }}
                          >
                            No
                          </Button>
                          <Button
                            className="btn btn-md"
                            onClick={() => {
                              // console.log("Updation Of Profile Begins");
                              const config = {
                                headers: {
                                  'Content-Type': 'application/json',
                                  "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
                                }
                              };
                              const data = {
                                firstName: updateFirstName,
                                lastName: updateLastName,
                                phoneNumber: updatePhoneNumber,
                              };
                              api
                                .post(PROFILE_DETAILS, data, config)
                                .then((resp) => {
                                  // console.log(resp);
                                  setIsProfileUpdateConfirmationBoxFlag(false);
                                  setIsFormDirty(false);
                                  setMyProfileToastMessage(
                                    "Profile Updated Successfully."
                                  );
                                  SetMyProfileSuccess(true);
                                  close();
                                  GetCustomerProfile();
                                  setTimeout(() => {
                                    SetMyProfileSuccess(false);
                                  }, 3000);
                                })
                                .catch((error) => {
                                  // console.log(error);
                                  if (error.response.status == 401) {
                                    // console.log("Error 401");
                                    Logout();
                                  }
                                  else {
                                    setIsProfileUpdateConfirmationBoxFlag(false);
                                    setIsFormDirty(false);
                                    setMyProfileToastMessage(
                                      "There is an error in Updating Profile. Please try Updating it again."
                                    );
                                    setMyProfileError(true);
                                    close();
                                    setTimeout(() => {
                                      setMyProfileError(false);
                                    }, 3000);
                                  }
                                });
                              // TwoFactorAuthentication();
                            }}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    </Container>
                  </div>
                )}
              </Popup>
              <Popup
                open={isEditPasswordPopup}
                modal
                className="custom-modal custom-modal-sm"
                onClose={() => {
                  setIsEditPasswordPopup(false);
                  setIsEditPasswordPopup(false);
                  setOldPassword("");
                  setIsEditPasswordPopup(false);
                  setPasswordLength("text-disabled");
                  setPasswordFormat("text-disabled");
                  setPasswordMatch("text-disabled");
                }}
              >
                {(close) => (
                  <div className="modal">
                    <div className="header">
                      <h2>Edit Password</h2>
                      <XLg
                        size={16}
                        className="cpointer"
                        onClick={() => {
                          close();
                        }}
                      />
                    </div>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {!showSuccess && !showFailure && (
                            <>
                              <Col lg={12}>
                                <Form autoComplete='off'>
                                  <Form.Group className="mb-4" controlId="password">
                                    <Form.Label>Old Password</Form.Label>
                                    <PasswordToggle
                                      value={oldPassword}
                                      placeholder="Enter Old Password"
                                      passwordToggleEvent={getOldPassword}
                                      maxLength="15"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-4" controlId="password">
                                    <Form.Label>New Password</Form.Label>
                                    <PasswordToggle
                                      value={newPassword}
                                      placeholder="Enter New Password"
                                      passwordToggleEvent={getPassword}
                                      maxLength="15"
                                    />

                                    <Form.Text className="text-danger password-validation">
                                      <p className='mt-2'>Password must contain</p>
                                      <ul>
                                        <li className={passwordLength}>
                                          <span>
                                            Minimum 8 and maximum 15 characters
                                          </span>
                                        </li>
                                        <li className={passwordFormat}>
                                          <span>
                                            A mix of uppercase and lowercase characters,
                                            numbers and special characters
                                          </span>
                                        </li>
                                        <li className={passwordCheck}>
                                          <span>
                                            Old password and new password should not be same
                                          </span>
                                        </li>
                                      </ul>
                                    </Form.Text>
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-4"
                                    controlId="confirmPassword"
                                  >
                                    <Form.Label>Confirm Password</Form.Label>
                                    <PasswordToggle
                                      value={confirmPassword}
                                      placeholder="Re - Enter New Password"
                                      passwordToggleEvent={getPasswordMatch}
                                      maxLength="15"
                                    />
                                    <Form.Text className="text-danger password-validation">
                                      {passwordIsMatchedValidationCheckFlag && <ul>
                                        <li className={passwordMatch}>
                                          {passwordMatchError}
                                        </li>
                                      </ul>}
                                      {!passwordIsMatchedValidationCheckFlag &&
                                        <ul className='password-is-matched-disabled'>
                                          <li className={passwordMatch}>
                                            {passwordMatchError}
                                          </li>
                                        </ul>
                                      }
                                    </Form.Text>
                                  </Form.Group>

                                  <div className="d-flex justify-content-center">
                                    <Button
                                      className="btn-lg mx-1 btn-border-light"
                                      variant="light"
                                      type="reset"
                                      onClick={resetPwdForm}
                                    >
                                      Cancel
                                    </Button>
                                    {enableBtn ?
                                      <Button
                                        className="btn mx-1 btn-primary btn-lg"
                                        variant="primary"
                                        type="button"
                                        onClick={checkPwd}
                                      >
                                        Save
                                      </Button>
                                      :
                                      <Button
                                        className="btn mx-1 btn-lg"
                                        variant="light"
                                        disabled
                                      >
                                        Save
                                      </Button>
                                    }

                                  </div>
                                </Form>
                              </Col>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Container>

                    {/* <div className="actions d-none">
                      <Container className="px-4">
                        <Button
                          className="btn-lg mr-3 mx-5 btn-border-light"
                          variant="light"
                          onClick={() => {
                            setIsEditPasswordPopup(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn btn-primary btn-lg"
                          onClick={() => {
                            setIsEditPasswordPopup(false);
                          }}
                        >
                          Save
                        </Button>
                      </Container>
                    </div> */}

                  </div>
                )}
              </Popup>

              {/* {isTwoFactorAuthenticationEnabled && (
                <div className="profile-mfa-information">
                  <Row>
                    <Col md={4} className='qr-code-image-mfa-div'>
                      <img src={customerProfile.qrCodeImage} className='qr-code-image-mfa' alt="" />
                    </Col>
                    <Col md={8}>
                      <p>
                        <ol>
                          <li>
                            Install Google or Microsoft Authentication App from:{" "}
                            <br />
                            App Store (iOS) - Google Authentication App /
                            Microsoft Authentication App
                            <br />
                            Play Store (Android) - Google Authentication App /
                            Microsoft Authentication App
                            <br />
                          </li>
                          <li>
                            Click on <b>Add Account</b>
                          </li>
                          <li>
                            Scan the QR Code on left <br />
                            That’s it! Now you can use the authenticator app to
                            generate the tokens <br />
                            for completing the sign in process
                          </li>
                        </ol>
                      </p>
                    </Col>
                  </Row>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </Container >
    </>
  );
};

export default MyProfileCustomer;