import { useState } from "react";
import { InputGroup, Form } from "react-bootstrap";
import './styles.scss';

const PasswordToggle = (props) => {
    const [password, setPassword] = useState('password');
    const [hideEye, setHideEye] = useState('');

    const togglePassword = () => {
        password === 'password' ? setHideEye('hide-eye') : setHideEye('');
        password === 'password' ? setPassword('text') : setPassword('password');
    }

    return (
        <>
            <InputGroup className="password-toggle">
                <Form.Control 
                    type={password} 
                    value={props.passwordValue} 
                    onChange={props.passwordToggleEvent} 
                    placeholder={props.placeholder} 
                    maxLength={props.maxLength}
                    />
                <InputGroup.Text>
                    <span className={`eye ${hideEye}`} onClick={togglePassword}>&nbsp;</span>
                </InputGroup.Text>
            </InputGroup>
        </>
    )
}

export default PasswordToggle;