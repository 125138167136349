import React, { useEffect , useContext } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles.scss";
import { api } from "../../../Utils/API";
import {LANDING_PAGE_CMS_DATA,TERMS_AND_CONDITION_ACCEPT} from "../../../Utils/GlobalConstants";
import {Container,Button,} from "react-bootstrap";
import { useState } from "react";
import CmsDetailsContext from "../../../Context/CmsDetails/CmsDetailsContext";

const TermsAndConditions = () => {
  const globalData = useContext(CmsDetailsContext); // Use Context Global Data
  const [termsAndConditionPopupFlag, setTermsAndConditionPopupFlag] = useState(false); // Open Popup for Terms and Condition
  const [termsAndConditionData, setTermsAndConditionData] = useState(); // Terms and Condition Data in popup Body
  const [showTermsAndConditionFlag, setShowTermsAndConditionFlag] = useState(); // Global Data version number from CMS Portal
  const [showTermsAndConditionHeader, setShowTermsAndConditionHeader] = useState("");  // Terms and Condition Popup Heading

  // Calling Global Data from Context
  useEffect(() => {
    if (globalData) {
      setShowTermsAndConditionFlag(globalData.termsConditionsVersion);
      // console.log("Show Tnc Flag : ", globalData.termsConditionsVersion);
    }
  }, [globalData]);

  // Terms and Condition Get Popup Data
  async function TermsAndConditionPopupData() {
    // console.log("Site Setting API Initiated..");
    const data = `{
            termsConditionsPage{
              displayText
              htmlBody {
                html
              }
            }   
          }`;
    // Defining Header Configuration
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
        "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
      },
    };
    // Axios Post Request
    await api
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log("HTML : ",resp.data.data.termsConditionsPage[0].htmlBody.html);
        setTermsAndConditionData(resp.data.data.termsConditionsPage[0].htmlBody.html);
        setShowTermsAndConditionHeader(resp.data.data.termsConditionsPage[0].displayText)
        // console.log("TNC DATA" ,showTermsAndConditionFlag,sessionStorage.getItem("Accepted_Version"))
        if(showTermsAndConditionFlag === sessionStorage.getItem("Accepted_Version")){
          setTermsAndConditionPopupFlag(false);
        }
        else{
          setTermsAndConditionPopupFlag(true);
        }
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      });
  }

  // Accept Terms and Condition Function
  async function TermsAndConditionAcceptFunction() {
    const config = {
      headers: {
        "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
      },
    };
    let data={}
    // console.log("Local Storage : ",data, config);
    await api
      .post(TERMS_AND_CONDITION_ACCEPT,data, config)
      .then((resp) => {
          // console.log(resp.data.message);
          sessionStorage.setItem("Accepted_Version",resp.data.message)
          setTermsAndConditionPopupFlag(false);
        // handle success
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      });
  }

  // Calling Popup data function only if customer has not accepted latest terms and condition
  useEffect(() => {
    if (!termsAndConditionPopupFlag && showTermsAndConditionFlag) {
      TermsAndConditionPopupData();
    }
  }, [showTermsAndConditionFlag])

  return (
    <>
      <Popup
        className="custom-modal custom-modal-xl"
        open={termsAndConditionPopupFlag}
        modal
        nested
        //  contentStyle={{ width: '90% ' }}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="modal terms-conditions">
            <div className="header p-4 py-3">
              <h1 className="mb-0">{showTermsAndConditionHeader}</h1>

            </div>
            <Container fluid className="p-4">
              <div className="content" dangerouslySetInnerHTML={{ __html: termsAndConditionData }}>

              </div>

            </Container>
            <div className="actions">
              <Container fluid className="px-3">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn btn-md"
                    onClick={() => { 
                      TermsAndConditionAcceptFunction();
                    }}
                  >
                    I Accept
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        )}
      </Popup>
    </>
  )
}

export default TermsAndConditions;