import React, { useState } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg, ArrowLeftCircle } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const OrderHistoryFilterBox = (props) => {

    const [columns, setColumns] = useState(["Order Notes", "Order Number", "Order Status"])

    return (
        <>
            <div className="filter-box">
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={() => {props.setShowFilterBox(false)}} />
                    </Col>
                    <Row className="mb-3 mobile-filter-row">
                    {/* <Col Col className="filter-column" >
                        <h3 className="mb-3">Cloud Services</h3>
                        <div>
                            {
                                <Form>
                                    <div className="mb-3">
                                        <Form.Check
                                            type='checkbox'
                                            id={"CloudServices-column-all"}
                                            label="All Items"
                                            checked={props.cloudServiceAll}
                                            onClick={() => {
                                                props.setCloudServiceListGlobal({});
                                                // console.log(document.getElementById("Monthly").checked);
                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                props.cloudServiceFilter.map((item) => {
                                                    props.setCloudServiceListGlobal((prev) => ({
                                                    cloudServiceGlobal: {
                                                        ...prev.cloudServiceGlobal, [item]: !(props.cloudServiceAll)
                                                    }
                                                    }));
                                                })
                                                props.setCloudServiceAll(!(props.cloudServiceAll));
                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                }}
                                        />
                                    </div>
                                    {(props.cloudServiceFilter).map((td=>{
                                        return(
                                            <div className="mb-3">
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    id={td}
                                                    label={td}
                                                    checked={(props.cloudServiceListGlobal).cloudServiceGlobal[td]}
                                                    // checked={(props.subjectListGlobal).subjectGlobal[td]}
                                                    onClick={() => {
                                                        props.setCloudServiceListGlobal({});
                                                        props.cloudServiceFilter.map((item) => {
                                                            if (item == td) {
                                                            // console.log(item, type, (document.getElementById(item)));
                                                            props.setCloudServiceListGlobal((prev) => ({
                                                                cloudServiceGlobal: {
                                                                ...prev.cloudServiceGlobal, [item]: (document.getElementById(item).checked)
                                                                }
                                                            }));
                                                            props.setCloudServiceAll(false);
                                                            }
                                                            else {
                                                            props.setCloudServiceListGlobal((prev) => ({
                                                                cloudServiceGlobal: {
                                                                ...prev.cloudServiceGlobal, [item]: (document.getElementById(item).checked)
                                                                }
                                                            }));
                                                            }
                                                        })
                                                        }
                                                        }
                                                />
                                            </div>
                                        )
                                    })) }
                                </Form>
                            }
                        </div>
                    </Col> */}
                    <Col Col className="filter-column" >
                        <h3 className="mb-3">Status</h3>
                        <div>
                            {
                                <Form>
                                    <div className="mb-3">
                                        <Form.Check
                                            type='checkbox'
                                            id={"Status-column-all"}
                                            label="All Items"
                                            checked={props.statusAll}
                                            onClick={() => {
                                                props.setStatusListGlobal({});
                                                // console.log(document.getElementById("Monthly").checked);
                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                props.statusFilter.map((item) => {
                                                    props.setStatusListGlobal((prev) => ({
                                                    statusGlobal: {
                                                        ...prev.statusGlobal, [item]: !(props.statusAll)
                                                    }
                                                    }));
                                                })
                                                props.setStatusAll(!(props.statusAll));
                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                }}
                                        />
                                    </div>
                                    {(props.statusFilter).map((td=>{
                                        return(
                                            <div className="mb-3">
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    id={td}
                                                    label={td}
                                                    checked={(props.statusListGlobal).statusGlobal[td]}
                                                    // checked={(props.subjectListGlobal).subjectGlobal[td]}
                                                    onClick={() => {
                                                        props.setStatusListGlobal({});
                                                        props.statusFilter.map((item) => {
                                                            if (item == td) {
                                                            // console.log(item, type, (document.getElementById(item)));
                                                            props.setStatusListGlobal((prev) => ({
                                                                statusGlobal: {
                                                                ...prev.statusGlobal, [item]: (document.getElementById(item).checked)
                                                                }
                                                            }));
                                                            props.setStatusAll(false);
                                                            }
                                                            else {
                                                            props.setStatusListGlobal((prev) => ({
                                                                statusGlobal: {
                                                                ...prev.statusGlobal, [item]: (document.getElementById(item).checked)
                                                                }
                                                            }));
                                                            }
                                                        })
                                                        }
                                                        }
                                                />
                                            </div>
                                        )
                                    })) }
                                </Form>
                            }
                        </div>
                    </Col>
                    </Row>
                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">

                            {/* Hide this button */}
                            {((!((JSON.stringify((props.statusListGlobal).statusGlobal).includes("true")))) || (props.statusAll)) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>}
                            {((!(props.statusAll)) && ((JSON.stringify((props.statusListGlobal).statusGlobal).includes("true")))) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={() => {
                                    props.setStatusListGlobalFilteredArray(null);
                                    props.GetClearFilterData(null);
                                }}
                            >
                                Clear
                            </Button>}
                            {/* {emptyFilterSubscriptionTable && <Button
              className="btn btn-lg mx-3"
              variant="primary"
              onClick={() => { GetTableDatas() }}
            >
              Clear
            </Button>} */}
                            {((!(props.statusAll)) && ((JSON.stringify((props.statusListGlobal).statusGlobal).includes("true")))) &&
                            <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                        
                                    let filterFinalFiltersAPIStatus = [];
                                    let filterFinalFiltersAPIStatusFalseStorage = [];
                                    let filterFinalFiltersAPIStatusBooleanStorage = [];
                  
                                    // let filterFinalFiltersAPICloudService = [];
                                    // let filterFinalFiltersAPICloudServiceFalseStorage = [];
                                    // let filterFinalFiltersAPICloudServiceBooleanStorage = [];
                  
                                    (props.statusFilter).map((item) => {
                                      if (props.statusListGlobal.statusGlobal[item] === true) {
                                        filterFinalFiltersAPIStatus.push(`${item}`);
                                        filterFinalFiltersAPIStatusBooleanStorage.push(true);
                                      }
                                      if (props.statusListGlobal.statusGlobal[item] === false) {
                                        filterFinalFiltersAPIStatusFalseStorage.push(`${item}`);
                                        filterFinalFiltersAPIStatusBooleanStorage.push(false);
                                      }
                                    });
                                    // (props.cloudServiceFilter).map((item) => {
                                    //   if (props.cloudServiceListGlobal.cloudServiceGlobal[item] === true) {
                                    //     filterFinalFiltersAPICloudService.push(`${item}`);
                                    //     filterFinalFiltersAPICloudServiceBooleanStorage.push(true);
                                    //   }
                                    //   if (props.cloudServiceListGlobal.cloudServiceGlobal[item] === false) {
                                    //     filterFinalFiltersAPICloudServiceFalseStorage.push(`${item}`);
                                    //     filterFinalFiltersAPICloudServiceBooleanStorage.push(false);
                                    //   }
                                    // });
                                    console.log("Status : ", filterFinalFiltersAPIStatus);
                                    // console.log("Cloud Services : ", filterFinalFiltersAPICloudService);
                                    // props.setCloudServiceListGlobalFilteredArray(filterFinalFiltersAPICloudService);
                                    props.setStatusListGlobalFilteredArray(filterFinalFiltersAPIStatus.join(","));
                                    props.GetTableFilterData(filterFinalFiltersAPIStatus);
                                    // GetFilterData(filterFinalFiltersAPIPriority, filterFinalFiltersAPISubject, filterFinalFiltersAPICloudService);
                                  }}
                            >
                                Apply
                            </Button>}

                            {/* Hide this button */}
                            {(!(JSON.stringify((props.statusListGlobal).statusGlobal).includes("true")) || props.statusAll) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>}
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
}

export default OrderHistoryFilterBox;