import React from 'react'
import {Container,Button,Row,Col} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg , ArrowLeftCircle } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const GlobalFilterBox = (
  {
    setShowFilterBox,
    billingTermAll,
    billingTermListGlobal,
    setBillingTermListGlobal,
    subscriptionTypeAll,
    billingTermFilter,
    setBillingTermAll,
    statusAll,
    setStatusAll,
    setSubscriptionTypeListGlobal,
    subscriptionTypeListGlobal,
    setStatusListGlobal,
    statusFilter,
    statusListGlobal,
    billingTermListLocal,
    setGlobalToLocalAllItemFlag,
    setStatusAllLocal,
    subscriptionTypeFilter,
    setSubscriptionTypeAll,
    setSubscriptionTypeAllLocal,
    setFilterFlagLocalSubscriptionType,
    filterFlag,
    setBillingTermAllLocal,
    subscriptionTypeListLocal,
    statusListLocal,
    setFilterFlagLocalBilling,
    setFilterFlagLocalStatus,
    GetTableDatas,
    GetFilterData,
    setIsGlobalLacalChangeAPIFlag,
    ClearGolbalFunction
  }
) => {
  return (
    <div className="filter-box">
      <Container>
      <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
            <h2>Filters</h2>
            <XLg size={16} onClick={() => setShowFilterBox(false)} />
          </Col>
        <Row className="mb-3 mobile-filter-row">
          <Col className="filter-column">
            <h3 className="mb-3">Billing Term</h3>
            <div>
              {
                <Form>
                  <div key={`default-allItems`} className="mb-3">
                    <Form.Check
                      type='checkbox'
                      id="allItems"
                      label="All Items"
                      checked={billingTermAll}
                      onClick={() => {
                        setBillingTermListGlobal({});
                        // console.log(document.getElementById("Monthly").checked);
                        // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                        billingTermFilter.map((item) => {
                          setBillingTermListGlobal((prev) => ({
                            billingTermGlobal: {
                              ...prev.billingTermGlobal, [item]: !(billingTermAll)
                            }
                          }));
                        })
                        setBillingTermAll(!billingTermAll);
                        // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                      }}
                    />
                  </div>
                  {billingTermFilter.map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        id={type}
                        label={type}
                        checked={billingTermListGlobal.billingTermGlobal[type]}
                        onClick={() => {
                          setBillingTermListGlobal({});
                          billingTermFilter.map((item) => {
                            if (item == type) {
                              // console.log(item, type, (document.getElementById(item)));
                              setBillingTermListGlobal((prev) => ({
                                billingTermGlobal: {
                                  ...prev.billingTermGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                              setBillingTermAll(false);
                            }
                            else {
                              setBillingTermListGlobal((prev) => ({
                                billingTermGlobal: {
                                  ...prev.billingTermGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                            }
                          })
                        }
                        }
                      />
                    </div>
                  ))}
                </Form>}
            </div>
          </Col>
          <Col className="filter-column">
            <h3 className="mb-3">Status</h3>
            <div>
              {
                <Form>
                  <div key={`default-allStatuses`} className="mb-3">
                    <Form.Check
                      type='checkbox'
                      id="allStatuses"
                      label="All Statuses"
                      checked={statusAll}
                      onClick={() => {
                        setStatusListGlobal({});
                        // console.log(document.getElementById("ACTIVE").checked);
                        statusFilter.map((item) => {
                          setStatusListGlobal((prev) => ({
                            statusListGlobal: {
                              ...prev.statusListGlobal, [item]: !(statusAll)
                            }
                          }));
                        })
                        setStatusAll(!statusAll);
                        // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                      }}
                    />
                  </div>
                  {statusFilter.map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        id={type}
                        label={type}
                        checked={statusListGlobal.statusListGlobal[type]}
                        onClick={() => {
                          setStatusListGlobal({});
                          statusFilter.map((item) => {
                            if (item == type) {
                              // console.log(item, type, (document.getElementById(item)));
                              setStatusListGlobal((prev) => ({
                                statusListGlobal: {
                                  ...prev.statusListGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                              setStatusAll(false);
                            }
                            else {
                              setStatusListGlobal((prev) => ({
                                statusListGlobal: {
                                  ...prev.statusListGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                            }
                          })
                        }
                        }
                      />
                    </div>
                  ))}
                </Form>}
            </div>
          </Col>
          <Col className="filter-column">
            <h3 className="mb-3">Subscription Type</h3>
            <div>
              {
                <Form>
                  <div key={`default-allTypes`} className="mb-3">
                    <Form.Check
                      type='checkbox'
                      id="allTypes"
                      label="All Types"
                      checked={subscriptionTypeAll}
                      onClick={() => {
                        setSubscriptionTypeListGlobal({});
                        // console.log(document.getElementById("NCE BASE").checked);
                        subscriptionTypeFilter.map((item) => {
                          setSubscriptionTypeListGlobal((prev) => ({
                            subscriptionTypeListGlobal: {
                              ...prev.subscriptionTypeListGlobal, [item]: !(subscriptionTypeAll)
                            }
                          }));
                        })
                        setSubscriptionTypeAll(!subscriptionTypeAll);
                      }}
                    />
                  </div>
                  {subscriptionTypeFilter.map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        id={type}
                        label={type}
                        checked={subscriptionTypeListGlobal.subscriptionTypeListGlobal[type]}
                        onClick={() => {
                          setSubscriptionTypeListGlobal({});
                          subscriptionTypeFilter.map((item) => {
                            if (item == type) {
                              // console.log(item, type, (document.getElementById(item)));
                              setSubscriptionTypeListGlobal((prev) => ({
                                subscriptionTypeListGlobal: {
                                  ...prev.subscriptionTypeListGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                              setSubscriptionTypeAll(false);
                            }
                            else {
                              setSubscriptionTypeListGlobal((prev) => ({
                                subscriptionTypeListGlobal: {
                                  ...prev.subscriptionTypeListGlobal, [item]: (document.getElementById(item).checked)
                                }
                              }));
                            }
                          })
                        }
                        }
                      />
                    </div>
                  ))}
                </Form>}
            </div>
          </Col>
        </Row>
        <Row className="actions-row">
          <Col className="d-flex justify-content-end">
            {((!((JSON.stringify(subscriptionTypeListGlobal.subscriptionTypeListGlobal).includes("true")) || (JSON.stringify(statusListGlobal.statusListGlobal).includes("true")) || (JSON.stringify(billingTermListGlobal.billingTermGlobal).includes("true"))))) && <Button
              className="btn btn-lg mx-3"
              variant="light"
              disabled
            >
              Clear
            </Button>}
            {((((!subscriptionTypeAll || !statusAll || !billingTermAll)) || (subscriptionTypeAll && statusAll && billingTermAll)) && ((JSON.stringify(subscriptionTypeListGlobal.subscriptionTypeListGlobal).includes("true")) || (JSON.stringify(statusListGlobal.statusListGlobal).includes("true")) || (JSON.stringify(billingTermListGlobal.billingTermGlobal).includes("true")))) && <Button
              className="btn btn-lg mx-3 btn-border-light"
              variant="light"
              onClick={() => {
                setIsGlobalLacalChangeAPIFlag(true);
                ClearGolbalFunction();
                setFilterFlagLocalBilling(false);
                setFilterFlagLocalStatus(false);
                setFilterFlagLocalSubscriptionType(false);
                setSubscriptionTypeAllLocal(false);
                setBillingTermAllLocal(false);
                setStatusAllLocal(false);
              }}
            >
              Clear
            </Button>}
            {/* {emptyFilterSubscriptionTable && <Button
              className="btn btn-lg mx-3"
              variant="primary"
              onClick={() => { GetTableDatas() }}
            >
              Clear
            </Button>} */}
            {(((!subscriptionTypeAll || !statusAll || !billingTermAll)) && ((JSON.stringify(subscriptionTypeListGlobal.subscriptionTypeListGlobal).includes("true")) || (JSON.stringify(statusListGlobal.statusListGlobal).includes("true")) || (JSON.stringify(billingTermListGlobal.billingTermGlobal).includes("true"))))
              && <Button
                className="btn btn-lg"
                variant="primary"
                onClick={() => {
                  setIsGlobalLacalChangeAPIFlag(true);
      
                  let filterFinalFiltersAPIStatus = [];
                  let filterFinalFiltersAPIStatusFalseStorage = [];
                  let filterFinalFiltersAPIStatusBooleanStorage = [];

                  let filterFinalFiltersAPISubscriptionType = [];
                  let filterFinalFiltersAPISubscriptionTypeFalseStorage = [];
                  let filterFinalFiltersAPISubscriptionTypeBooleanStorage = [];

                  let filterFinalFiltersAPIBillingTerm = [];
                  let filterFinalFiltersAPIBillingTermFalseStorage = [];
                  let filterFinalFiltersAPIBillingTermBooleanStorage = [];

                  subscriptionTypeFilter.map((item) => {
                    if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[item] === true) {
                      filterFinalFiltersAPISubscriptionType.push(`${item}`);
                      filterFinalFiltersAPISubscriptionTypeBooleanStorage.push(true);
                    }
                    if (subscriptionTypeListGlobal.subscriptionTypeListGlobal[item] === false) {
                      filterFinalFiltersAPISubscriptionTypeFalseStorage.push(`${item}`);
                      filterFinalFiltersAPISubscriptionTypeBooleanStorage.push(false);
                    }
                  })
                  statusFilter.map((item) => {
                    if (statusListGlobal.statusListGlobal[item] === true) {
                      filterFinalFiltersAPIStatus.push(`${item}`);
                      filterFinalFiltersAPIStatusBooleanStorage.push(true);
                    }
                    if (statusListGlobal.statusListGlobal[item] === false) {
                      filterFinalFiltersAPIStatusFalseStorage.push(`${item}`);
                      filterFinalFiltersAPIStatusBooleanStorage.push(false);
                    }
                  })
                  billingTermFilter.map((item) => {
                    if (billingTermListGlobal.billingTermGlobal[item] === true) {
                      filterFinalFiltersAPIBillingTerm.push(`${item}`);
                      filterFinalFiltersAPIBillingTermBooleanStorage.push(true);
                    }
                    if (billingTermListGlobal.billingTermGlobal[item] === false) {
                      filterFinalFiltersAPIBillingTermFalseStorage.push(`${item}`);
                      filterFinalFiltersAPIBillingTermBooleanStorage.push(false);
                    }
                  })

                  filterFinalFiltersAPISubscriptionType.map((item) => {
                    subscriptionTypeListLocal.subscriptionTypeListLocal[item] = true;
                    // setFilterFlagLocalSubscriptionType(true);
                  })
                  filterFinalFiltersAPISubscriptionTypeFalseStorage.map((item) => {
                    subscriptionTypeListLocal.subscriptionTypeListLocal[item] = false;
                    // setFilterFlagLocalSubscriptionType(false);
                  })
                  if (filterFinalFiltersAPISubscriptionTypeBooleanStorage.includes(true)) {
                    setFilterFlagLocalSubscriptionType(true);
                  }
                  if (!(filterFinalFiltersAPISubscriptionTypeBooleanStorage.includes(true))) {
                    setFilterFlagLocalSubscriptionType(false);
                  }
                  if (subscriptionTypeAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setSubscriptionTypeAllLocal(true);
                  }
                  if (!subscriptionTypeAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setSubscriptionTypeAllLocal(false);
                  }

                  filterFinalFiltersAPIStatus.map((item) => {
                    statusListLocal.statusListLocal[item] = true;
                    // setFilterFlagLocalStatus(true);
                  })
                  filterFinalFiltersAPIStatusFalseStorage.map((item) => {
                    statusListLocal.statusListLocal[item] = false;
                    // setFilterFlagLocalStatus(false);
                  })
                  if (filterFinalFiltersAPIStatusBooleanStorage.includes(true)) {
                    setFilterFlagLocalStatus(true);
                  }
                  if (!(filterFinalFiltersAPIStatusBooleanStorage.includes(true))) {
                    setFilterFlagLocalStatus(false);
                  }
                  if (statusAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setStatusAllLocal(true);
                  }
                  if (!statusAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setStatusAllLocal(false);
                  }

                  filterFinalFiltersAPIBillingTerm.map((item) => {
                    billingTermListLocal.billingTermListLocal[item] = true;
                    // setFilterFlagLocalBilling(true);
                  })
                  filterFinalFiltersAPIBillingTermFalseStorage.map((item) => {
                    billingTermListLocal.billingTermListLocal[item] = false;
                    // setFilterFlagLocalBilling(false);
                  })
                  if (filterFinalFiltersAPIBillingTermBooleanStorage.includes(true)) {
                    setFilterFlagLocalBilling(true);
                  }
                  if (!(filterFinalFiltersAPIBillingTermBooleanStorage.includes(true))) {
                    setFilterFlagLocalBilling(false);
                  }
                  if (billingTermAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setBillingTermAllLocal(true);
                  }
                  if (!billingTermAll) {
                    setGlobalToLocalAllItemFlag(true);
                    setBillingTermAllLocal(false);
                  }

                  // console.log("Status : ", filterFinalFiltersAPIStatus);
                  // console.log("Subscription Type : ", filterFinalFiltersAPISubscriptionType);
                  // console.log("Billing Term : ", filterFinalFiltersAPIBillingTerm);
                  GetFilterData(filterFinalFiltersAPIStatus, filterFinalFiltersAPISubscriptionType, filterFinalFiltersAPIBillingTerm);
                }}
              // disabled
              >
                Apply
              </Button>}
            {((!((JSON.stringify(subscriptionTypeListGlobal.subscriptionTypeListGlobal).includes("true")) || (JSON.stringify(statusListGlobal.statusListGlobal).includes("true")) || (JSON.stringify(billingTermListGlobal.billingTermGlobal).includes("true")))) || (subscriptionTypeAll && statusAll && billingTermAll))
              && <Button
                className="btn btn-lg"
                variant="light"
                disabled
              >
                Apply
              </Button>}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GlobalFilterBox
