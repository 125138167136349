import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import "./styles.scss";
import PasswordToggle from "../../../Components/PasswordToggle";
import BrandDetails from "../BrandDetails";
import { LANDING_PAGE_CMS_DATA, VALIDATE_USER, VALIDATE_OTP } from "../../../Utils/GlobalConstants";
import { cmsApi, api } from "../../../Utils/API";
import SendActivationLink from "../../../Components/ForgotPassword";
import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import {
  Check2Circle,
  XCircle,
} from "react-bootstrap-icons";
import MfaVerification from "../../../Components/MfaVerification";
import Footer from "../../../Layouts/Footer";
import InstallPWA from "../../../Layouts/InstallPWA";

const LoginCustomer = (props) => {

  // UseState Defination
  const [mobileActive, setMobileActive] = useState("");                           // Mobile Screen Check
  const [bannerData, setBannerData] = useState();                                 // Banner Data from CMS
  const [backgroundImageUrl, setBackgroundImageUrl] = useState();                 // BG Image from CMS
  const [landingPageData, setLandingPageData] = useState();                       // Landing Page Data from CMS
  const [activationLink, setActivationLink] = useState(false);                    // Send activation link is open
  const globalData = useContext(CmsDetailsContext);                               // Global Data from context
  const [loginEmailAddress, setLoginEmailAddress] = useState("");                 // Login Page Email Address
  const [loginPassword, setLoginPassword] = useState("");                         // Login Page Password
  const [isTwoFactorEnabledFlag, setIsTwoFactorEnabledFlag] = useState(false);    // Check MFA is enabled
  const [verificationCode, setVerificationCode] = useState("");                   // MFA Verification Code
  const [loginInitiatedFlag, setLoginInitiatedFlag] = useState(false);                   // MFA Verification Code

  // Use State for Toasts
  const [myProfileSuccess, SetMyProfileSuccess] = useState(false);
  const [myProfileError, setMyProfileError] = useState(false);
  const [myProfileToastMessage, setMyProfileToastMessage] = useState("");

  const [defferedPrompt, setDefferedPrompt] = useState();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      setDefferedPrompt(e);
    })
  }, [])

  // Defining UseNavigate for react router DOM v6
  const navigate = useNavigate();

  // To set different Layout for Mobile View with 2 screen Component
  const showActivationLink = () => {
    setActivationLink(!activationLink);
  };

  // To activate CSS Class when on mobile Screen
  const showMobileSceen = () => {
    setMobileActive("active");
  };

  // Function for calling Landing Page data from Orchid Core CMS
  async function GetLandingPageData() {
    // console.log("CMS Data Fetching Initiated");
    const data = `query {
      landingPage {
        bannerText
        forgotPassword {
          teaserText
          welcomeText
        }
        login {
          teaserText
          welcomeText
        }
        twoFactorAuthentication {
          teaserText
          welcomeText
        }
        bannerImage {
          paths
          urls
        }
      }
    }`;
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log("Login Image URL : ", globalData.baseUrl + resp.data.data.landingPage[0].bannerImage.urls[0]);
        setLandingPageData(resp.data.data.landingPage[0]);
        setBannerData(resp.data.data.landingPage[0]);
        setBackgroundImageUrl(
          `url(${globalData.baseUrl + resp.data.data.landingPage[0].bannerImage.urls[0]})`
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  // Adding CSS Style to Landing Page Main Component
  const loginComponent = {
    backgroundImage: backgroundImageUrl,
    backgroundSize: "cover",
  };

  // To navigate user To management Console when a user gets logged in
  if (props.customerIsAuthenticated) {
    navigate("/management-console/");
  }

  // Login API to validate Credentials
  async function LogingIn() {
    setLoginInitiatedFlag(true);
    // console.log("UserName : ", loginEmailAddress, " Password : ", loginPassword);
    const data = {
      "userName": loginEmailAddress,
      "password": loginPassword
    };
    await api
      .post(VALIDATE_USER, data)
      .then((resp) => {
        // handle success
        // console.log(resp.data);
        let profileInitialCredential = (resp.data.firstName.slice(0, 1) + resp.data.lastName.slice(0, 1))
        let profileName = resp.data.firstName + " " + resp.data.lastName
        sessionStorage.setItem("profile_initial_credential", profileInitialCredential);
        sessionStorage.setItem("profile_name", profileName);
        setIsTwoFactorEnabledFlag(resp.data.isTwoFactorEnabled);
        if (!resp.data.isTwoFactorEnabled) {
          setMyProfileToastMessage("User Authenticated Successfully");
          SetMyProfileSuccess(true);
          setTimeout(() => {
            SetMyProfileSuccess(false);
          }, 2000);
          // GetToken();
          setTimeout(() => {
            sessionStorage.setItem("access_token", resp.data.token);
            sessionStorage.setItem("token_type", resp.data.tokenType);
            sessionStorage.setItem("Expire_time", resp.data.expire);
            sessionStorage.setItem("Accepted_Version", resp.data.acceptedTermsVersion);
            props.setCustomerIsAuthenticated(true);
          }, 1000);
        }
        else {
          setLoginInitiatedFlag(false);
        }
      })
      .catch((error) => {
        // console.log(error.response.data.errors[0]);
        setMyProfileToastMessage(`${error.response.data.errors[0]} Please try again !`);
        setLoginInitiatedFlag(false);
        setMyProfileError(true);
        setTimeout(() => {
          setMyProfileError(false);
        }, 2000);
      });
  };

  // MFA Code Verify Function
  async function VerifyCode() {
    // console.log("Code : ", verificationCode);
    const data = {
      "userName": loginEmailAddress,
      "password": loginPassword,
      "otp": verificationCode
    };
    await api
      .post(VALIDATE_OTP, data)
      .then((resp) => {
        // handle success
        // console.log(resp.data);
        setMyProfileToastMessage("OTP Verified Successfully");
        SetMyProfileSuccess(true);
        setTimeout(() => {
          SetMyProfileSuccess(false);
          setLoginInitiatedFlag(false);
        }, 2000);
        sessionStorage.setItem("access_token", resp.data.token);
        sessionStorage.setItem("token_type", resp.data.tokenType);
        sessionStorage.setItem("Expire_time", resp.data.expire);
        sessionStorage.setItem("Accepted_Version", resp.data.acceptedTermsVersion);
        props.setCustomerIsAuthenticated(true);
      })
      .catch((error) => {
        // console.log(error);
        setLoginInitiatedFlag(false);
        setMyProfileToastMessage(`${error.response.data.errors[0]} Please try again !`);
        setMyProfileError(true);
        setTimeout(() => {
          setMyProfileError(false);
        }, 2000);
      });
  };

  // Defining UseEffect to call CMS landing Page Data
  useEffect(() => {
    if (globalData)
      GetLandingPageData();
  }, [globalData]);

  return (
    <>
      {/* {defferedPrompt && <Button className="pwa-install-button" onClick={async () => {
        if (defferedPrompt !== null) {
          defferedPrompt.prompt();
          const { outcome } = await defferedPrompt.userChoice;
          if (outcome === 'accepted') {
            setDefferedPrompt(null);
          }
        }
      }}>Install
      </Button>} */}
      {/* Toasts */}
      <div className={`landing-page-alert ${myProfileSuccess ? "active" : ""}`}>
        <Alert variant="success">
          <div className='d-flex align-items-center'>
            <Check2Circle size={24} color="#1D874C" />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>

      <div className={`landing-page-alert ${myProfileError ? "active" : ""}`}>
        <Alert variant="danger">
          <div className='d-flex align-items-center'>
            <XCircle size={24} color="#9C3B35" />
            <span className="mx-3 text-medium">{myProfileToastMessage}</span>
          </div>
        </Alert>
      </div>

      <div className="d-flex flex-column login-page" style={loginComponent}>
        <Container>
          {landingPageData && (
            <Row>
              <Col md={6}>
                <BrandDetails
                  handleClick={showMobileSceen}
                  bannerData={bannerData}
                />
              </Col>
              <Col md={6}>

                {/* Default Login Page */}
                {!activationLink && !isTwoFactorEnabledFlag && (
                  <>
                    <div className={`anonymous-login-main-div ${mobileActive}`}>
                      <Col xl={8} lg={10} md={12} className="login-block">
                        <div>
                          {landingPageData && (
                            <>
                              <div className="d-none d-md-block">
                                <h1>{landingPageData.login.welcomeText}</h1>
                                <p>{landingPageData.login.teaserText}</p>
                              </div>

                              {/* Mobile Display Text */}
                              <div className="d-block d-md-none">
                                <h1>{landingPageData.login.welcomeText}</h1>
                                <p>{landingPageData.login.teaserText}</p>
                              </div>
                            </>
                          )}
                          <Form onSubmit={LogingIn}>
                            <Form.Group
                              className="form-group"
                              controlId="userName"
                            >
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Email Address"
                                value={loginEmailAddress}
                                onChange={(e) => (e.target.value.length <= 250 ? setLoginEmailAddress(e.target.value) : "")}
                              />
                              <Form.Text className="text-danger d-none">
                                Enter User Name
                                <br />
                              </Form.Text>
                            </Form.Group>

                            <Form.Group
                              className="form-group"
                              controlId="password"
                            >
                              <Form.Label>Password</Form.Label>

                              <PasswordToggle
                                inputType="password"
                                placeholder="Enter Password"
                                passwordValue={loginPassword}
                                passwordToggleEvent={(e) => (e.target.value.length <= 15 ? setLoginPassword(e.target.value) : "")}
                              />

                              <Form.Text className="text-danger d-none">
                                Enter Password
                              </Form.Text>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 d-none"
                              controlId="formBasicCheckbox"
                            >
                              <Form.Check type="checkbox" label="Remember me" />
                            </Form.Group>
                            <div className="d-none d-md-block hidden-btn">
                              {!loginInitiatedFlag && (loginEmailAddress.length > 0 && loginPassword.length > 0) &&
                                <Button
                                  className="w-0 h-0"
                                  variant="none"
                                  type="submit"
                                  onClick={() => {
                                    LogingIn();
                                  }}
                                >
                                  &nbsp;
                                </Button>}
                            </div>

                            {/* <div className="d-none">
                              {!loginInitiatedFlag && (loginEmailAddress.length > 0 && loginPassword.length > 0) &&
                                <Button
                                  className="col-12"
                                  type="submit"
                                  onClick={() => {
                                    LogingIn();
                                  }}
                                >
                                  Sign In
                                </Button>}
                              {(loginInitiatedFlag || (loginEmailAddress.length === 0 || loginPassword.length === 0)) && <Button
                                className="col-12"
                                // type="submit"
                                variant="light"
                                disabled
                              >
                                Sign In
                              </Button>}

                              <p className="text-center login-back-link">
                                <label onClick={()=>{
                                  showActivationLink();
                                  setLoginEmailAddress("");
                                  setLoginPassword("");
                                }}>
                                  Forgot Password?
                                </label>
                              </p>
                            </div> */}
                          </Form>
                        </div>
                        <div className="mobile-login-btns">
                          <div className="col-12">
                            <div className="col-12">
                              {!loginInitiatedFlag && (loginEmailAddress.length > 0 && loginPassword.length > 0) &&
                                <Button
                                  className="col-12"
                                  type="submit"
                                  onClick={() => {
                                    LogingIn();
                                  }}
                                >
                                  Sign In
                                </Button>}
                              {(loginInitiatedFlag || (loginEmailAddress.length === 0 || loginPassword.length === 0)) && <Button
                                className="col-12"
                                // type="submit"
                                variant="light"
                                disabled
                              >
                                Sign In
                              </Button>}
                            </div>
                            <p className="text-center login-back-link w-100 col-12">
                              <label onClick={showActivationLink}>
                                Forgot Password?
                              </label>
                            </p>
                          </div>
                        </div>
                      </Col>
                      {/* <Footer customClass="d-block d-md-none" /> */}
                    </div>
                  </>
                )}

                {/* Navigate user to Send Activation Link Component */}
                {activationLink && (
                  <SendActivationLink
                    showLogin={showActivationLink}
                    forgetPasswordData={landingPageData}
                    setMyProfileToastMessage={setMyProfileToastMessage}
                    setMyProfileError={setMyProfileError}
                    SetMyProfileSuccess={SetMyProfileSuccess}
                  />
                )}

                {/* Navigate user to MFA Verification Code */}
                {
                  isTwoFactorEnabledFlag &&
                  <MfaVerification
                    verificationCode={verificationCode}
                    mfaData={landingPageData}
                    setIsTwoFactorEnabledFlag={setIsTwoFactorEnabledFlag}
                    setVerificationCode={setVerificationCode}
                    VerifyCode={VerifyCode}
                  />
                }

              </Col>
            </Row>
          )}
        </Container>
      </div>
      <InstallPWA />
    </>
  );
};

export default LoginCustomer;
