import React, { useContext, useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./styles.scss";

const InstallPWA = () => {

    const [defferedPrompt, setDefferedPrompt] = useState();
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            setDefferedPrompt(e);
        })
    }, [])

    return (
        <>
            {defferedPrompt &&
                <Container fluid className="install-btn">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h3>Install App</h3>
                                <p className="mb-0">Enhance your experience by installing app</p>
                            </Col>
                            <Col md={6} className="text-right btn-wrapper-outer">

                                <Button onClick={async () => setDefferedPrompt(null)}
                                    className="btn-lg mr-3 mx-3 btn-border-light"
                                    variant="light"
                                >
                                    Not Now
                                </Button>
                                <Button onClick={async () => {
                                    if (defferedPrompt !== null) {
                                        defferedPrompt.prompt();
                                        const { outcome } = await defferedPrompt.userChoice;
                                        if (outcome === 'accepted') {
                                            setDefferedPrompt(null);
                                        }
                                    }
                                }}>Install
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </>
    )
}

export default InstallPWA