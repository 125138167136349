import React, { useState, useRef } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg, ArrowLeftCircle } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const OrderSummary = (
  {
    showPanel,
    showPriceFlag,
    emptyDropdownMyCloudSrvicesflag,
    updatedQuantityIntermediate,
    emptySubscriptionTable,
    showPanelDetails,
    orderSummaryArray,
    EmptyOrderSummaryIllustration,
    setUpdatedQuantityIntermediateErrorState,
    setUpdatedQuantityIntermediate,
    updatedQuantityIntermediateErrorState,
    orderSummaryQuantityCount,
    setShowPanel,
    handleDelete,
    setIntermediateOrderSummaryArray,
    setOrderSummaryEditIntermediateFlag,
    setSelectedOrderSummaryArray,
    ConfirmEditQuantity,
    setEditOrderNoteConfirmation,
    orderSummaryTotalPriceCount,
    setOrderSummaryArray,
    editOrderNoteConfirmation,
    orderSummaryProcessingButtonCheck,
    setOrderSummaryProcessingButtonCheck,
    isProceed,
    setIsProceed,
  }
) => {
  const licenceQuantity = useRef();

  const [increaseQuantity, setIncreaseQuantity] = useState(true);
  const [decreaseQuantity, setDecreaseQuantity] = useState(true);

  const [isIncreaseAllowed, setIsIncreaseAllowed] = useState(true);
  const [isDecreaseAllowed, setIsDecreaseAllowed] = useState(true);

  const [isConditionsAccepted, setIsConditionsAccepted] = useState(true);

  const date = new Date(); 


  return (
    <div
      className="col py-0 order-summery d-none d-md-flex"
      style={{
        flex: !showPanel ? "1 0" : "1",
        overflow: "hidden",
        opacity: "1",
        width: "505px",
        position: "absolute",
        left: !showPanel ? "calc(100% - 505px)" : "calc(100% - 25px)",
        transition: "all 0.3s",
      }}
    >
      <div className="order-summery-toggle-block">
        <span
          className={`order-summery-toggle ${!showPanel ? "active" : ""}`}
          onClick={() => {
            if (!emptyDropdownMyCloudSrvicesflag && !emptySubscriptionTable) {
              showPanelDetails();
            }
          }
          }
        >
          &nbsp;
        </span>
      </div>
      <Container className="px-0">
        <div className="order-summery-inner-block">
          <h2 className="p-3 m-0">Order Summary</h2>
          {orderSummaryArray.length == 0 && (
            <>
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: "100%" }}>
                <img src={EmptyOrderSummaryIllustration} className="empty-order-summary-svg" />
                <p className="p-1 mb-5 text-center">
                  The order summary is currently empty <br /> since no plans have been edited.
                </p>
              </div>
            </>
          )}
          {orderSummaryArray.length > 0 && (
            <>
              <div className="order-summery-mid-content">
                <div className='order-summery-mid-inner-content'>
                  <div className="order-summery-inner-block-header">
                    <Row className="head m-0">
                      <Col xs={showPriceFlag ? 4 : 5} className="px-2">
                        <span>Plan</span>
                      </Col>
                      <Col xs={showPriceFlag ? 2 : 3} className="text-left pr-1">
                        <span>Current Qty</span>
                      </Col>
                      <Col xs={showPriceFlag ? 2 : 3} className="text-right pr-1">
                        <span>Net Qty</span>
                      </Col>
                      {showPriceFlag && <Col xs={3} className="text-center pr-1">
                        <span>Net Price</span>
                      </Col>}
                      <Col xs={1}>
                        <span>&nbsp;</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="order-summery-inner-block-content">
                    {orderSummaryArray.map((item) => {
                      return (
                        <Row className="content">
                          {(item.parentPlanName.length > 0) &&
                            <Col xs={showPriceFlag ? 4 : 5} className="px-3 pr-2">
                              <p>{item.parentPlanName}</p>
                              <ul className="addon-plan-names">
                                <li>{item.planName}</li>
                              </ul>
                            </Col>}
                          {(item.parentPlanName.length == 0) &&
                            <Col xs={showPriceFlag ? 4 : 5} className="px-3 pr-2">
                              <p>{item.planName}</p>
                            </Col>}
                          <Col xs={showPriceFlag ? 2 : 3} className="text-right pr-1">
                            {item.defaultQuantity}
                          </Col>
                          <Col xs={showPriceFlag ? 2 : 3} className="text-right">
                            {item.netQuantity}
                            <Popup
                            onOpen={() => {
                              (item.defaultQuantity + item.netQuantity) < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                              (item.defaultQuantity + item.netQuantity) > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                            }
                            }
                              onClose={() => {
                                setUpdatedQuantityIntermediateErrorState(false);
                                setUpdatedQuantityIntermediate(0);
                                setIncreaseQuantity(true);
                                setDecreaseQuantity(true);
                                setIsIncreaseAllowed(true);
                                setIsDecreaseAllowed(true);
                              }
                              }
                              trigger={
                                <span className="editpencil mx-3 mr-0">&nbsp;</span>
                              }
                              className="custom-modal"
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <div className="header">
                                    <h2>Edit Quantity</h2>
                                    <XLg
                                      size={24}
                                      className="cpointer close-icon-popup"
                                      onClick={close}
                                    />
                                  </div>
                                  <Container>
                                    <Row>
                                      <Col xs={12} className="mb-4">
                                        <p className="text-regular mb-4"><span className='text-strong '>Plan :</span> {item.planName}</p>
                                      </Col>
                                      <Col md={4} className="col-6">
                                        <p className="mb-0">Enter New Quantity</p>
                                        {!updatedQuantityIntermediateErrorState && <p className="text-small text-disabled">
                                          {"( Min " +
                                            item.minQuantity +
                                            ", Max " +
                                            item.maxQuantity +
                                            " )"}
                                        </p>}
                                        {updatedQuantityIntermediateErrorState && <p className="text-small text-danger">
                                          {"( Min " +
                                            item.minQuantity +
                                            ", Max " +
                                            item.maxQuantity +
                                            " )"}
                                        </p>}
                                      </Col>
                                      <Col md={4} className="col-6">
                                        <div class="number-input">

                                          <Form.Control
                                            type="number"
                                            placeholder="Enter Quantity"
                                            defaultValue={(item.defaultQuantity + item.netQuantity)}
                                            min={item.minQuantity}
                                            max={item.maxQuantity}
                                            ref={licenceQuantity}
                                            disabled={!isIncreaseAllowed && !isDecreaseAllowed}
                                            onInput={(e) => {
                                              e.target.value =
                                                !!e.target.value && Math.abs(e.target.value) >= item.minQuantity ? Math.abs(e.target.value) : null;

                                              e.target.value =
                                                !!e.target.value && Math.abs(e.target.value) <= item.maxQuantity ? Math.abs(e.target.value) : null;

                                              if (!isIncreaseAllowed && e.target.value > (item.defaultQuantity + item.netQuantity)) {
                                                e.target.value = null
                                              }

                                              if (!isDecreaseAllowed && e.target.value < (item.defaultQuantity + item.netQuantity)) {
                                                e.target.value = null
                                              }

                                            }}
                                            onChange={(e) => {
                                              if (
                                                e.target.value >= item.minQuantity &&
                                                e.target.value <= item.maxQuantity
                                              ) {
                                                setUpdatedQuantityIntermediateErrorState(false);
                                                setUpdatedQuantityIntermediate(e.target.value);
                                                e.target.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                                e.target.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                              } else {
                                                setUpdatedQuantityIntermediateErrorState(true);
                                              }

                                              if (e.target.value <= item.minQuantity) {
                                                setDecreaseQuantity(false);
                                              } else {
                                                setDecreaseQuantity(true);
                                              }
                                            }
                                            }
                                          />

                                          <div className="btn-wrapper">

                                            {isIncreaseAllowed &&
                                              <button className="plus" onClick={() => {

                                                if (licenceQuantity.current.value < item.maxQuantity) {
                                                  licenceQuantity.current.value = parseInt(licenceQuantity.current.value) + 1;
                                                  setUpdatedQuantityIntermediateErrorState(false);
                                                  setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                                } else {
                                                  setUpdatedQuantityIntermediateErrorState(true);
                                                }

                                                console.log(licenceQuantity.current.value < item.maxQuantity);

                                                licenceQuantity.current.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                                licenceQuantity.current.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                              }
                                              }>+</button>}

                                            {!isIncreaseAllowed &&
                                              <button className="plus disabled" disabled>+</button>
                                            }

                                            {isDecreaseAllowed && <button className="minus" onClick={
                                              () => {
                                                {
                                                  if (licenceQuantity.current.value > item.minQuantity) {
                                                    licenceQuantity.current.value = parseInt(licenceQuantity.current.value) - 1;
                                                    setUpdatedQuantityIntermediateErrorState(false);
                                                    setUpdatedQuantityIntermediate(licenceQuantity.current.value);
                                                  } else {
                                                    setUpdatedQuantityIntermediateErrorState(true);
                                                  }

                                                  licenceQuantity.current.value < item.maxQuantity ? setIsIncreaseAllowed(true) : setIsIncreaseAllowed(false);
                                                  licenceQuantity.current.value > item.minQuantity ? setIsDecreaseAllowed(true) : setIsDecreaseAllowed(false);
                                                }
                                              }
                                            }> <span>&nbsp;</span> </button>}

                                            {!isDecreaseAllowed &&
                                              <button className="minus disabled" disabled><span>&nbsp;</span></button>
                                            }
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Table
                                      bordered
                                      className="quantity-table"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="col-md-3 col-sm-3"></th>
                                          <th className="col-md-3 col-sm-3">Current</th>
                                          <th className="col-md-3 col-sm-3">Updated</th>
                                          <th className="col-md-3 col-sm-3">Net</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Quantity</td>
                                          <td>{item.defaultQuantity}</td>
                                          {updatedQuantityIntermediate ===
                                            0 && <td>{item.defaultQuantity + item.netQuantity}</td>}
                                          {updatedQuantityIntermediate >
                                            0 && (
                                              <td>
                                                {updatedQuantityIntermediate}
                                              </td>
                                            )}
                                          {updatedQuantityIntermediate ===
                                            0 && <td>{item.netQuantity}</td>}
                                          {updatedQuantityIntermediate >
                                            0 && (
                                              <td>
                                                {updatedQuantityIntermediate -
                                                  item.defaultQuantity}
                                              </td>
                                            )}
                                        </tr>
                                        {showPriceFlag && <tr>
                                          <td>Price</td>
                                          <td>
                                            $
                                            {Intl.NumberFormat("en-US").format((item.customerPrice *
                                              item.defaultQuantity).toFixed(2))}
                                          </td>
                                          {updatedQuantityIntermediate ===
                                            0 &&
                                            <td>
                                              $
                                              {Intl.NumberFormat("en-US").format((
                                                item.customerPrice *
                                                (item.defaultQuantity + item.netQuantity)
                                              ).toFixed(2))}
                                            </td>
                                          }
                                          {updatedQuantityIntermediate >
                                            0 && (
                                              <td>
                                                $
                                                {Intl.NumberFormat("en-US").format((
                                                  item.customerPrice *
                                                  updatedQuantityIntermediate
                                                ).toFixed(2))}
                                              </td>
                                            )}

                                          {updatedQuantityIntermediate ===
                                            0 && <td>
                                              {(updatedQuantityIntermediate - item.defaultQuantity) >= 0 ? "$" : "-$"}
                                              {Intl.NumberFormat("en-US").format(Math.abs(
                                                item.customerPrice *
                                                (item.defaultQuantity + item.netQuantity) -
                                                item.customerPrice *
                                                item.defaultQuantity
                                              ).toFixed(2))}
                                            </td>}
                                          {updatedQuantityIntermediate >
                                            0 && (
                                              <td>
                                                {(updatedQuantityIntermediate - item.defaultQuantity) >= 0 ? "$" : "-$"}
                                                {Intl.NumberFormat("en-US").format(Math.abs(
                                                  item.customerPrice *
                                                  updatedQuantityIntermediate -
                                                  item.customerPrice *
                                                  item.defaultQuantity
                                                ).toFixed(2))}
                                              </td>
                                            )}
                                        </tr>}
                                      </tbody>
                                      <tfoot>
                                        {showPriceFlag && <tr>
                                          <td colSpan={3} className='edit-quantity-popup-total'><strong>Total</strong></td>
                                          <td>
                                            {(updatedQuantityIntermediate - item.defaultQuantity) >= 0 ? "$" : "-$"}
                                            {updatedQuantityIntermediate ===
                                              0 && Intl.NumberFormat("en-US").format(Math.abs(
                                                item.customerPrice *
                                                (item.defaultQuantity + item.netQuantity) -
                                                item.customerPrice *
                                                item.defaultQuantity
                                              ).toFixed(2))}
                                            {updatedQuantityIntermediate >
                                              0 &&
                                              Intl.NumberFormat("en-US").format(Math.abs(
                                                item.customerPrice *
                                                updatedQuantityIntermediate -
                                                item.customerPrice *
                                                item.defaultQuantity
                                              ).toFixed(2))}
                                          </td>
                                        </tr>}
                                      </tfoot>
                                    </Table>
                                    {(((item.customerPrice * updatedQuantityIntermediate - item.customerPrice * item.defaultQuantity).toFixed(2) <= 0)) && <p className="mb-3 mt-0 text-small text-faded">*Plan cost is provided under 'Net price', final cost may vary based on vendor’s billing logic.</p>}
                                    {(((item.customerPrice * updatedQuantityIntermediate - item.customerPrice * item.defaultQuantity).toFixed(2) > 0)) && <p className="mb-3 mt-0 text-small text-faded">&nbsp;</p>}

                                    <div className="actions">
                                      <div className="d-flex justify-content-end">
                                        <Button
                                          className="btn-lg mr-3 mx-3 btn-border-light"
                                          variant="light"
                                          onClick={() => {
                                            // console.log(
                                            //   "modal closed "
                                            // );
                                            close();
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                        {((updatedQuantityIntermediateErrorState || updatedQuantityIntermediate ===
                                          0) || (updatedQuantityIntermediate == item.defaultQuantity)) && (
                                            <Button
                                              className="btn btn-lg"
                                              variant="light"
                                              disabled
                                            >
                                              Proceed
                                            </Button>
                                          )}
                                        {!updatedQuantityIntermediateErrorState && (updatedQuantityIntermediate > 0 && updatedQuantityIntermediate != item.defaultQuantity) && (
                                          <Button
                                            className="button btn-lg"
                                            onClick={() => {
                                              // let checkFlag=false;
                                              setIntermediateOrderSummaryArray(
                                                {
                                                  parentPlanName:
                                                    item.parentPlanName,
                                                  vendorSubscriptionId:
                                                    item.vendorSubscriptionId,
                                                  customerPrice:
                                                    item.customerPrice,
                                                  planName:
                                                    item.planName,
                                                  subId:
                                                    item.subId,
                                                  minQuantity:
                                                    item.minQuantity,
                                                  maxQuantity:
                                                    item.maxQuantity,
                                                  defaultQuantity:
                                                    item.defaultQuantity,
                                                  updatedQuantity:
                                                    updatedQuantityIntermediate,
                                                  netQuantity:
                                                    updatedQuantityIntermediate -
                                                    item.defaultQuantity,
                                                  defaultPrice:
                                                    item.customerPrice *
                                                    item.defaultQuantity,
                                                  updatedPrice: (
                                                    item.customerPrice *
                                                    updatedQuantityIntermediate
                                                  ).toFixed(2),
                                                  netPrice: (
                                                    item.customerPrice *
                                                    updatedQuantityIntermediate -
                                                    item.customerPrice *
                                                    item.defaultQuantity
                                                  ).toFixed(2),
                                                }
                                              );
                                              setShowPanel(false);
                                              setOrderSummaryEditIntermediateFlag(true);
                                              close();
                                            }}
                                          >
                                            Proceed
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </Container>

                                </div>
                              )}
                            </Popup>
                          </Col>
                          {showPriceFlag && <Col xs={3} className="text-right">
                            <strong>{(item.netQuantity) >= 0 ? "$" : "-$"}{Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(Math.abs(item.netPrice))}</strong>
                          </Col>}
                          <Col xs={1}>
                            <a onClick={() => handleDelete(item.subId)}>
                              <span className="trash cpointer">&nbsp;</span>
                            </a>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
                <div className="order-summery-inner-block-footer">
                  <Row className="foot">
                    <Col xs={showPriceFlag ? 4 : 5} className="px-3">
                      <span className="text-strong">
                        Total
                      </span>
                    </Col>
                    <Col xs={showPriceFlag ? 2 : 3} className="text-right">
                      &nbsp;
                    </Col>
                    <Col xs={showPriceFlag ? 2 : 3} className="text-right text-strong">
                      {orderSummaryQuantityCount}
                      <span className="editpencil mx-3 mr-0 opacity-0">&nbsp;</span>
                    </Col>
                    {showPriceFlag && <Col xs={3} className="text-right text-strong">
                      <span>{(orderSummaryQuantityCount) >= 0 ? "$" : "-$"}{Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(Math.abs(orderSummaryTotalPriceCount))}</span>
                    </Col>}
                    <Col xs={1}>
                      &nbsp;
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="d-flex justify-content-end py-2 px-3">
                <Popup
                  className="custom-modal custom-modal-md"
                  trigger={
                    <Button
                      variant="light"
                      className="btn btn-lg mx-3 btn-border-light"
                    >
                      Clear
                    </Button>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <div className="header p-4 py-3">
                        <h2 className="mb-0 px-3">Order Summary</h2>
                        <XLg
                          size={18}
                          className="cpointer text-strong close-icon-popup-sm"
                          onClick={close}
                          color="#6A6A6A"
                        />
                      </div>
                      <Container className="p-5">
                        <p className='pt-0 px-3 pb-5 mb-0'>
                          <strong>Are you sure you want to clear the <br /> edited items ({orderSummaryArray.length})?</strong>
                        </p>
                        <div className="actions">
                          <div className="d-flex justify-content-center pb-3 pt-2">
                            <Button
                              className="btn btn-lg mx-3 btn-border-light"
                              variant="light"
                              onClick={() => {
                                // console.log("modal closed ");
                                close();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn btn-md"
                              onClick={() => {
                                setSelectedOrderSummaryArray([]);
                                setOrderSummaryArray([]);
                                setShowPanel(true);
                                close();
                              }}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </Container>
                    </div>
                  )}
                </Popup>
                <Popup
                  className="custom-modal custom-modal-md confirm-order-summary"
                  trigger={<Button>Confirm</Button>}
                  modal
                  closeOnDocumentClick={false}
                  nested
                  onClose={() => {setIsProceed(false);setIsConditionsAccepted(true);}}
                >
                  {(close) => (
                    <div className="modal">
                      <div className="header py-4">
                        <h2 className="mb-0">Order Summary</h2>
                        <XLg
                          size={18}
                          className="cpointer text-strong close-icon-popup-sm"
                          onClick={() => { if (!orderSummaryProcessingButtonCheck) { close(); } }}
                          color="#6A6A6A"
                        />
                      </div>
                      {!isProceed && <Container className="p-0">

                        {/* <p>Are you sure you want to confirm ?</p> */}
                        <div className="order-summery-inner-block">
                          <div>
                            <Row className="head m-0">
                              <Col>
                                <Row>
                                  <Col xs={6}>
                                    <span className='confirm-order-summary-table-bold'>Plan</span>
                                  </Col>
                                  <Col xs={showPriceFlag ? 2 : 6} className="text-right">
                                    <span className='confirm-order-summary-table-bold'>Net Qty</span>
                                  </Col>
                                  {showPriceFlag && <Col xs={4} className="text-right">
                                    <span className='confirm-order-summary-table-bold'>Net Price</span>
                                  </Col>}
                                </Row>
                              </Col>
                            </Row>

                            <Row className="m-0 osc-content-row">
                              <Col>

                                {orderSummaryArray.map((item) => {
                                  return (
                                    <Row className="content osc-content confirm-order-summary-table-bold">
                                      {(item.parentPlanName.length > 0) &&
                                        <Col xs={6}>
                                          <div>
                                            {item.planName}
                                            {/* <b>{item.parentPlanName}</b><br/><li>{item.planName}</li><br/>{(item.vendorSubscriptionId).toUpperCase()} */}
                                          </div>
                                        </Col>}
                                      {(item.parentPlanName.length == 0) &&
                                        <Col xs={6}>
                                          <div>
                                            {item.planName}
                                            {/* {item.planName}<br/>{(item.vendorSubscriptionId).toUpperCase()} */}
                                          </div>
                                        </Col>}
                                      <Col xs={showPriceFlag ? 2 : 6} className="text-right">
                                        {item.netQuantity}
                                      </Col>
                                      {showPriceFlag && <Col xs={4} className="text-right">
                                        {(item.netQuantity) >= 0 ? "$" : "-$"}{Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(Math.abs(item.netPrice))}
                                      </Col>}
                                    </Row>
                                  );
                                })}
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row className="foot m-0">
                              <Col className="py-0">
                                <Row className="m-0">
                                  <Col xs={6}>
                                    <p className="mb-0"><strong>Total</strong></p>
                                  </Col>
                                  <Col xs={showPriceFlag ? 2 : 6} className="text-right">
                                    {orderSummaryQuantityCount}
                                  </Col>
                                  {showPriceFlag && <Col xs={4} className="text-right ">
                                    {(orderSummaryQuantityCount) >= 0 ? "$" : "-$"}{Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(Math.abs(orderSummaryTotalPriceCount))}
                                  </Col>}
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Container>
                          <Row className="m-0">
                            <Col>
                              <p className="mb-3 mt-1 text-small text-faded confirm-popup-disclaimer">*Plan cost is provided under 'Net price', final cost may vary based on vendor’s billing logic.</p>
                              <Form.Group className="form-group mb-1" controlId="other">
                                <Form.Label>Order Notes</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Add Order Notes"
                                  value={editOrderNoteConfirmation}
                                  onChange={(e) => { if (e.target.value.length < 1001) setEditOrderNoteConfirmation(e.target.value) }}
                                />
                              </Form.Group>
                              <div className="actions py-4">
                                <div className="d-flex justify-content-end">
                                  <Button
                                    className="button mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => {
                                      // console.log("modal closed ");
                                      close();
                                    }}
                                  >
                                    Cancel
                                  </Button>

                                  <Button
                                    className="button"
                                    onClick={() => {
                                      setIsProceed(true);
                                      // close();
                                    }}
                                  >
                                    Proceed
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Container>}
                      {isProceed &&
                        <Container className="p-0">
                          <div className="order-summery-inner-block">
                            <Container className="px-4">
                              <p className="text-disabled">Disclaimer</p>

                              <div className="terms-block">

                                <p>By placing an order on this website, you acknowledge and agree:</p>

                                <ol>
                                  <li><span className='bold-disclaimer'>Payment Liability: </span>You are liable to pay the order amount post-order to your provider.</li>
                                  <li><span className='bold-disclaimer'>User Data Logging: </span>User details and metadata are logged for order verification and tracking purposes.</li>
                                </ol>
                              </div>
                              <Row className="my-4">
                                <Col md={6}>
                                  <p>Customer Name <br /> <strong>{sessionStorage.getItem("profile_name")}</strong></p>
                                </Col>
                                <Col md={6}>
                                  <p>Created Date <br />
                                    <strong>{String(date.getDate()).padStart(2, '0')}/{String(date.getMonth()+1).padStart(2,"0")}/{date.getFullYear()}</strong></p>
                                </Col>

                                <Col md={12}>
                                  <Form.Check // prettier-ignore
                                    id="conditions"
                                    onChange={() => { setIsConditionsAccepted(!isConditionsAccepted) }}
                                    className="form-check-lg text-small"
                                    // style={{alignItems:"flex-start"}}
                                    label="I confirm the acceptance of these terms regarding payment liability and user data logging."
                                  />
                                </Col>
                              </Row>
                            </Container>
                            <Container className="px-4">
                              <Row className="pb-3">
                                <Col>
                                  <div className="actions py-4">
                                    <div className="d-flex justify-content-end">
                                      {!orderSummaryProcessingButtonCheck && <Button
                                        className="button mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                          // console.log("modal closed ");
                                          setIsProceed(false);
                                          close();
                                        }}
                                      >
                                        Cancel
                                      </Button>}
                                      {orderSummaryProcessingButtonCheck && <Button
                                        className="button mx-3 btn-border-light"
                                        variant="light"
                                        disabled
                                      >
                                        Cancel
                                      </Button>}
                                      {!orderSummaryProcessingButtonCheck && <Button
                                        className="button"
                                        variant={!isConditionsAccepted ? "primary" : "disabled"}
                                        onClick={() => {
                                          ConfirmEditQuantity(close);
                                          // close();
                                        }}
                                        disabled={isConditionsAccepted}
                                      >
                                        Confirm
                                      </Button>}
                                      {orderSummaryProcessingButtonCheck && <Button
                                        className="button"
                                        variant='secondary'
                                        disabled
                                      >
                                        Processing . . .
                                      </Button>}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </Container>
                      }
                    </div>
                  )}
                </Popup>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default OrderSummary