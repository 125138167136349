import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { Container, Form, Button, Col, Alert } from 'react-bootstrap';
import { userApi } from '../../../Utils/API';
import { PROFILE_CHANGE_PASSWORD } from '../../../Utils/GlobalConstants';
import './styles.scss';

const ForgetPasswordCustomer = () => {
    const navigate = useNavigate();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPwdError, setNewPwdError] = useState('');
    const [confirmPwdError, setConfirmPwdError] = useState('');
    const [regExError, setRegExError] = useState("");
    const regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{6,15}$');

    const checkPwd = async () => {
        if ((newPassword !== "" && confirmPassword !== "") && !(regexPassword.test(newPassword))) {
            setRegExError(true);
        } else {
            setRegExError(false);
        }

        oldPassword === '' ? setOldPasswordError("Please enter old password") : setOldPasswordError('');
        newPassword === '' ? setNewPwdError("Please enter valid password") : setNewPwdError('');
        confirmPassword === '' ? setConfirmPwdError("Please enter confirm password") : setConfirmPwdError('');

        if (regexPassword.test(newPassword) && (newPassword === confirmPassword) && oldPassword !== '') {
            const data = {
                "oldPassword": oldPassword,
                "password": newPassword,
                "confirmPassword": confirmPassword
            }
            const config = { headers: { 'Content-Type': 'application/json' } };

            await userApi.post(PROFILE_CHANGE_PASSWORD, data, config)
                .then(response => {
                    console.log("Success");
                })
                .catch(error => {
                    switch (error.response.status) {
                        case 400:
                            setTimeout(() => {
                                navigate("/management-console/");
                            }, 3000);
                            break;
                        default:

                    }
                });
        } else {

        }

    }

    return (
        <>
            <Container className="mt-3">
                <div className="anonymous-login-main-div">
                    <Col lg={6} md={8} className="m-auto">
                        <center><h1>Change Password</h1></center>
                        <Form>
                            <Form.Group className="mb-3" controlId="oldPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control type="text" placeholder="Old Password" value={oldPassword}
                                    onChange={(e) => { setOldPassword(e.target.value); }}
                                />
                                <Form.Text className="text-danger">
                                    {oldPasswordError}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" placeholder="Password" value={newPassword}
                                    onChange={(e) => { setNewPassword(e.target.value); }}
                                />
                                <Form.Text className="text-danger">
                                    {newPwdError}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmPassword" >
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword}
                                    onChange={(e) => { setConfirmPassword(e.target.value); }}
                                />
                                <Form.Text className="text-danger">
                                    {confirmPwdError}
                                </Form.Text>
                            </Form.Group>
                            <Button className="col" variant="primary" type="button" onClick={checkPwd}>
                                Change Password
                            </Button>

                            {
                                newPassword === confirmPassword ?
                                    ""
                                    :
                                    <Alert variant="danger" className="my-3" dismissible>
                                        Passwords not matched
                                    </Alert>
                            }
                            {
                                regExError ?

                                    <Alert variant="danger" className="my-3" dismissible>
                                        Password should contain Letters, Numbers and Special Characters
                                    </Alert>

                                    :
                                    ""
                            }
                        </Form>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default ForgetPasswordCustomer
