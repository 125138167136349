import React, { useState, useEffect } from "react";
import { Form, Button, Col } from "react-bootstrap";
import "./styles.scss";
import { api } from "../../Utils/API";
import { FORGOT_PASSWORD } from "../../Utils/GlobalConstants";
import Footer from "../../Layouts/Footer";

const SendActivationLink = (props) => {
  const [userName, setUserName] = useState("");                                 // Email Input Field Data
  const [isActivationLinkSent, setIsActivationLinkSent] = useState(false);    // Set Activation Link Set Check
  const [forgetPasswordData, setForgetPasswordData] = useState();            // CMS Portal Data
  const [emptyUserNameCheck, setEmptyUserNameCheck] = useState(false);      // Empty User Name Check UseState
  const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;      // Regex Expression for Email

  // Send Activation Link Function
  async function sendActivationLink() {
    if (!(emailRegEx.test(userName))) {
      setEmptyUserNameCheck(true);
      setTimeout(() => {
        setEmptyUserNameCheck(false);
      }, 4000);
    } else {
      let data = {
        "email": userName
      }
      await api
        .post(FORGOT_PASSWORD, data)
        .then((response) => {
          // console.log(response.data.message);
          props.setMyProfileToastMessage(response.data.message);
          props.SetMyProfileSuccess(true);
          setUserName("");
          setTimeout(() => {
            props.SetMyProfileSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          props.setMyProfileToastMessage(`${error.response.data.errors[0]} Please try again !`);
          props.setMyProfileError(true);
          setTimeout(() => {
            props.setMyProfileError(false);
          }, 2000);
        });
    }
  }

  // Use Effects Defination

  useEffect(() => {
    // console.log(props.forgetPasswordData);
    setForgetPasswordData(props.forgetPasswordData);
  }, []);


  return (
    <>
      <div className="anonymous-login-main-div active">
        <Col xl={8} lg={10} md={12} className="login-block">
          {forgetPasswordData && (
            <>
              <Form className="activation-form" onSubmit={e => { e.preventDefault(); 
              if(userName.length>0)
              sendActivationLink();}}>
                <div>
                  <h1>{forgetPasswordData.forgotPassword.welcomeText}</h1>
                  <p>{forgetPasswordData.forgotPassword.teaserText}</p>

                  <Form.Group controlId="userName">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      value={userName}
                      onChange={(e)=>(e.target.value.length <= 250 ? setUserName(e.target.value) : "")}
                    />
                    {emptyUserNameCheck && (
                      <Form.Text className="text-danger activation-error-message-username text-small">
                        Enter a valid Email Address
                      </Form.Text>
                    )}
                    {!isActivationLinkSent && (
                      <Form.Text className="text-muted activation-error-message-username text-small">
                        &nbsp;
                      </Form.Text>
                    )}
                    {/* {isSuccess && (
                      <Form.Text className="text-success activation-error-message-username text-small">
                        Activation link sent to your email.
                      </Form.Text>
                    )}
                    {isFailure && (
                      <Form.Text className="text-danger activation-error-message-username text-small">
                        {errorMessage}
                      </Form.Text> */}
                    {/* )} */}
                  </Form.Group>
                </div>
                {userName && <div className="hidden-btn">
                <Button
                  className="btn-none"
                  variant="none"
                  type="submit"
                  onClick={sendActivationLink}
                >
                  &nbsp;
                </Button>
                </div>}
              </Form>
              <div className="mobile-login-btns">
              <div className="w-100">
                {userName && <Button
                  className="col-12"
                  variant="primary"
                  type="submit"
                  onClick={sendActivationLink}
                >
                  Send Reset Link
                </Button>}
                {!userName && <Button
                  className="col-12"
                  variant="secondary"
                  disabled
                >
                  Send Reset Link
                </Button>}
                <p className="text-center login-back-link">
                  <label onClick={props.showLogin}>Back to Sign In?</label>
                </p>
                </div>
              </div>
              {/* <div className="mobile-login-btns">
                <div className="w-100">
                  <Button
                    className="col-12"
                    variant="primary"
                    type="submit"
                    onClick={sendActivationLink}
                  >
                    Send Reset Link
                  </Button>
                  <p className="text-center login-back-link">
                    <label onClick={props.showLogin}>Back to Sign In?</label>
                  </p>
                </div>
              </div> */}
            </>
          )}
        </Col>
        <Footer customClass="d-block d-md-none"/>
      </div>
    </>
  );
};

export default SendActivationLink;