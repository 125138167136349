import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Styles/App.scss";


// Anonymous User Screen Import

import MainContactUsPage from "./Pages/Anonymous/ContactUs";
import LoginCustomer from "./Pages/Anonymous/Login";
import ForgetPasswordCustomer from "./Pages/Anonymous/ResetPassword";
import CustomerNavbar from "./Layouts/Navbars/CustomerNavbar/CustomerNavbar";

// Authenticated User Screen Import

import ChangePassword from "./Pages/Customer/ChangePassword";
import MyProfileCustomer from "./Pages/Customer/MyProfile";
import ManagementConsoleCustomer from "./Pages/Customer/ManagementConsole";
import MicrosoftCSPServiceManagement from "./Pages/Customer/ManagementConsole/MicrosoftCSPServiceManagement";
import GoogleWorkspaceServiceManagement from "./Pages/Customer/ManagementConsole/GoogleWorkspaceServiceManagement";
import Footer from "./Layouts/Footer";
import RaiseATicket from "./Pages/Customer/RaiseATicket";
import CmsDetailsState from "./Context/CmsDetails/CmsDetailsSate";
import PageNotFound from "./Components/EmptyStates/PageNotFound";
import TicketDetails from "./Pages/Customer/TicketDetails";
import OrderHistory from "./Pages/Customer/OrderHistory";

function App() {
  // UseStates Defination
  const [customerIsAuthenticated, setCustomerIsAuthenticated] = useState(false); // Customer is authenticated Flag
  const [isCustomer, setIsCustomer] = useState(true);
  const [profileInitialCredentialChange, setProfileInitialCredentialChange] = useState(false); // Keeping Count of Profile Image initials change
  const [activeLocalKey, setActiveLocalKey] = useState("default"); // navigation bar default value
  const [pageNotFound, setPageNotFound] = useState(false); // Page not found Flag

  useEffect(() => {
    // sessionStorage.clear();
    let value = sessionStorage.getItem("customerIsAuthenticated");
    if (value) {
      setCustomerIsAuthenticated(value); // Defining default value that customer is signed in or not
    }
  }, []);

  const setAuthentication = (value) => {
    sessionStorage.setItem("customerIsAuthenticated", value);
    setCustomerIsAuthenticated(value); // Defining value that customer is signed in or not after change in useState
  };

  return (
    <>
      <CmsDetailsState>
        <div className="main-page">
          {customerIsAuthenticated && isCustomer && !pageNotFound && (
            <>
              <CustomerNavbar setCustomerIsAuthenticated={setAuthentication} activeLocalKey={activeLocalKey} setActiveLocalKey={setActiveLocalKey}
               profileInitialCredentialChange={profileInitialCredentialChange} setProfileInitialCredentialChange={setProfileInitialCredentialChange} />
              {/* Navbar visible to logged in Customer */}
            </>
          )}
          <Routes>
            {/* Defining Routes for logged in Customer so that these pages
             cannot be explicitly accessed by unauthorized users */}
            <Route
              path="/management-console/"
              element={<ManagementConsoleCustomer setCustomerIsAuthenticated={setAuthentication} activeLocalKey={activeLocalKey} setActiveLocalKey={setActiveLocalKey} customerIsAuthenticated={customerIsAuthenticated} />}
            />
            <Route path="/profile/" element={<MyProfileCustomer customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setCustomerIsAuthenticated} profileInitialCredentialChange={profileInitialCredentialChange} setProfileInitialCredentialChange={setProfileInitialCredentialChange} />} />
            <Route path="/change-password/" element={<ChangePassword />} />
            <Route
              path="/management-console/microsoft-csp/"
              element={<MicrosoftCSPServiceManagement />}
            />
            <Route
              path="/management-console/google-workspace/"
              element={<GoogleWorkspaceServiceManagement />}
            />
            <Route path="/create-support-ticket" element={<RaiseATicket customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setCustomerIsAuthenticated} />} />
            <Route path="/support-tickets" element={<TicketDetails setActiveLocalKey={setActiveLocalKey} customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setCustomerIsAuthenticated} />}  />
            <Route path="/order-history" element={<OrderHistory setActiveLocalKey={setActiveLocalKey} customerIsAuthenticated={customerIsAuthenticated} setCustomerIsAuthenticated={setCustomerIsAuthenticated}  />}/>

            {/* Defining Routes for unauthorized Customers */}
            <Route
              path="/"
              element={
                <LoginCustomer
                  customerIsAuthenticated={customerIsAuthenticated}
                  setCustomerIsAuthenticated={setAuthentication}
                />
              }
            />
            <Route
              path="/reset-password/"
              element={<ForgetPasswordCustomer />}
            />
            <Route path="*" element={<PageNotFound setPageNotFound={setPageNotFound} />} />
          </Routes>
          {!pageNotFound && <Footer />} {/* Footer for all screen */}
        </div>
      </CmsDetailsState>
    </>
  );
}

export default App;
