import React, { useState, useEffect, useContext } from 'react'
import './styles.scss';
import { Bell } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from "react-router-dom";
// import profilePhoto from "../../../Assets/Images/profilepicture.jpg";
import profileIcon from "../../../Assets/Images/profileicon.svg";
import logo from "../../../Assets/Images/synnex-logo.png";
import Offcanvas from 'react-bootstrap/Offcanvas';
import CmsDetailsContext from '../../../Context/CmsDetails/CmsDetailsContext';
import { LOGOUT } from '../../../Utils/GlobalConstants';
import { api } from '../../../Utils/API';

const CustomerNavbar = (props) => {
    const navigate = useNavigate();   // React Router v6
    const globalData = useContext(CmsDetailsContext);   // Global Variable
    const [active, setActive] = useState('default');   // Active profile dropdown
    const [tabActive, setTabActive] = useState('default');  // Active Blue color highlight for navigation
    const [profilePictureText, setProfilePictureText] = useState(sessionStorage.getItem("profile_initial_credential"));  // Profile Picture Text Initial
    // const profilePictureText = sessionStorage.getItem("profile_initial_credential");

    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
        },
    };

    // Updating Initial Profile on Profile Updation
    useEffect(() => {
        if (props.profileInitialCredentialChange) {
            setProfilePictureText(sessionStorage.getItem("profile_initial_credential"));
            props.setProfileInitialCredentialChange(false);
        }
    }, [props.profileInitialCredentialChange])

    // UseEffect to set active tab for navigation 
    useEffect(() => {
        setTabActive(props.activeLocalKey);
    }, [props.activeLocalKey])

    async function LogoutRevoke() {
        let data = "";
        await api
            .post(LOGOUT, data, config)
            .then((resp) => {
                props.setCustomerIsAuthenticated(false);
                if (props.setCustomerIsAuthenticated) { navigate("/") };
                sessionStorage.clear(); localStorage.clear();
                props.setActiveLocalKey('default');
                window.location.reload();
            })
            .catch((error) => {

                // console.log(error.response.status);
            });

    }


    return (
        <div>
            <Navbar collapseOnSelect expand="md" bg="light" variant="light" className='anonymousPageNavbar main-nav'>
                <Container fluid>
                    <Nav className="management-console-nav d-block d-md-none">
                        {['md'].map((expand) => (
                            <Navbar collapseOnSelect key={expand} expand="md" className="col">
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="px-0" />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav.Link
                                            onClick={() => navigate("/management-console/")} eventKey="default">
                                            Management Console
                                        </Nav.Link>
                                        <Nav.Link onClick={() => navigate("/support-tickets")} eventKey="ticketDetails">
                                            Support Tickets
                                        </Nav.Link>
                                        <Nav.Link onClick={() => navigate("/order-history", { state: { accountId: "", subId: "", planName: "", isGlobal: true } })} eventKey="orderHistory">
                                            Order History
                                        </Nav.Link>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>

                            </Navbar>
                        ))}
                    </Nav>
                    <Nav className="logo-block">
                        {globalData &&
                            <Navbar.Brand className="col-lg-4 col brand-logo-navigation-bar">
                                <img src={globalData.baseUrl + globalData.headerLogo.urls[0]} alt="Synnex" />
                            </Navbar.Brand>
                        }
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <span className="d-none d-md-flex seperator">&nbsp;</span>
                            <Nav.Link className="header-link d-none d-md-flex pl-3">Customer Portal</Nav.Link>
                        </Navbar.Collapse>
                    </Nav>



                    <Navbar className="topnavbar">
                        <Nav className='ms-auto' activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
                            <div className="notifications d-none">
                                <Bell color={"royalblue"} size={16} />
                            </div>
                            <NavDropdown eventKey={1}
                                title=
                                {profilePictureText ?
                                    <span className='profile-text'>{profilePictureText}</span> :
                                    <img className="profile-image"
                                        src={profileIcon}
                                        alt="user pic"
                                    />
                                }
                                className="pofile-nav"
                                id="main-nav-dropdown">
                                {/* <NavDropdown.Item onClick={() => {navigate("/management-console/");props.setActiveLocalKey("default")}} eventKey="default">
                                    Management Console</NavDropdown.Item> */}
                                <NavDropdown.Item className="profile" onClick={() => { navigate("/profile/"); props.setActiveLocalKey("") }} eventKey="link-1">
                                    <label>Profile</label>
                                </NavDropdown.Item>
                                {/* <NavDropdown.Item onClick={() => navigate("/change-password/")} eventKey="link-2">Change Password</NavDropdown.Item> */}
                                <NavDropdown.Item className="logout" onClick={() => { LogoutRevoke() }} eventKey="link-3">
                                    <label>Logout</label>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                    </Navbar>

                </Container>
            </Navbar>


            <Container fluid id="navbarToggleExternalContent" className="d-none d-md-block">
                <div className="my-3">
                    <Nav className="nav-tabs custom-navs" activeKey={tabActive} onSelect={(selectedKey) => { props.setActiveLocalKey(selectedKey) }}>
                        {/* <Nav.Link onClick={() => navigate("/management-console/")} eventKey="dashboard" disabled>
                            Dashboard
                        </Nav.Link> */}
                        <Nav.Link onClick={() => navigate("/management-console/")} eventKey="default">
                            Management Console
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/support-tickets", { state: { accountId: "", subId: "", planName: "", isGlobal: true } })} eventKey="ticketDetails">
                            Support Tickets
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/order-history", { state: { accountId: "", subId: "", planName: "", isGlobal: true } })} eventKey="orderHistory">
                            Order History
                        </Nav.Link>
                    </Nav>
                </div>
            </Container>
        </div>
    )
}

export default CustomerNavbar
