import React from 'react';
import './styles.scss';

const Eye = (props) => {
    const showEyePassword = () => {
        props.passwordType[0] === 'password' ? props.setPasswordType(['text', 'hide-eye']) : props.setPasswordType(['password', '']);
    }

    return (
        <span className={`eye btn-eye ${props.passwordType[1]}`} onClick={showEyePassword}>&nbsp;</span>
    );
}

export default Eye;