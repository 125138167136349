import React, { useState, useEffect } from 'react'
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import './styles.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../Utils/API";
import { SUPPORT_TICKETS } from "../../../Utils/GlobalConstants";
import { useLocation } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { CheckCircleFill, Clock, ClockFill, XCircleFill, XLg } from 'react-bootstrap-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from "react-bootstrap/Table";
import OrderHistoryFilterBox from './OrderHistoryFilterBox';
import EmptyDataTableFilterSearch from '../../../Assets/Images/EmptyDataTableFilterSearch.svg';
import {
    ORDER_HISTORY,
    ORDER_HISTORY_DOWNLOAD,
    ORDER_HISTORY_STATUS_TYPES
} from '../../../Utils/GlobalConstants';



const OrderHistory = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showPanel, setShowPanel] = useState(true);
    const [searchIconFlag, setSearchIconFlag] = useState(false); // Search Icon 
    const [searchValueTable, setSearchValueTable] = useState(null); // Storing searching value in the search input box

    // Filter Flags
    const [filterFlag, setFilterFlag] = useState(false);
    const [showFilterBox, setShowFilterBox] = useState(false); // Showing Global Filter Box
    const [isGlobalLacalChangeAPIFlag, setIsGlobalLacalChangeAPIFlag] = useState(false);


    // CLOUD SERVICE MANAGEMENT URL PARAMETERS 


    // Table Pagination PARAMS

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);

    // Rows per page based on the total count
    let perPageCount = []


    const defaultPerPageCount = 5; // Update this number based on per page. Default is 5

    for (let i = defaultPerPageCount; i <= totalRows + defaultPerPageCount; i += defaultPerPageCount) {
        perPageCount.push(i);
    }
    // console.log(perPageCount);

    const [page, setPage] = useState(1);
    const countPerPage = defaultPerPageCount;

    const [accountId, setAccountId] = useState(props.accountIdDefault);

    const [orderId, setOrderId] = useState();

    // CLOUD SERVICE MANAGEMENT TABLE DATA

    const [columns, setColumns] = useState([]);
    const [table, setTable] = useState([]);
    let ColumnName = ["Order ID", "Order Date & Time", "Cloud Service", "Status", "Order Notes", "Order By"];
    const [ticketDetails, setTicketDetails] = useState([]);
    const [ticketId, setTicketId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);



    // Order Details PopUp
    const [odColumns, setODColumns] = useState([]);
    const [odTable, setODTable] = useState([]);
    let ODColumnName = ["Plan", "Billing Term", "Updated Qty", "Net Qty", "Order Status"];

    const [modalOpen, setModalOpen] = useState(false);

    const [statusFilter, setStatusFilter] = useState([]);
    // const [cloudServiceFilter, setCloudServiceFilter] = useState([]);

    const [statusAll, setStatusAll] = useState(false);
    // const [cloudServiceAll, setCloudServiceAll] = useState(false);

    const [statusListGlobal, setStatusListGlobal] = useState([]);
    // const [cloudServiceListGlobal, setCloudServiceListGlobal] = useState([]);

    const [statusListGlobalFilteredArray, setStatusListGlobalFilteredArray] = useState(null);
    // const [cloudServiceListGlobalFilteredArray, setCloudServiceListGlobalFilteredArray] = useState();

    const [popupOrderId, setPopupOrderId] = useState();
    const [popupOrderService, setPopupOrderService] = useState();

    let keyValueArrayStatusFilter = [];
    const [statusFilterMapping, setStatusFilterMapping] = useState();

    const [isDesc, setIsDesc] = useState(true);

    const [serverErrorOrderDetails, setserverErrorOrderDetails] = useState(false);
    const [emptyOrderDetails, setemptyOrderDetails] = useState(false);
    const [emptyOrderDetailsFilters, setemptyOrderDetailsFilters] = useState(false);
    const [emptyOrderDetailsSearch, setemptyOrderDetailsSearch] = useState(false);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const date = new Date(); 

    // Clear Global Function
    async function ClearGolbalFunction() {
        // console.log("Global Functions")
    }


    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
        },
    };

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        sessionStorage.clear();
        localStorage.clear();
        props.setActiveLocalKey('default');
    }

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    useEffect(() => {
        // console.log("Logged in :",props.customerIsAuthenticated);
        if (props.customerIsAuthenticated === false) {
            navigate("/");
        }
    }, [])


    async function GetOrderDetails(orderId) {
        setLoading(true);
        await api.get(ORDER_HISTORY + orderId, config)
            .then(resp => {
                // handle success
                // console.log("Order Details", resp.data);
                let f = [];
                setPopupOrderId(resp.data.orderNumber);
                setPopupOrderService(resp.data.serviceName);
                let cloudservice = resp.data.serviceName;
                (resp.data.orderDetails).map((td) => {
                    // console.log("asdfasd", td.id)
                    f.push
                        (
                            {
                                // let ODColumnName = ["Quantity", "Plan Name", "Vendor Subscription Id", "Net Quantity", "Billing Term",	"Order Status"];
                                
                                "Plan": td.orderStatus == "Failed" ? <div className="plan-name-cell"><span className='order-summary-subtable-bold'>{`${td.planName}`}</span> <br />
                                    <span className='order-summary-subtable-subbold'>{td.vendorSubscriptionId}</span><br/><span className="text-small red">Note: Order failed, for any queries please raise a <span className='text-decoration-underline order-history-popup-raise-ticket-navigation' onClick={()=>{
                                        props.setActiveLocalKey("ticketDetails");
                                        navigate("/create-support-ticket", {
                                          state: {
                                            accountId: "1",
                                            subId: td.subscriptionId,
                                            planName: td.planName,
                                            isGlobal: false,
                                            cloudServices: cloudservice,
                                          },
                                        });
                                    }}>Support Ticket</span></span></div>
                                    : <><span className='order-summary-subtable-bold'>{td.planName}</span><br /><span className='order-summary-subtable-subbold'>{td.vendorSubscriptionId}</span><br/></>,
                                "Billing Term": <span className='order-summary-subtable-bold'>{td.billingTerm}</span>,
                                "Updated Qty": <span className='order-summary-subtable-bold'>{td.quantity}</span>,
                                "Net Qty": <span className='order-summary-subtable-bold'>{td.netQuantity}</span>,
                                "Order Status": <span className='order-summary-subtable-bold'>{td.orderStatus}</span>,
                                // "Vendor Subscription Id": td.vendorSubscriptionId,  
                            }
                        );
                })
                // console.log("Test F", f);
                setTimeout(() => {
                    setODTable(f);         // Setting Table Data
                }, 0);
                let d = [];
                ODColumnName.map((td) => {
                    if(td==="Updated Qty" || td==="Net Qty"){
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            right:true,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else{
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            left:true,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                })
                setODColumns(d);     // Setting Column Data
                setLoading(false);
                // // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    setLoading(false);
                    Logout();
                }
                else {
                    // console.log(error);
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        if (orderId) {
            GetOrderDetails(orderId);
        }
    }, [orderId]);

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    async function Download() {
        setLoading(true);

        await api.get(ORDER_HISTORY_DOWNLOAD, config)
            .then(resp => {
                // handle success
                // console.log("Response : ", resp.data);
                downloadFile({
                    data: (resp.data),
                    fileName: `OrderHistory_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth()+1).padStart(2,"0")}${date.getFullYear()}.csv`,
                    fileType: 'text/csv',
                });
                // // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    setLoading(false);
                    Logout();
                }
                else {
                    // console.log(error);
                    setLoading(false);
                }
            });
    };

    async function GetTableData() {
        setLoading(true);

        await api.get(`${ORDER_HISTORY}?pageNo=${page}&pageSize=${pageSize}`, config) // Pagination Provided here like Ticket Details
            .then(resp => {
                // handle success
                // console.log("Order Data", resp.data.content);
                let f = [];
                let statusIcon;
                setTotalRows(resp.data.totalCount);
                (resp.data.content).map((td) => {
                    // console.log("asdfasd", td);
                    if (td.orderStatus == "Success") {
                        statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                    } else if (td.orderStatus == "In Progress") {
                        statusIcon = <ClockFill color='#D8932C' size={16} />
                    } else if (td.orderStatus == "Partially Success") {
                        statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                    } else {
                        statusIcon = <XCircleFill color='#9C3B35' size={16} />
                    }
                    f.push
                        (
                            {
                                //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                "Order Date & Time": `${td.orderDate}`,
                                "Cloud Service": td.serviceName,
                                "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                "Order Notes": td.orderNotes,
                                "Order By": td.orderBy,
                            }
                        );
                })
                // console.log("Test F", f);
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 0);
                let d = [];
                ColumnName.map((td) => {
                    if (td === "Order Date & Time") {
                        d.push({
                            id: `${td}`,
                            name: <div>
                                {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                            </div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else if (td === "Status") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                            center: true,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                })
                setColumns(d);     // Setting Column Data
                // setCloudServiceFilter(["Microsoft CSP","Google Workspace"]); 
                //     ["Microsoft CSP","Google Workspace"].map((item) => 
                //     {
                //         setCloudServiceListGlobal((prev) => ({
                //             cloudServiceGlobal: {
                //             ...prev.cloudServiceGlobal, [item]: false
                //             }
                //         }));
                //     });
                //     // console.log("Reached");

                setLoading(false);
                // // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                    setLoading(false);
                }

            });
    };

    const FilterStatusData = async () => {
        await api.get(ORDER_HISTORY_STATUS_TYPES, config)
            .then(resp => {
                // console.log("Test Data", resp.data);
                let valueArray = [];
                resp.data.map((item) => {
                    valueArray.push(item.name);
                })
                // console.log("Key Value Array : ", keyValueArrayStatusFilter);
                setStatusFilterMapping(keyValueArrayStatusFilter);
                setStatusFilter(valueArray);
                // console.log("ValueArray", valueArray);
                valueArray.map((item) => {
                    setStatusListGlobal((prev) => ({
                        statusGlobal: {
                            ...prev.statusGlobal, [item]: false
                        }
                    }));
                });

            });
    };

    useEffect(() => {
        // GetTableData();
        FilterStatusData();
    }, []);

    useEffect(() => {
        if (table.length > 0 && showFilterBox === false && statusListGlobalFilteredArray === null) {
            FilterStatusData();
            setStatusAll(false);
        }
    }, [showFilterBox])

    async function SortStartDate() {
        setInitialLoading(true);
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        let statusSub;
        if (statusListGlobalFilteredArray === null || statusListGlobalFilteredArray === undefined) {
            statusSub = ""
        }
        else {
            statusSub = statusListGlobalFilteredArray
        }

        await api.get(`${ORDER_HISTORY}?pageNo=${1}&pageSize=${pageSize}&orderNumber=${searchValueTableSub}&status=${statusSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setemptyOrderDetails(true);
                    setInitialLoading(false);
                    // setEmptyDropdownMyCloudSrvicesflag(true);
                }
                else {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setemptyOrderDetails(false);
                    setserverErrorOrderDetails(false);
                    setPageNo(1);
                    // handle success
                    // console.log("Order Data", resp.data.content);
                    let f = [];
                    let statusIcon;

                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        // console.log("asdfasd", td.id)
                        if (td.orderStatus == "Success") {
                            statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                        } else if (td.orderStatus == "In Progress") {
                            statusIcon = <ClockFill color='#D8932C' size={16} />
                        } else if (td.orderStatus == "Partially Success") {
                            statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                        } else {
                            statusIcon = <XCircleFill color='#9C3B35' size={16} />
                        }
                        f.push
                            (

                                {
                                    //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                    "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                    "Order Date & Time": `${td.orderDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                    "Order Notes": td.orderNotes,
                                    "Order By": td.orderBy,
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 0);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Order Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                                center: true,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                    })
                    setColumns(d);     // Setting Column Data
                    setInitialLoading(false);
                    setLoading(false);
                    // // console.log(d);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    setserverErrorOrderDetails(true);
                    // console.log(error);
                    setLoading(false);
                    setInitialLoading(true);
                }

            });

    };

    useEffect(() => {
        SortStartDate();
    }, [isDesc]);

    // useEffect(() => {
    //     if(cloudServiceListGlobal.length>0){let local = cloudServiceListGlobal;
    //     let localCloudServiceBoolean = [];
    //     // console.log("Priority List Global : ",cloudServiceListGlobal);
    //     if (local.cloudServiceGlobal) {
    //       // // console.log("Billing Term List Global : ", local)
    //       cloudServiceFilter.map((item) => {
    //         // // console.log(item);
    //         if (cloudServiceListGlobal.cloudServiceGlobal[item] === true) {
    //             localCloudServiceBoolean.push(true);
    //         }
    //         else {
    //             localCloudServiceBoolean.push(false);
    //         }
    //       })
    //       // console.log("Local :",localCloudServiceBoolean);
    //       if (localCloudServiceBoolean.includes(false)) {
    //         setCloudServiceAll(false);
    //       }
    //       else {
    //         setCloudServiceAll(true);
    //       }
    //     }}
    //   }, [cloudServiceListGlobal])

    useEffect(() => {
        if (statusListGlobal) {
            let local = statusListGlobal;
            let localStatusBoolean = [];
            // console.log("Status List Global : ", statusListGlobal);
            if (local.statusGlobal) {
                // // console.log("Billing Term List Global : ", local)
                statusFilter.map((item) => {
                    // // console.log(item);
                    if (statusListGlobal.statusGlobal[item] === true) {
                        localStatusBoolean.push(true);
                    }
                    else {
                        localStatusBoolean.push(false);
                    }
                })
                // console.log("Local :", localStatusBoolean);
                if (localStatusBoolean.includes(false)) {
                    setStatusAll(false);
                }
                else {
                    setStatusAll(true);
                }
            }
        }
    }, [statusListGlobal])


    async function GetTableUpdatedData(page, newPerPage) {
        setLoading(true);
        
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        let statusSub;
        if (statusListGlobalFilteredArray === null || statusListGlobalFilteredArray === undefined) {
            statusSub = ""
        }
        else {
            statusSub = statusListGlobalFilteredArray
        }

        await api.get(`${ORDER_HISTORY}?pageNo=${page}&pageSize=${newPerPage}&orderNumber=${searchValueTableSub}&status=${statusSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setserverErrorOrderDetails(false);
                // handle success
                // console.log("Order Data", resp.data.content);
                let f = [];
                let statusIcon;
                setTotalRows(resp.data.totalCount);
                (resp.data.content).map((td) => {
                    // console.log("asdfasd", td.id);
                    if (td.orderStatus == "Success") {
                        statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                    } else if (td.orderStatus == "In Progress") {
                        statusIcon = <ClockFill color='#D8932C' size={16} />
                    } else if (td.orderStatus == "Partially Success") {
                        statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                    } else {
                        statusIcon = <XCircleFill color='#9C3B35' size={16} />
                    }
                    f.push
                        (
                            {
                                //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                "Order Date & Time": `${td.orderDate}`,
                                "Cloud Service": td.serviceName,
                                "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                "Order Notes": td.orderNotes,
                                "Order By": td.orderBy,
                            }
                        );
                })
                // console.log("Test F", f);
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 0);
                let d = [];
                ColumnName.map((td) => {
                    if (td === "Order Date & Time") {
                        d.push({
                            id: `${td}`,
                            name: <div>
                                {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                            </div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else if (td === "Status") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                            center: true,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                })
                setColumns(d);     // Setting Column Data
                setLoading(false);
                // // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    setserverErrorOrderDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };


    async function GetTableFilterData(status) {
        setLoading(true);
        let FilterData = [];
        status.map((item) => {
            // // console.log(statusFilter.indexOf(item))
            FilterData.push(statusFilter.indexOf(item) + 1);
        });
        // console.log("Filter Data : ", FilterData.join(","));
        let FilterDataFinal = FilterData.join(",");
        setStatusListGlobalFilteredArray(FilterDataFinal);
        // console.log("starteddddddddddddd");
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }

        await api.get(`${ORDER_HISTORY}?pageNo=${1}&pageSize=${pageSize}&orderNumber=${searchValueTableSub}&status=${FilterDataFinal}&isDesc=${isDesc}`, config)
            .then(resp => {
                if (resp.status === 204) {
                    setFilterFlag(true);
                    setemptyOrderDetailsFilters(true);
                    setFilterFlag(true);
                    setShowFilterBox(!showFilterBox);
                }
                else {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setemptyOrderDetailsFilters(false);
                    setserverErrorOrderDetails(false);
                    setPageNo(1);
                    // handle success
                    // console.log("Order Data", resp.data.content);
                    let f = [];
                    let statusIcon;
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        // console.log("asdfasd", td.id);
                        if (td.orderStatus == "Success") {
                            statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                        } else if (td.orderStatus == "In Progress") {
                            statusIcon = <ClockFill color='#D8932C' size={16} />
                        } else if (td.orderStatus == "Partially Success") {
                            statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                        } else {
                            statusIcon = <XCircleFill color='#9C3B35' size={16} />
                        }
                        f.push
                            (
                                {
                                    //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                    "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                    "Order Date & Time": `${td.orderDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                    "Order Notes": td.orderNotes,
                                    "Order By": td.orderBy,
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 0);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Order Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                                center: true,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                    })
                    setColumns(d);     // Setting Column Data
                    setLoading(false);
                    setFilterFlag(true);
                    setShowFilterBox(!showFilterBox);
                    // console.log("Done Filtering");
                    // // console.log(d);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    setserverErrorOrderDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };

    async function GetClearFilterData(status) {
        setLoading(true);
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        let statusSub;
        if (status === null || status === undefined) {
            statusSub = ""
        }
        else {
            statusSub = statusListGlobalFilteredArray
        }

        FilterStatusData();
        setStatusAll(false);

        await api.get(`${ORDER_HISTORY}?pageNo=${1}&pageSize=${pageSize}&orderNumber=${searchValueTableSub}&status=${statusSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setResetPaginationToggle(!resetPaginationToggle);
                setemptyOrderDetailsFilters(false);
                setserverErrorOrderDetails(false);
                setFilterFlag(false);
                setPageNo(1);
                // handle success
                // console.log("Order Data", resp.data.content);
                let f = [];
                let statusIcon;
                setTotalRows(resp.data.totalCount);
                (resp.data.content).map((td) => {
                    // console.log("asdfasd", td.id);
                    if (td.orderStatus == "Success") {
                        statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                    } else if (td.orderStatus == "In Progress") {
                        statusIcon = <ClockFill color='#D8932C' size={16} />
                    } else if (td.orderStatus == "Partially Success") {
                        statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                    } else {
                        statusIcon = <XCircleFill color='#9C3B35' size={16} />
                    }
                    f.push
                        (
                            {
                                //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                "Order Date & Time": `${td.orderDate}`,
                                "Cloud Service": td.serviceName,
                                "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                "Order By": td.orderBy,
                            }
                        );
                })
                // console.log("Test F", f);
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 0);
                let d = [];
                ColumnName.map((td) => {
                    if (td === "Order Date & Time") {
                        d.push({
                            id: `${td}`,
                            name: <div>
                                {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                            </div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else if (td === "Status") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                            center: true,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                })
                setColumns(d);     // Setting Column Data
                setLoading(false);
                // // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    setserverErrorOrderDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };

    async function GetTableSearchData() {
        setLoading(true);
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        let statusSub;
        if (statusListGlobalFilteredArray === null || statusListGlobalFilteredArray === undefined) {
            statusSub = ""
        }
        else {
            statusSub = statusListGlobalFilteredArray
        }

        await api.get(`${ORDER_HISTORY}?pageNo=${1}&pageSize=${pageSize}&orderNumber=${searchValueTableSub}&status=${statusSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setemptyOrderDetailsSearch(true);
                }
                else {
                    // handle success
                    setResetPaginationToggle(!resetPaginationToggle);
                    setemptyOrderDetailsSearch(false);
                    setserverErrorOrderDetails(false);
                    setPageNo(1);
                    // console.log("Order Data", resp.data.content);
                    let f = [];
                    let statusIcon;
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        // console.log("asdfasd", td.id);
                        if (td.orderStatus == "Success") {
                            statusIcon = <CheckCircleFill color='#1D874C' size={16} />
                        } else if (td.orderStatus == "In Progress") {
                            statusIcon = <ClockFill color='#D8932C' size={16} />
                        } else if (td.orderStatus == "Partially Success") {
                            statusIcon = <CheckCircleFill color='#EECA3F' size={16} />
                        } else {
                            statusIcon = <XCircleFill color='#9C3B35' size={16} />
                        }
                        f.push
                            (
                                {
                                    //["Order ID", "Order Date", "Order Notes", "Order Number", "Order Status"];
                                    "Order ID": <span className="ticketid-color-underline" onClick={() => { setOrderId(td.orderId); setModalOpen(true); }} >{td.orderNumber}</span>,
                                    "Order Date & Time": `${td.orderDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Status": <span className="plan-status">{statusIcon}<br />{td.orderStatus}</span>,
                                    "Order Notes": td.orderNotes,
                                    "Order By": td.orderBy,
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 0);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Order Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else if (td === "Status") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap text-center">{row[`${td}`]}</div>,
                                center: true,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                    })
                    setColumns(d);     // Setting Column Data
                    setLoading(false);
                    // // console.log(d);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // // console.log("Error 401");
                    Logout();
                }
                else {
                    setserverErrorOrderDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };


    // Order Details

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        // fetchUsers(page);
        // console.log("Page Changed");
        // console.log("Current Page : ", pageNo);
        GetTableUpdatedData(pageNo, pageSize);
        setPageNo(pageNo);
    };


    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        // console.log("Number of rows changed");
        // console.log("New Per Page", newPerPage);
        // console.log("Current Page", page);
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableUpdatedData(page, newPerPage);
    };

    useEffect(() => {
        if (searchValueTable != null) {
            const getData = setTimeout(() => {
                GetTableSearchData();
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable]);

    const conditionalRowStyles = [
        {
            when: row => row.Status.props.children.includes("Failed"),
            style: {
                backgroundColor: '#FFF2F2',
            },
        },
    ];

    // CSS for Customer Cloud Service Management Data Table
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    const customStylesSub = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '10px',
                paddingBottom: '10px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center'
            },
        },
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                // textAlign: 'center'
            },
        },
    };

    return (
        <>
            <div className="customer-management-console-main-div mb-5">
                <h1 className="d-block d-md-none mobile-h1">Order History</h1>
                <div className="mgmt-console-table-row">
                    <div className="my-3 d-flex">
                        <Popup
                            open={modalOpen}
                            // onOpen={() => setTicketId(td.id)}
                            onClose={() => { setModalOpen(false); }}
                            className="custom-modal"
                            modal
                            nested
                        >
                            {(close) => (
                                <div className="modal">
                                    <div className="header">
                                        <h2>Order Details</h2>
                                        <XLg
                                            size={24}
                                            className="cpointer close-icon-popup"
                                            onClick={close}
                                        />
                                    </div>
                                    <Container fluid>

                                        <Row className="my-4">
                                            <Col md={6}>
                                                <label>Order ID: </label>
                                                <label className="pl-5 text-strong">{popupOrderId}</label>
                                            </Col>
                                            <Col md={6}>
                                                <label>Order Service:</label>
                                                <label className="pl-5 text-strong">{popupOrderService}</label>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid className="px-0 mb-5 order-history-sub-table">
                                        <DataTable
                                            columns={odColumns}
                                            data={odTable}
                                            theme="solarized"
                                            customStyles={customStylesSub}
                                            // striped={true}
                                            persistTableHead
                                            width="100%"
                                            fixedHeaderScrollHeight="50vh"
                                            progressPending={loading}
                                            fixedHeader
                                            // pagination
                                            // paginationRowsPerPageOptions={[2, 4, 8, 16]}
                                        />
                                    </Container>
                                </div>
                            )}
                        </Popup>
                        <Col className="subscription-table py-0">
                            <div className="subscription-table-inner">
                                <Row className="my-0 table-heading align-items-center">
                                    <Col className={`pl-1 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                        <h5 className="py-3 mb-0 mobile-padding-search-open subscription-heading-table-header">Orders</h5>
                                    </Col>
                                    {!initialLoading && !emptyOrderDetails && <Col className="px-1">
                                        <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                            <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                {!searchIconFlag &&
                                                    <span className="mx-3">&nbsp;</span>
                                                }
                                                {!emptyOrderDetailsFilters && !searchIconFlag &&
                                                    <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                }
                                                {!emptyOrderDetailsFilters && searchIconFlag &&
                                                    <InputGroup className="search-input-box">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer" onClick={() => { GetTableSearchData(); }}>
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Enter Order ID"
                                                            aria-label="Enter Order ID"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box"
                                                            value={searchValueTable}
                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTable("");
                                                                setTimeout(() => {
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }, 500);
                                                            }}>
                                                            <span className="closebtn" >
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                }

                                                {!filterFlag && !emptyOrderDetailsSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                    setShowFilterBox(!showFilterBox);
                                                    if (showFilterBox) { setIsGlobalLacalChangeAPIFlag(true); ClearGolbalFunction(); }
                                                }}></span>}
                                                {filterFlag && !emptyOrderDetailsSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                <span className="download cpointer ml-3" onClick={() => { Download() }}>&nbsp;</span>
                                            </Col>
                                        </Row>
                                    </Col>}
                                </Row>
                                {showFilterBox &&
                                    <OrderHistoryFilterBox
                                        // cloudServiceFilter={cloudServiceFilter} cloudServiceListGlobal={cloudServiceListGlobal} 
                                        // setCloudServiceListGlobal={setCloudServiceListGlobal} setCloudServiceAll={setCloudServiceAll} 
                                        // cloudServiceAll={cloudServiceAll}  setCloudServiceListGlobalFilteredArray={setCloudServiceListGlobalFilteredArray}
                                        statusFilter={statusFilter} statusListGlobal={statusListGlobal}
                                        setStatusListGlobal={setStatusListGlobal} setStatusAll={setStatusAll}
                                        statusAll={statusAll} setStatusListGlobalFilteredArray={setStatusListGlobalFilteredArray}
                                        GetTableFilterData={GetTableFilterData} GetClearFilterData={GetClearFilterData} 
                                        setShowFilterBox={setShowFilterBox} />
                                }
                                <Container fluid className="mb-5 p-0">
                                    <Row>
                                        <Col>
                                            <div className="table-responsive support-ticket-history-table order-history-table">
                                                {serverErrorOrderDetails &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                    </div>
                                                }
                                                {emptyOrderDetailsSearch &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Orders Found.</h2>
                                                    </div>
                                                }
                                                {emptyOrderDetails &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Orders Found.</h2>
                                                    </div>
                                                }
                                                {emptyOrderDetailsFilters &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Orders Found.</h2>
                                                    </div>
                                                }
                                                {!emptyOrderDetailsFilters && !emptyOrderDetails && !emptyOrderDetailsSearch && !serverErrorOrderDetails && <DataTable
                                                    columns={columns}
                                                    data={table}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    // striped={true}
                                                    persistTableHead
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    noDataComponent={<div className='loading-support-tickets'><p><b>Loading your Order's History . . .</b></p></div>}
                                                    // progressPending={loading}
                                                    fixedHeader
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={pageSize}
                                                    paginationRowsPerPageOptions={[5,10,20,50,100]}
                                                    onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    paginationResetDefaultPage={resetPaginationToggle}
                                                />}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderHistory
