import React , {useState,useEffect} from 'react';
import {api} from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION ,MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY , MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION , MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION , MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL } from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import Popup from 'reactjs-popup';
import './styles.scss';
import 'reactjs-popup/dist/index.css';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

const ManageSubscriptionLegacy = (props) => {

  const [tableSub, setTableSub] = useState([]);
  let ColumnName = ["Plan Name (SKU) / Subscription ID", "Billing Term" , "RRP (Inc. GST)" , "Price (Ex.GST)" , "	Auto Renew" ,  "Qty", "Status"];
  const [loading, setLoading] = useState(false);

  const [updatedQuantity, setUpdatedQuantity] = useState("");
  const [editQuantityError, setEditQuantityError] = useState(false);
  const [isSwitchOnState, setIsSwitchOnState] = useState(false);
  const [isSwitchOnStateAutoRenew, setIsSwitchOnStateAutoRenew] = useState(false);
  const [minQuantity, setMinQuantity] = useState();
  const [maxQuantity, setMaxQuantity] = useState();
  const [statusConfirmationBox, setStatusConfirmationBox] = useState("");
  const [statusConfirmationBoxAutoRenew, setStatusConfirmationBoxAutoRenew] = useState("");



  const CheckSubscriptionStatus = (a) => {
    console.log(a);
    if(a==="ACTIVE"){
        // isSwitchOn=(true);
        setIsSwitchOnState(true);
        }
    else if(a==="INACTIVE"){
        // isSwitchOn=(false);
        setIsSwitchOnState(false);
    }
  };

  const CheckSubscriptionStatusAutoRenew = (a) => {
    console.log(a);
    if(a===true){
        // isSwitchOn=(true);
        setIsSwitchOnStateAutoRenew(true);
        }
    else if(a===false){
        // isSwitchOn=(false);
        setIsSwitchOnStateAutoRenew(false);
    }
  };

  const onSwitchAction = (e) => {
      if(isSwitchOnState==false){
          setStatusConfirmationBox("deactivate-subscription")
      }
      else{
          setStatusConfirmationBox("activate-subscription")
      }
    };

    const onSwitchActionAutoRenew = (e) => {
      if(isSwitchOnStateAutoRenew==false){
          setStatusConfirmationBoxAutoRenew("disable-auto-renew")
      }
      else{
          setStatusConfirmationBoxAutoRenew("enable-auto-renew")
      }
    };

  useEffect(() => {
    console.log(isSwitchOnState)
    console.log(isSwitchOnStateAutoRenew)
  }, [isSwitchOnState])


  const GetManageSubscriptionTableData = async () => {
    setLoading(true);
    console.log("started");
    await api.get(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION + props.actionSelectionData.SubscriptionId).then(resp => {
        // handle success
        console.log("Result",resp.data);
        setUpdatedQuantity(resp.data.licenseCount);
        setMinQuantity(resp.data.minQuantity);
        setMaxQuantity(resp.data.maxQuantity)
        CheckSubscriptionStatus(resp.data.subscriptionStatus);
        CheckSubscriptionStatusAutoRenew(resp.data.licenseAutoRenewal);
        

        setTableSub({
            "PlanName": resp.data.planName,
            "SubscriptionID": resp.data.vendorSubscriptionId,
            "BillingTerm": resp.data.billingTerm,
            "RRP": resp.data.rrpInclusiveGST,
            "Price": resp.data.customerBuyPrice,
            "Qty": resp.data.licenseCount,
            "Status": resp.data.subscriptionStatus,
            "Plancode":resp.data.planCode,
        })
        console.log("Table Sub",tableSub,resp.data);
        setLoading(false);
        // if(resp.data.planType != ("iam" || "add-on")){
        //     GetManageSubscriptionAddOns();
        // }
      })
      .catch(error => {
        // handle error
        console.log("Error");
        setLoading(false);
      });
    };

useEffect(() => {
    GetManageSubscriptionTableData();
}, [props.actionSelectionData.SubscriptionId]);


  return (
    <>
      <Container>
        <br/><br/>
        <center><h1 className='mt-10'>Legacy Microsoft CSP Manage Subscription {props.actionSelectionData.SubscriptionId}</h1></center>
          { !loading &&
              <>
                <br/><br/>
                <Table responsive striped hover>
                    <thead className='manage-subscription-table-head'>
                        <tr>
                        {ColumnName.map((td)=>{
                            return(
                                <th className='manage-subscription-table-head-sub'>{td}</th>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='manage-subscription-table-data'>{tableSub.PlanName + "("+ tableSub.Plancode +")" }<br/> {tableSub.SubscriptionID}</td>
                            <td className='manage-subscription-table-data'>{tableSub.BillingTerm}</td>
                            <td className='manage-subscription-table-data'>$ {tableSub.RRP}</td>
                            <td className='manage-subscription-table-data'>$ {tableSub.Price}</td>
                            <td className='manage-subscription-table-data'>
                                <Form.Check 
                                    type="switch"
                                    checked={isSwitchOnStateAutoRenew}
                                    onChange={() => onSwitchActionAutoRenew()}
                                    id="custom-switch"
                                />
                            </td>
                            <td className='manage-subscription-table-data'>{tableSub.Qty}&nbsp;&nbsp;&nbsp;
                                    <Popup
                                        trigger={<Button>Edit</Button>}
                                        modal
                                        nested
                                        contentStyle={{ width: '25%' }}
                                    >
                                        {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>
                                            &times;
                                            </button>
                                            <div className="header"> Edit Quantity </div>
                                            <div className="content">
                                                <center><Form.Control className='quantity-input-field' type="text"  defaultValue={updatedQuantity} 
                                                onChange={(e)=>{
                                                    setUpdatedQuantity(e.target.value);
                                                }
                                                }/></center>
                                                <br />
                                                <center><b>{editQuantityError && <p className='edit-quantity-paragraph text-danger'>Number should be between {minQuantity} & {maxQuantity}</p>}</b></center>
                                                {!editQuantityError && <p className='empty-edit-quantity-paragraph'></p>}
                                            </div>
                                            <div className="actions">
                                            <Button
                                                className="button me-3"
                                                onClick={() => {
                                                if(updatedQuantity>=minQuantity && updatedQuantity<=maxQuantity){
                                                    setEditQuantityError(false);
                                                    const config = { headers: { 'Content-Type': 'application/json' } };
                                                    let updatedQuantityData = {
                                                        "subscriptionResourceList": [
                                                            {
                                                              "subscriptionId": props.actionSelectionData.SubscriptionId,
                                                              "quantity": updatedQuantity,
                                                            }
                                                          ]
                                                    }
                                                    api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY,updatedQuantityData,config).then(resp => {
                                                        console.log(resp);
                                                        // GetManageSubscriptionTableData();
                                                        close();
                                                        window.location.reload();
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                      });
                                                }   
                                                else{
                                                    setEditQuantityError(true);
                                                    console.log("Quantity is out of range bond");
                                                    // alert("Error");
                                                }            
                                                }}
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                className="button"
                                                onClick={() => {
                                                console.log('modal closed ');
                                                close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            </div>
                                        </div>
                                        )}
                                    </Popup>
                            </td>
                            <td className='manage-subscription-table-data'>
                                {/* {tableSub.Status} */}
                                {/* {isSwitchOnState?"ACTIVE":"INACTIVE"} */}
                                <Form.Check 
                                    type="switch"
                                    checked={isSwitchOnState}
                                    onChange={() => onSwitchAction()}
                                    id="custom-switch"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
              </>
          }
          { loading &&
              <center><br/><br/><b><h4>Loading . . .</h4></b></center>
          }
      </Container>
              <Popup
                  open={statusConfirmationBox==="activate-subscription"}
                  modal
                  contentStyle={{ width: '25%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                      &times;
                      </button>
                      <div className="header"> Deactivate Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Deactivate Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Dectivate Subscription");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let subscriptionId = props.actionSelectionData.SubscriptionId
                              let dataDeactivateSubscription = {
                                    subscriptionId
                                }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION,dataDeactivateSubscription,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnState(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBox("activate-subscription");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBox("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>

              <Popup
                  open={statusConfirmationBox==="deactivate-subscription"}
                  modal
                  contentStyle={{ width: '25%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                      &times;
                      </button>
                      <div className="header"> Activate Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Activate Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Activate Subscription");
                              let subscriptionId = props.actionSelectionData.SubscriptionId
                              let dataActivateSubscription = {
                                    subscriptionId
                                }
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION,dataActivateSubscription,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnState(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBox("deactivate-subscription");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBox("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>
              <Popup
                  open={statusConfirmationBoxAutoRenew==="enable-auto-renew"}
                  modal
                  contentStyle={{ width: '40%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBoxAutoRenew("");}}>
                      &times;
                      </button>
                      <div className="header"> Disable Auto Renewal Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Disable Auto Renew Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Disable Auto Renew");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let autoRenewalData = {
                                "subscriptionResourceList": [
                                  {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId,
                                    "licenseAutoRenewal": false
                                  }
                                ]
                              }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL,autoRenewalData,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnStateAutoRenew(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBoxAutoRenew("");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBoxAutoRenew("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>

              <Popup
                  open={statusConfirmationBoxAutoRenew==="disable-auto-renew"}
                  modal
                  contentStyle={{ width: '40%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBoxAutoRenew("");}}>
                      &times;
                      </button>
                      <div className="header"> Auto Renewal Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Auto Renew Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Auto Renewal Subscription");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let autoRenewalData = {
                                "subscriptionResourceList": [
                                  {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId,
                                    "licenseAutoRenewal": true
                                  }
                                ]
                              }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL,autoRenewalData,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnStateAutoRenew(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBoxAutoRenew("");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBox("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>
    </>
  )
}

export default ManageSubscriptionLegacy
