import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api } from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION , 
  MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_OFFERS_DETAILS ,
  MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_START_PAID_SERVICE} from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

const GWConvertToPaid = (props) => {

  const [columnsCurrentTable, setColumnsCurrentTable] = useState([]);
  const [tableCurrentTable, setTableCurrentTable] = useState([]);
  let ColumnNameCurrentTable = ["Offer Name", "Edition Name", "RRP (INC)", "Reseller Price (Ex)", "Billing Period"];
  let ColumnNameReviewOrder = ["Product", "Plan Name", "Licence", "Reseller Total Price (Ex)", "P.O. Number"];
  const [loadingCurrentTable, setLoadingCurrentTable] = useState(false);

  const [columnsNewPlan, setColumnsNewPlan] = useState([]);
  const [tableNewPlan, setTableNewPlan] = useState([]);
  const [loadingNewPlan, setLoadingNewPlan] = useState(false);


  const [secondNextPage, setSecondNextPage] = useState(false);
  const [thirdNextPage, setThirdNextPage] = useState(false);

  const [offerSKU, setOfferSKU] = useState("");
  const [skuName, setSkuName] = useState("");
  const [minQuantity, setMinQuantity] = useState();
  const [maxQuantity, setMaxQuantity] = useState();


  const [reviewOrderPaymentPlan, setReviewOrderPaymentPlan] = useState("");
  const [reviewOrderPlanName, setReviewOrderPlanName] = useState("");
  const [reviewOrderOfferName, setReviewOrderOfferName] = useState("");
  const [reviewOrderLicence, setReviewOrderLicence] = useState(1);
  const [reviewOrderResellerTotalPrice, setReviewOrderResellerTotalPrice] = useState("");
  const [reviewOrderPONumber, setReviewOrderPONumber] = useState("");
  const [editQuantityError, setEditQuantityError] = useState(false);

  const [loadingConfirmOrder, setLoadingConfirmOrder] = useState(false);

  async function GetConvertToPaidSubscriptionTableData() {
    setLoadingCurrentTable(true);
    setLoadingNewPlan(true);
    console.log("started");
    await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_OFFERS_DETAILS, {
      params: {
        subscriptionId: props.actionSelectionData.SubscriptionId,
        planName: props.actionSelectionData.Plan
      }
    }).then(resp => {
        // handle success
      //   resp.data={
      //     "isSuccess": true,
      //     "statusMessage": "Google workspace list of available plan offers fetched successfully.",
      //     "purchasableOfferDetails": [
      //         {
      //             "duration": 0,
      //             "currentPlan": true,
      //             "maxValue": 10,
      //             "minValue": 1,
      //             "offerDescription": "Allows trying services without making a payment.",
      //             "offerName": "Trial Plan",
      //             "offerSku": "accounts/C00wf6uoy/offers/U2k2RRWQWgvMRj",
      //             "paymentPlan": "TRIAL",
      //             "paymentType": "PAYMENT_TYPE_UNSPECIFIED",
      //             "periodType": "Trial",
      //             "planDescription": "Manage users, devices and applications to secure your data in the cloud.",
      //             "planId": null,
      //             "planName": "Cloud Identity Premium",
      //             "price": null,
      //             "rrpExclusiveTax": "0",
      //             "rrpInclusiveTax": "0.00",
      //             "skuName": "products/U31OA73ARzQq8p/skus/UkRDl6Xv3rLEQK",
      //             "customerBuyPrice": 20.12
      //         },
      //         {
      //             "duration": 1,
      //             "currentPlan": false,
      //             "maxValue": 10,
      //             "minValue": 0,
      //             "offerDescription": "Commit to a 12 month Service plan. You will be billed at the end of the month based on your committed number of licenses. Additional licenses may be added to the commitment at any time.",
      //             "offerName": "Annual Plan (Monthly Payment)",
      //             "offerSku": "accounts/C00wf6uoy/offers/U9rG88gPgQj19x",
      //             "paymentPlan": "COMMITMENT",
      //             "paymentType": "POSTPAY",
      //             "periodType": "Monthly",
      //             "planDescription": "Manage users, devices and applications to secure your data in the cloud.",
      //             "planId": null,
      //             "planName": "Cloud Identity Premium",
      //             "price": null,
      //             "rrpExclusiveTax": "7.5",
      //             "rrpInclusiveTax": "8.25",
      //             "skuName": "products/U31OA73ARzQq8p/skus/UkRDl6Xv3rLEQK",
      //             "customerBuyPrice": 20.12
      //         },
      //         {
      //             "duration": 1,
      //             "currentPlan": false,
      //             "maxValue": 10,
      //             "minValue": 0,
      //             "offerDescription": "Add and delete users at any time. You will be billed at the end of the month for the actual number of accounts in service during the month.",
      //             "offerName": "Flexible Plan",
      //             "offerSku": "accounts/C00wf6uoy/offers/UPNg11QAQzv8A4",
      //             "paymentPlan": "FLEXIBLE",
      //             "paymentType": "POSTPAY",
      //             "periodType": "Monthly",
      //             "planDescription": "Manage users, devices and applications to secure your data in the cloud.",
      //             "planId": null,
      //             "planName": "Cloud Identity Premium",
      //             "price": null,
      //             "rrpExclusiveTax": "7.5",
      //             "rrpInclusiveTax": "8.25",
      //             "skuName": "products/U31OA73ARzQq8p/skus/UkRDl6Xv3rLEQK",
      //             "customerBuyPrice": 20.12
      //         },
      //         {
      //             "duration": 1,
      //             "currentPlan": false,
      //             "maxValue": 10,
      //             "minValue": 0,
      //             "offerDescription": "Commit to a 12 month Service plan. You will be billed at the end of the first month for the full annual price.",
      //             "offerName": "Annual Plan (Yearly Payment)",
      //             "offerSku": "accounts/C00wf6uoy/offers/UkRB557N7QA7L6",
      //             "paymentPlan": "COMMITMENT",
      //             "paymentType": "PREPAY",
      //             "periodType": "Yearly",
      //             "planDescription": "Manage users, devices and applications to secure your data in the cloud.",
      //             "planId": null,
      //             "planName": "Cloud Identity Premium",
      //             "price": null,
      //             "rrpExclusiveTax": "90",
      //             "rrpInclusiveTax": "99.00",
      //             "skuName": "products/U31OA73ARzQq8p/skus/UkRDl6Xv3rLEQK",
      //             "customerBuyPrice": 20.12
      //         }
      //     ]
      // }
        console.log("Resp : ", resp.data.purchasableOfferDetails);
        let f = [];
        let g=[];
        if (resp.data.purchasableOfferDetails.length > 1) {
            (resp.data.purchasableOfferDetails).map((td) => {
              if(td.currentPlan === true){
                f.push
                    (
                        {
                            "Offer Name": td.offerName,
                            "Edition Name": td.planName,
                            "RRP (INC)":td.rrpInclusiveTax,
                            "Reseller Price (Ex)": td.rrpExclusiveTax,
                            "Billing Period": td.periodType,
                            "SKU Name": td.skuName,
                            "Offer SKU" : td.offerSku,
                            "Payment Plan" : td.paymentPlan,
                            "MinQuantity": td.minValue,
                            "MaxQuantity" : td.maxValue,

                        }
                    );
              }
              else{
                g.push
                    (
                      {
                        "Offer Name": td.offerName,
                        "Edition Name": td.planName,
                        "RRP (INC)":td.rrpInclusiveTax,
                        "Reseller Price (Ex)": td.rrpExclusiveTax,
                        "Billing Period": td.periodType,
                        "SKU Name": td.skuName,
                        "Offer SKU" : td.offerSku,
                        "Payment Plan" : td.paymentPlan,
                        "MinQuantity": td.minValue,
                        "MaxQuantity" : td.maxValue,
                    }
                    );
              }   
            })
        }
        else {
          if(resp.data.purchasableOfferDetails[0].currentPlan === true){
            f.push
                (
                    {
                      "Offer Name": resp.data.purchasableOfferDetails[0].offerName,
                      "Edition Name": resp.data.purchasableOfferDetails[0].planName,
                      "RRP (INC)": resp.data.purchasableOfferDetails[0].rrpInclusiveTax,
                      "Reseller Price (Ex)": resp.data.purchasableOfferDetails[0].rrpExclusiveTax,
                      "Billing Period": resp.data.purchasableOfferDetails[0].periodType,
                      "SKU Name": resp.data.purchasableOfferDetails[0].skuName,
                      "Offer SKU" : resp.data.purchasableOfferDetails[0].offerSku,
                      "Payment Plan" : resp.data.purchasableOfferDetails[0].paymentPlan,
                      "MinQuantity": resp.data.purchasableOfferDetails[0].minValue,
                      "MaxQuantity" : resp.data.purchasableOfferDetails[0].maxValue,
                    }
                );
          }
          else{
            g.push
                (
                    {
                      "Offer Name": resp.data.purchasableOfferDetails[0].offerName,
                      "Edition Name": resp.data.purchasableOfferDetails[0].planName,
                      "RRP (INC)": resp.data.purchasableOfferDetails[0].rrpInclusiveTax,
                      "Reseller Price (Ex)": resp.data.purchasableOfferDetails[0].rrpExclusiveTax,
                      "Billing Period": resp.data.purchasableOfferDetails[0].periodType,
                      "SKU Name": resp.data.purchasableOfferDetails[0].skuName,
                      "Offer SKU" : resp.data.purchasableOfferDetails[0].offerSku,
                      "Payment Plan" : resp.data.purchasableOfferDetails[0].paymentPlan,
                      "MinQuantity": resp.data.purchasableOfferDetails[0].minValue,
                      "MaxQuantity" : resp.data.purchasableOfferDetails[0].maxValue,
                    }
                );
          }
        }

        setTableCurrentTable(f);         // Setting Table Data
        setTableNewPlan(g);
        let d = [];
        ColumnNameCurrentTable.map((td) => {
            d.push({
                id: `${td}`,
                name: `${td}`,
                // selector: row => row[columnNameRow],
                selector: row => row[`${td}`],
                // sortable: true,
                center: true,
                wrap: true,
            })
        })
        setColumnsCurrentTable(d);     // Setting Column Data
        setColumnsNewPlan(d);     // Setting Column Data
        console.log("------", d);
        setLoadingCurrentTable(false);
        setLoadingNewPlan(false);
    })
        .catch(error => {
            // handle error
            console.log("Error");
            setLoadingCurrentTable(false);
            setLoadingNewPlan(false);
        });
  };

  useEffect(() => {
    GetConvertToPaidSubscriptionTableData();
  }, [props.actionSelectionData.SubscriptionId]);
  
  createTheme('solarized', {
    text: {
        primary: '#000000',
    },
    background: {
        default: '#FFFFFF',
    },
    divider: {
        default: '#d7dadf',
    },
    striped: {
        default: '#f5f5f5',
    },
  });
  
  const customStyles = {
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            textAlign: 'center',
            fontSize: '0.9rem',
            fontWeight: '600',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '10px', // override the cell padding for head cells
            paddingRight: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: '#29286a',
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: 'white',
            textTransform: 'capitalize'
        },
    },
  };

  const handleChange = (rows) => {
    if(rows.selectedCount>0){
      console.log("Rows",rows.selectedRows[0]);
      setReviewOrderOfferName(rows.selectedRows[0]["Offer Name"]);
      setReviewOrderPlanName(rows.selectedRows[0]["Edition Name"]);
      setReviewOrderResellerTotalPrice(rows.selectedRows[0]["Reseller Price (Ex)"]);
      setReviewOrderPaymentPlan(rows.selectedRows[0]["Payment Plan"]);
      setOfferSKU(rows.selectedRows[0]["Offer SKU"]);
      setSkuName(rows.selectedRows[0]["SKU Name"]);
      setMaxQuantity(rows.selectedRows[0]["MaxQuantity"]);
      setMinQuantity(rows.selectedRows[0]["MinQuantity"])
      setSecondNextPage(true);

//   {
//     "Offer Name": td.offerName,
//     "Edition Name": td.planName,
//     "RRP (INC)":td.rrpInclusiveTax,
//     "Reseller Price (Ex)": td.rrpExclusiveTax,
//     "Billing Period": td.periodType,
//     "SKU Name": td.skuName,
//     "Offer SKU" : td.offerSku,
//     "Payment Plan" : td.paymentPlan,

// }
    }
    }


    const quantityReviewOrderConvertToPaid = (e) => {
      let quantity = e.target.value;
      setReviewOrderLicence(e.target.value);
      if(minQuantity<=quantity && maxQuantity>=quantity){
        console.log("Correct");
      }
      }

      async function confirmOrder() {
        if(minQuantity<=reviewOrderLicence && maxQuantity>=reviewOrderLicence){
          setLoadingConfirmOrder(true);
          const config = { headers: { 'Content-Type': 'application/json' } };
          let confirmOrderData = {
              "offerName": reviewOrderOfferName,
              "offerSku": offerSKU,
              "paymentPlan": reviewOrderPaymentPlan,
              "planName": reviewOrderPlanName,
              "poNumber": reviewOrderPONumber,
              "quantity": reviewOrderLicence,
              "skuName": skuName,
              "subscriptionId": props.actionSelectionData.SubscriptionId
          }
          api.post(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_START_PAID_SERVICE,confirmOrderData,config).then(resp => {
              console.log(resp);
              setLoadingConfirmOrder(false);
              alert("Converted To Paid Successfully");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
          })
          .catch(error => {
              console.log(error);
              setLoadingConfirmOrder(false);
            });
      }   
      else{
        setEditQuantityError(true);
          setTimeout(() => {
            setEditQuantityError(false); 
          }, 3000);
          console.log("Quantity is out of range bond");
          // alert("Error");
      }   
        
        }



  return (
    <div>
      <Container>
        <center><h1>Convert To Paid</h1></center>
        <br/><br/><br/>
        <h1>Current Plan</h1>
        <br/>
        <DataTable
              columns={columnsCurrentTable}
              data={tableCurrentTable}
              theme="solarized"
              customStyles={customStyles}
              striped={true}
              // fixedHeaderScrollHeight="60vh"
              progressPending={loadingCurrentTable}
              fixedHeader
        />
        <br/><br/><br/>
        <h1>New Plan</h1>
        <br/>
        <DataTable
              columns={columnsNewPlan}
              data={tableNewPlan}
              theme="solarized"
              customStyles={customStyles}
              striped={true}
              // fixedHeaderScrollHeight="60vh"
              progressPending={loadingCurrentTable}
              fixedHeader
              selectableRows
              onSelectedRowsChange={handleChange}
              selectableRowsHighlight
              selectableRowsSingle
        />
        <br/><br/>
        {!secondNextPage && <Button className='float-right' disabled>Next</Button>}
        {secondNextPage && <Button className='float-right' onClick={()=>{console.log("New Values :");setThirdNextPage(true);}}>Next</Button>}
        <br/><br/>
        {  thirdNextPage &&
              <>
                <br/><br/> 
                <center><h3 className='mt-10'>Please verify the below details, enter the P.O Number and confirm order</h3></center><br/>
                <Table responsive striped hover>
                    <thead className='manage-subscription-table-head'>
                        <tr>
                        {ColumnNameReviewOrder.map((td)=>{
                            return(
                                <th className='manage-subscription-table-head-sub'>{td}</th>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td className='manage-subscription-table-data'>{reviewOrderPlanName}</td>
                            <td className='manage-subscription-table-data'>{reviewOrderOfferName}</td>
                            <td className='manage-subscription-table-data'>
                              <Form.Control
                                type="number"
                                defaultValue={reviewOrderLicence}
                                className='quantity-downgrade-subscription-input-number'
                                onChange={quantityReviewOrderConvertToPaid}
                              />
                            </td>
                            <td className='manage-subscription-table-data'>{reviewOrderResellerTotalPrice*reviewOrderLicence}</td>
                            <td className='manage-subscription-table-data'>
                              <Form.Control
                                placeholder="P.O Number"
                                defaultValue={reviewOrderPONumber}
                                onChange={(e)=>{setReviewOrderPONumber(e.target.value)}}
                              />
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <br />
                <center><b>{editQuantityError && <p className='edit-quantity-paragraph text-danger'>Number should be between {minQuantity} & {maxQuantity}</p>}</b></center>
                {!editQuantityError && !loadingConfirmOrder && <p className='empty-edit-quantity-paragraph'></p>}
                <center><b>{loadingConfirmOrder && <p className='empty-edit-quantity-paragraph text-warning'>Converting Trial Plan to Paid . . .</p> }</b></center>
                {thirdNextPage && !reviewOrderPONumber && <Button className='float-right' disabled>Confirm Order</Button>}
                {thirdNextPage && reviewOrderPONumber && <Button className='float-right' onClick={()=>{confirmOrder();}}>Confirm Order</Button>}
                <br />
              </>
            }
      </Container>

      
    </div>
  )
}

export default GWConvertToPaid
