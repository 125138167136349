import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const SubscriptionDetails = (
    {
        isSubscriptionDetailsPopupOpen,
        setIsSubscriptionDetailsPopupOpen,
        subscriptionDetailsIsTrial,
        subscriptionDetailIsLoadingFlag,
        subscriptionDetailsPlanName,
        subscriptionDetailsVendorSubscriptionId,
        subscriptionDetailsLicenceAutoRenewal,
        subscriptionDetailsStartDate,
        subscriptionDetailsEndDate,
        subscriptionDetailsTrialEndDate
    }
) => {
    const navigate = useNavigate();
    return (
        <Popup
            className="custom-modal"
            open={isSubscriptionDetailsPopupOpen}
            onClose={() => setIsSubscriptionDetailsPopupOpen(false)}
            modal
            nested
        >
            {(close) => (
                <div className="modal">
                    {!subscriptionDetailIsLoadingFlag &&
                        <>
                            <div className="header">
                                <h2>Subscription Details</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container>
                                <Row>
                                    <Col className="mb-3">
                                        <label className="text-medium">
                                            Plan
                                        </label>
                                        <p>
                                            <strong>
                                                {subscriptionDetailsPlanName}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-3">
                                        <label className="text-medium">
                                            Vendor Subscription Id
                                        </label>
                                        <p className="mb-0 text-medium">
                                            <strong>
                                                {subscriptionDetailsVendorSubscriptionId.toUpperCase()}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col className="mb-3">
                                        <label className="text-medium">
                                            Licence Auto Renewal
                                        </label>
                                        <p className="text-medium">
                                            <strong>
                                                {subscriptionDetailsLicenceAutoRenewal}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mb-0">
                                    <Col md={6}>
                                        <label className="text-medium">
                                            Subscription Start Date
                                        </label>
                                        <p className="text-medium">
                                            <strong>
                                                {subscriptionDetailsStartDate}
                                            </strong>
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <label className="text-medium">
                                            Subscription End Date
                                        </label>
                                        <p className="text-medium">
                                            <strong>
                                                {subscriptionDetailsEndDate}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col lg={4}>
                    <label>RRP</label>
                    <p>
                    <strong>
                        {subscriptionDetailsRRP}
                    </strong>
                    </p>
                </Col> */}
                                    {subscriptionDetailsIsTrial === "Yes" && (
                                        <Col lg={4} md={6} className="mb-3">
                                            <label className="text-medium">
                                                Is Trial
                                            </label>
                                            <p className="text-medium mb-0">
                                                <strong>
                                                    {subscriptionDetailsIsTrial}
                                                </strong>
                                            </p>
                                        </Col>
                                    )}
                                    {subscriptionDetailsIsTrial === "Yes" && (
                                        <Col lg={4} md={6} className="mb-3">
                                            <label className="text-medium mb-0">
                                                Trial End Date
                                            </label>
                                            <p className="text-medium">
                                                <strong>
                                                    {subscriptionDetailsTrialEndDate}
                                                </strong>
                                            </p>
                                        </Col>
                                    )}

                                </Row>
                            </Container>
                        </>
                    }
                    {
                        subscriptionDetailIsLoadingFlag &&
                        <>
                            <div className="header">
                                <h2 className="mb-0">Subscription Details</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong"
                                    onClick={close}
                                    color="#6A6A6A"
                                />
                            </div>
                            <Container>
                                <div className="empty-subscription-detail">
                                    <center><h2> Loading . . .</h2></center>
                                </div>
                            </Container>
                        </>
                    }
                </div>
            )}
        </Popup>
    )
}

export default SubscriptionDetails
