import React from 'react';
import "./styles.scss";
import Table from "react-bootstrap/Table";

const NoData = (
    {
        ColumnNameMicrosoftCSP,
        showPriceFlag,
        EmptyDataTableFilterSearch
    }
) => {
  return (
    <div className="table-body-height-responsive">
        <Table table-bordered className="no-data-found-table">
        <thead className="manage-subscription-csp-table-head sticky-top">
            <tr>
            {ColumnNameMicrosoftCSP.map((td) => {
                if (showPriceFlag === false) {
                if (
                    td === "Plan" ||
                    td === "Billing Term" ||
                    td === "Subscription Type" ||
                    td === "Start Date" ||
                    td === " "
                ) {
                    if (td === "Subscription Type") {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}
                        </th>
                    );
                    }
                    if (td === "Billing Term") {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}
                        </th>
                    );
                    }
                    else {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}
                        </th>
                    );
                    }

                }
                if (
                    td === "Status" ||
                    td === "Support Tickets" ||
                    td === "Info"
                ) {
                    if (td === "Status") {
                    return (
                        <th className="manage-subscription-table-head-sub text-center">
                        {td}
                        </th>
                    );
                    }
                    else {
                    return (
                        <th className="manage-subscription-table-head-sub text-center">
                        {td}
                        </th>
                    );
                    }
                }
                if (td === "Quantity") {
                    return (
                    <th className="manage-subscription-table-head-sub text-right">
                        {td}
                    </th>
                    );
                }
                }
                if (showPriceFlag === true) {
                if (
                    td === "Plan" ||
                    td === "Billing Term" ||
                    td === "Subscription Type" ||
                    td === "Start Date" ||
                    td === " "
                ) {
                    if (td === "Subscription Type") {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}

                        </th>
                    );
                    }
                    if (td === "Billing Term") {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}

                        </th>
                    );
                    }
                    else {
                    return (
                        <th className="manage-subscription-table-head-sub text-left">
                        {td}
                        </th>
                    );
                    }
                }
                if (
                    td === "Status" ||
                    td === "Support Tickets" ||
                    td === "Info"
                ) {
                    if (td === "Status") {
                    return (
                        <th className="manage-subscription-table-head-sub text-center">
                        {td}

                        </th>
                    );
                    }
                    else {
                    return (
                        <th className="manage-subscription-table-head-sub text-center">
                        {td}
                        </th>
                    );
                    }
                }
                if (td === "Quantity" || td === "Unit Price") {
                    return (
                    <th className="manage-subscription-table-head-sub text-right">
                        {td}
                    </th>
                    );
                }
                }
            })}
            </tr>
        </thead>
        </Table>
        <div className="no-data-found">
        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
        <h2 className="mt-4 mb-3">No Data Found</h2>
        <center><p></p></center>
        </div>
    </div>
  )
}

export default NoData
