import React, { useState } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg, ArrowLeftCircle } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const TicketFilterBox = (props) => {

    const [columns, setColumns] = useState(["Subject", "Service Name", "Priority"])

    return (
        <>
            <div className="filter-box">
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={() => {props.setShowFilterBox(false)}} />
                    </Col>
                    <Row className="mb-3 mobile-filter-row">
                                {/* // Loop from here */}
                                    <Col Col className="filter-column" >
                                        <h3 className="mb-3">Subject</h3>
                                        <div>
                                            {
                                                <Form>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={"Subject-column-all"}
                                                            label="All Items"
                                                            checked={props.subjectAll}
                                                            onClick={() => {
                                                                props.setSubjectListGlobal({});
                                                                // console.log(document.getElementById("Monthly").checked);
                                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                                props.subjectFilter.map((item) => {
                                                                  props.setSubjectListGlobal((prev) => ({
                                                                    subjectGlobal: {
                                                                      ...prev.subjectGlobal, [item]: !(props.subjectAll)
                                                                    }
                                                                  }));
                                                                })
                                                                props.setSubjectAll(!(props.subjectAll));
                                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                              }}
                                                        />
                                                    </div>
                                                   {props.subjectFilter.map((td=>{
                                                        return(
                                                            <div className="mb-3">
                                                                <Form.Check // prettier-ignore
                                                                    type='checkbox'
                                                                    id={td}
                                                                    label={td}
                                                                    checked={(props.subjectListGlobal).subjectGlobal[td]}
                                                                    onClick={() => {
                                                                        props.setSubjectListGlobal({});
                                                                        props.subjectFilter.map((item) => {
                                                                          if (item == td) {
                                                                            // console.log(item, type, (document.getElementById(item)));
                                                                            props.setSubjectListGlobal((prev) => ({
                                                                              subjectGlobal: {
                                                                                ...prev.subjectGlobal, [item]: (document.getElementById(item).checked)
                                                                              }
                                                                            }));
                                                                            props.setSubjectAll(false);
                                                                          }
                                                                          else {
                                                                            props.setSubjectListGlobal((prev) => ({
                                                                              subjectGlobal: {
                                                                                ...prev.subjectGlobal, [item]: (document.getElementById(item).checked)
                                                                              }
                                                                            }));
                                                                          }
                                                                        })
                                                                      }
                                                                      }
                                                                />
                                                            </div>
                                                        )
                                                   })) }
                                                </Form>
                                            }
                                        </div>
                                    </Col>
                                    <Col Col className="filter-column" >
                                        <h3 className="mb-3">Priority</h3>
                                        <div>
                                            {
                                                <Form>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={"Priority-column-all"}
                                                            label="All Items"
                                                            checked={props.priorityAll}
                                                            onClick={() => {
                                                                props.setPriorityListGlobal({});
                                                                // console.log(document.getElementById("Monthly").checked);
                                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                                props.priorityFilter.map((item) => {
                                                                  props.setPriorityListGlobal((prev) => ({
                                                                    priorityGlobal: {
                                                                      ...prev.priorityGlobal, [item]: !(props.priorityAll)
                                                                    }
                                                                  }));
                                                                })
                                                                props.setPriorityAll(!(props.priorityAll));
                                                                // billingTermListGlobal.Monthly = !(document.getElementById("Monthly").checked);
                                                              }}
                                                        />
                                                    </div>
                                                   {(props.priorityFilter).map((td=>{
                                                        return(
                                                            <div className="mb-3">
                                                                <Form.Check // prettier-ignore
                                                                    type='checkbox'
                                                                    id={td}
                                                                    label={td}
                                                                    checked={(props.priorityListGlobal).priorityGlobal[td]}
                                                                    // checked={(props.subjectListGlobal).subjectGlobal[td]}
                                                                    onClick={() => {
                                                                        props.setPriorityListGlobal({});
                                                                        props.priorityFilter.map((item) => {
                                                                          if (item == td) {
                                                                            // console.log(item, type, (document.getElementById(item)));
                                                                            props.setPriorityListGlobal((prev) => ({
                                                                                priorityGlobal: {
                                                                                ...prev.priorityGlobal, [item]: (document.getElementById(item).checked)
                                                                              }
                                                                            }));
                                                                            props.setPriorityAll(false);
                                                                          }
                                                                          else {
                                                                            props.setPriorityListGlobal((prev) => ({
                                                                                priorityGlobal: {
                                                                                ...prev.priorityGlobal, [item]: (document.getElementById(item).checked)
                                                                              }
                                                                            }));
                                                                          }
                                                                        })
                                                                      }
                                                                      }
                                                                />
                                                            </div>
                                                        )
                                                   })) }
                                                </Form>
                                            }
                                        </div>
                                    </Col>

                    </Row>
                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">

                            {/* Hide this button */}
                            {((!((JSON.stringify((props.subjectListGlobal).subjectGlobal).includes("true")) || (JSON.stringify((props.priorityListGlobal).priorityGlobal).includes("true")))) || (props.priorityAll && props.subjectAll)) &&
                            <Button
                                className="btn btn-lg mx-3"
                                variant="light"
                                disabled
                            >
                                Clear
                            </Button>}
                            {((!(props.priorityAll) || !(props.subjectAll)) && ((JSON.stringify((props.subjectListGlobal).subjectGlobal).includes("true")) || (JSON.stringify((props.priorityListGlobal).priorityGlobal).includes("true")))) &&
                            <Button
                                className="btn btn-lg mx-3 btn-border-light"
                                variant="light"
                                onClick={() => {
                                    props.setPriorityListGlobalFilteredArray(null);
                                    props.setSubjectListGlobalFilteredArray(null);
                                    props.GetClearFilterData(null,null);
                                }}
                            >
                                Clear
                            </Button>}
                            {/* {emptyFilterSubscriptionTable && <Button
              className="btn btn-lg mx-3"
              variant="primary"
              onClick={() => { GetTableDatas() }}
            >
              Clear
            </Button>} */}
                             {((!(props.priorityAll) || !(props.subjectAll)) && ((JSON.stringify((props.subjectListGlobal).subjectGlobal).includes("true")) || (JSON.stringify((props.priorityListGlobal).priorityGlobal).includes("true")))) &&
                             <Button
                                className="btn btn-lg"
                                variant="primary"
                                onClick={() => {
                        
                                    let filterFinalFiltersAPIPriority = [];
                                    let filterFinalFiltersAPIPriorityFalseStorage = [];
                                    let filterFinalFiltersAPIPriorityBooleanStorage = [];
                  
                                    let filterFinalFiltersAPISubject = [];
                                    let filterFinalFiltersAPISubjectFalseStorage = [];
                                    let filterFinalFiltersAPISubjectBooleanStorage = [];
                  
                                    (props.subjectFilter).map((item) => {
                                      if (props.subjectListGlobal.subjectGlobal[item] === true) {
                                        filterFinalFiltersAPISubject.push(`${item}`);
                                        filterFinalFiltersAPISubjectBooleanStorage.push(true);
                                      }
                                      if (props.subjectListGlobal.subjectGlobal[item] === false) {
                                        filterFinalFiltersAPISubjectFalseStorage.push(`${item}`);
                                        filterFinalFiltersAPISubjectBooleanStorage.push(false);
                                      }
                                    });
                                    (props.priorityFilter).map((item) => {
                                      if (props.priorityListGlobal.priorityGlobal[item] === true) {
                                        filterFinalFiltersAPIPriority.push(`${item}`);
                                        filterFinalFiltersAPIPriorityBooleanStorage.push(true);
                                      }
                                      if (props.priorityListGlobal.priorityGlobal[item] === false) {
                                        filterFinalFiltersAPIPriorityFalseStorage.push(`${item}`);
                                        filterFinalFiltersAPIPriorityBooleanStorage.push(false);
                                      }
                                    });
                                    console.log("Priority : ", (filterFinalFiltersAPIPriority).join(","));
                                    console.log("Subject : ", filterFinalFiltersAPISubject);
                                    props.setPriorityListGlobalFilteredArray(filterFinalFiltersAPIPriority.join(","));
                                    props.setSubjectListGlobalFilteredArray(filterFinalFiltersAPISubject.join(","));
                                    props.GetGlobalFilterData((filterFinalFiltersAPIPriority).join(","),(filterFinalFiltersAPISubject).join(","));
                                    // GetFilterData(filterFinalFiltersAPIPriority, filterFinalFiltersAPISubject, filterFinalFiltersAPICloudService);
                                  }}
                            >
                                Apply
                            </Button>}
                            
                            {/* Hide this button */}
                            {((!((JSON.stringify((props.subjectListGlobal).subjectGlobal).includes("true")) || (JSON.stringify((props.priorityListGlobal).priorityGlobal).includes("true")))) || (props.priorityAll && props.subjectAll)) &&
                            <Button
                                className="btn btn-lg"
                                variant="light"
                                disabled
                            >
                                Apply
                            </Button>}
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
}

export default TicketFilterBox;