import React from 'react';
import "./styles.scss";
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const EmptySubscription = (
    {
        EmptySubscriptionTableIllustration,
        setActiveLocalKey,
    }
) => {

  const navigate = useNavigate();

  return (
    <div className="table-body-height-responsive empty-cloud-services-table-body-height-responsive">
        <img src={EmptySubscriptionTableIllustration} className="empty-subscription-table-svg" />
        <h2 className="mt-4 mb-3">No Subscriptions Found.</h2>
        <p className="mb-3">Please create a support ticket for adding subscriptions.</p>
        <Button
        className="btn btn-lg btn-primary"
        onClick={() => {
            setActiveLocalKey("ticketDetails");
            navigate("/create-support-ticket", {
            state: { accountId: "", subId: "", planName: "", isGlobal: true }
            }
            );
        }}
        >
        Support Tickets
        </Button>
    </div>
  )
}

export default EmptySubscription
