import React, { useState, useEffect } from 'react'
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import './styles.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../../Utils/API";
import { MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, MANAGEMENT_CONSOLE_ACCOUNTS_URL } from "../../../../Utils/GlobalConstants";
import Eye from '../../../../Components/Eye';
import microsoftEsp from '../../../../../src/Assets/Images/microsoft.png';
import { useLocation } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import ManageSubscriptionLegacy from './ManageSubscriptionLegacy';
import ManageSubscriptionNCEBase from './ManageSubscriptionNCEBase';
import Form from 'react-bootstrap/Form';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const CloudServiceManagement = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showPanel, setShowPanel] = useState(true);

    // CLOUD SERVICE MANAGEMENT URL PARAMETERS 

    const [pageNo, setPageNo] = useState(1);
    // const [pageSize, setPageSize] = useState(2);
    const [pageSize, setPageSize] = useState(8);
    const [accountId, setAccountId] = useState(props.accountIdDefault);

    // CLOUD SERVICE MANAGEMENT TABLE DATA

    const [columns, setColumns] = useState([]);
    const [table, setTable] = useState([]);
    let ColumnName = ["Plan", "Qty", "Billing Term", "Commitment Term", "Start Date", "Status", "Subscription Type", "Actions", "Migration"];
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);

    // CLOUD SERVICE MANAGEMENT CUSTOMER DETAILS

    const [customerData, setCustomerData] = useState("Loading...");
    const [startDate, setStartDate] = useState("Loading...");
    const [portal, setPortal] = useState("Loading...");
    const [domainName, setDomainName] = useState("Loading...");
    const [initialPassword, setInitialPassword] = useState("Loading...");
    const [totalLicences, setTotalLicences] = useState("Loading...");
    const [totalAddOns, setTotalAddOns] = useState("Loading...");
    const [passwordType, setPasswordType] = useState(['password', '']);


    const [actionSelectionFlag, setActionSelectionFlag] = useState("");
    const [actionSelectionData, setActionSelectionData] = useState();

    const [isManageSubscriptionType, setIsManageSubscriptionType] = useState("");


    // const [emptyTableCheck, setEmptyTableCheck] = useState(false);


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className='threeDotAnchorTag'
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className="threedots" />
        </a>
    ));


    async function GetCustomerData() {
        console.log("started");
        await api.get(MANAGEMENT_CONSOLE_ACCOUNTS_URL + accountId).then(resp => {
            // handle success

            // console.log(resp.data);

            //Setting Customer Cloud Service Mangement Details

            setCustomerData(resp.data.customerCompanyName);
            setStartDate(resp.data.provisionDate);
            setPortal(resp.data.serviceUrl);
            setDomainName(resp.data.domainName);
            setInitialPassword(resp.data.password);
            setTotalLicences(resp.data.subscriptionLicenseCount);
            setTotalAddOns(resp.data.addonsLicenseCount);
        })

            .catch(error => {
                // handle error
            });
    };


    async function GetTableData() {
        setLoading(true);
        // setEmptyTableCheck(true);
        console.log("started");
        await api.get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, { params: { accountId, pageNo, pageSize } }).then(resp => {
            console.log(resp.data);
            setTotalRows(resp.data.page.totalElements);
            console.log(resp.data.page.totalElements);
            let f = [];
            (resp.data.content).map((td) => {
                let actionData = {
                    "SubscriptionId": td.subscriptionId,
                    "SubscriptionType": td.planType,
                };
                console.log("Action Data : ", actionData);
                f.push  
                    (
                        {
                            "Plan": td.planName,
                            "Qty": td.licenseCount,
                            "Billing Term": td.billingTerm,
                            "Commitment Term": td.termDuration,
                            "Start Date": td.subscriptionDate,
                            "Status": td.subscriptionStatus,
                            "Subscription Type": td.planType,
                            "Actions": <Dropdown drop={"right-start"}>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    {
                                        (td.serviceActions).map((data) => {
                                            return (<Dropdown.Item onClick={(e) => ActionSelection(data.actionKey, actionData)} id={data.actionKey}>{data.actionName}</Dropdown.Item>)
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>,
                            "Migration": td.migrationStatus
                        }
                    );
            })
            // console.log(f);
            setTable(f);         // Setting Table Data
            let d = [];
            ColumnName.map((td) => {
                d.push({
                    id: `${td}`,
                    name: <div>{td}</div>,
                    selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                    sortable: true,
                    center: true,
                })
            })
            setColumns(d);     // Setting Column Data
            setLoading(false);
            // console.log(d);

        })
            .catch(error => {
                // handle error
                console.log(error);
                setLoading(false);
            });

    };


    async function GetTableUpdatedData(pageNo, pageSize) {
        setLoading(true);
        // setEmptyTableCheck(true);
        console.log("started");
        await api.get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT, { params: { accountId, pageNo, pageSize } }).then(resp => {
            // handle success
            setTotalRows(resp.data.page.totalElements);
            console.log(resp.data.content);
            let f = [];
            (resp.data.content).map((td) => {
                let actionData = {
                    "SubscriptionId": td.subscriptionId,
                    "SubscriptionType": td.planType,
                };
                console.log("Action Data : ", actionData);
                f.push
                    (
                        {
                            "Plan": td.planName,
                            "Qty": td.licenseCount,
                            "Billing Term": td.billingTerm,
                            "Commitment Term": td.termDuration,
                            "Start Date": td.subscriptionDate,
                            "Status": td.subscriptionStatus,
                            "Subscription Type": td.planType,
                            "Actions": <Dropdown drop={"right-start"}>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                    {
                                        (td.serviceActions).map((data) => {
                                            return (<Dropdown.Item onClick={(e) => ActionSelection(data.actionKey, actionData)} id={data.actionKey}>{data.actionName}</Dropdown.Item>)
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>,
                            "Migration": td.migrationStatus
                        }
                    );
            })
            // console.log(f);
            setTable(f);         // Setting Table Data
            let d = [];
            ColumnName.map((td) => {
                if (td === "Actions") {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        sortable: true,
                        center: true,
                        allowOverflow: true,
                    })
                }
                else {
                    d.push({
                        id: `${td}`,
                        name: <div>{td}</div>,
                        selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                        sortable: true,
                        center: true,
                    })
                }
            })
            setColumns(d);     // Setting Column Data
            // console.log(d);
            setLoading(false);
        })
            .catch(error => {
                // handle error
                console.log(error);
                setLoading(false);
            });

    };

    const ActionSelection = (actionSelectionLabel, actionSelectionData) => {
        console.log(actionSelectionLabel, actionSelectionData);
        setActionSelectionFlag(actionSelectionLabel);
        setActionSelectionData(actionSelectionData);
        if (actionSelectionData.SubscriptionType === "NCE Base") {
            setIsManageSubscriptionType("NCE");
        }
        else {
            setIsManageSubscriptionType("Legacy");
        }
    };


    useEffect(() => {
        GetCustomerData();
        GetTableData();
    }, []);

    // Function Triggers when Page Number is Changed by Customer

    const handlePageChange = page => {
        // fetchUsers(page);
        console.log("Page Changed");
        console.log("Page : ", page);
        GetTableUpdatedData(page, pageSize);
        setPageNo(page);
    };

    // Function Triggers when Rows Per Page is Changed by Customer

    const handlePerRowsChange = async (newPerPage, page) => {
        console.log("Number of rows changed");
        console.log(newPerPage, page);
        GetTableUpdatedData(page, newPerPage);
        setPageNo(page);
        setPageSize(newPerPage);
    };

    // CSS for Customer Cloud Service Management Data Table

    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '600',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#29286a',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: 'white',
                textTransform: 'capitalize'
            },
        },
    };

    const showPanelDetails = () => {
        console.log(showPanel);
        setShowPanel(!showPanel)
    }

    // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    

    const ExpanableComponent = ({data,table,columns,customStyles}) => 
        <DataTable
            columns={columns}
            data={table}
            theme="solarized"
            customStyles={customStyles}
            noTableHead={!!data}
            striped={true}
            // fixedHeaderScrollHeight="60vh"
            progressPending={loading}
            fixedHeader
            // noDataComponent=<div>No previous versions of this document were found.</div>
            persistTableHead={true}
            // pagination
            // paginationServer
            // paginationTotalRows={totalRows}
            // paginationPerPage={pageSize}
            // paginationRowsPerPageOptions={[2, 4, 8, 16]}
            // onChangePage={handlePageChange}
            // onChangeRowsPerPage={handlePerRowsChange}
        />;
        // <pre>{JSON.stringify(data.Plan, null, 2)}</pre>;


    return (
        <>
            <div className='customer-cloud-service-management-main-div container-fluid'>

                <br /><br />
                <Container>
                    <center>
                        <h1>Microsoft CSP Service Management</h1>
                    </center>
                    <center>
                        <p onClick={() => navigate("/management-console/")}>Back</p>
                    </center>


                    <Row>
                        <Col lg={4} md={6}>
                            <Row className="border rounded py-2 m-0">
                                <Col lg={4} md={6} className="pt-2">
                                    <label>My Poducts</label>
                                </Col>
                                <Col lg={8} md={6}>
                                    <Form.Select aria-label="Default select example" onChange={(e) => { props.setAccountFlag(e.target.childNodes[e.target.selectedIndex].getAttribute('id')); props.setAccountIdDefault(e.target.value); }}>
                                        {props.accountIdArray.map((arrayItems) => {
                                            if (props.accountIdDefault == arrayItems) {
                                                return (<option id="microsoftsaas" value={arrayItems} selected>Microsoft CSP</option>);
                                            }
                                            else {
                                                return (<option id="googleworkspace" value={arrayItems}>Google Workspace</option>);
                                            }
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={3} md={6} className="border rounded m-1">
                            <Row className="center">
                                <div className="col-6 pt-2">
                                    <label className="pt-2 label-sm">Total Licenses
                                        <strong> {totalLicences}</strong>
                                    </label>
                                </div>
                                <div className="col-6 pt-2">
                                    <label className="pt-2 label-sm ">Total Add-Ons
                                        <strong> {totalAddOns}</strong>
                                    </label>
                                </div>
                            </Row>
                        </Col>


                        <Col lg={3} md={6} className="pt-2">
                            <div className="pt-2">
                                <Popup
                                    trigger={<a>View Cloud Portal Details</a>}
                                    modal
                                    nested
                                    contentStyle={{ width: '45%' }}
                                >
                                    {close => (
                                        <div className="modal">
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            {/* <div className="header"> Edit Quantity </div> */}
                                            <div className="content">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4>Cloud Portal Details</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="p-3">
                                                            <div className="d-flex m-0 align-items-center mb-2">
                                                                <label className="form-label mb-0 col-4">Customer</label>
                                                                <span className='col-8'>
                                                                    <a href={portal}>{customerData}</a>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex m-0 align-items-center mb-2">
                                                                <label className="form-label mb-0 col-4">Start Date</label>
                                                                <span className='col-8'>
                                                                    {startDate}
                                                                </span>
                                                            </div>
                                                            <div className="d-flex m-0 align-items-center mb-2">
                                                                <label className="form-label mb-0 col-4">Portal</label>
                                                                <span className='col-8'>
                                                                    <a href={portal}>
                                                                        {portal}
                                                                    </a>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex m-0 align-items-center mb-2">
                                                                <label className="form-label mb-0 col-4">Domain Name</label>
                                                                <span className='col-8'>{domainName}</span>
                                                            </div>
                                                            <div className="d-flex m-0 align-items-center mb-2">
                                                                <label className="form-label mb-0 col-4">Initial Password</label>
                                                                <span className='d-flex justify-content-between col-8'>
                                                                    <input type={passwordType[0]} value={initialPassword} readOnly className='plain-text' />
                                                                    <Eye setPasswordType={setPasswordType} passwordType={passwordType} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="actions">
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </Col>
                    </Row>

                    <Row className="my-2">
                        <Col md={3}>
                            <Form.Group
                                className="form-group"
                                controlId="search">

                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                />
                            </Form.Group>


                        </Col>

                        <Col md={2}>

                            <Form.Group
                                className="form-group"
                                controlId="filter1"
                            >

                                <Form.Control
                                    type="text"
                                    placeholder="Filter"
                                />

                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group
                                className="form-group"
                                controlId="filter2"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Filter"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                </Container>

                <Container className='mb-5 mt-5'>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button className="btn-lg mb-3"
                                onClick={showPanelDetails}
                            >Order Summry
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{
                        overflowX: "hidden",
                        position: "relative"
                    }}>
                        <Col style={{
                            overflowX: !showPanel ? "hidden" : "auto",
                            width: !showPanel ? "70%" : "100%",
                            display: !showPanel ? "flex" : "block",
                            flex: "0 0 auto",
                            transition: "all 0.3s",
                        }}>
                            <div style={{
                                overflowX: "auto"
                            }}>

                                {
                                    <div style={{ width: !showPanel ? "90vw" : "100%" }}>
                                        <DataTable
                                            columns={columns}
                                            data={table}
                                            theme="solarized"
                                            customStyles={customStyles}
                                            striped={true}
                                            // fixedHeaderScrollHeight="60vh"
                                            progressPending={loading}
                                            fixedHeader
                                            // pagination
                                            // paginationServer
                                            // paginationTotalRows={totalRows}
                                            // paginationPerPage={pageSize}
                                            // paginationRowsPerPageOptions={[2, 4, 8, 16]}
                                            // onChangePage={handlePageChange}
                                            // onChangeRowsPerPage={handlePerRowsChange}
                                            expandableRows
                                            expandableRowsComponent={ExpanableComponent}
                                            expandableRowsComponentProps={{"columns":columns,"table":table,"customStyles":customStyles}}
                                        />
                                    </div>
                                }
                                {/* {
                        !emptyTableCheck && (table.length) < 1 && 
                        <center>
                            <p>No Items to Display</p>
                        </center>
                    } */}


                                {
                                    actionSelectionFlag === "manage-subscription" && (isManageSubscriptionType === "NCE") &&
                                    <ManageSubscriptionNCEBase actionSelectionData={actionSelectionData} />
                                }
                                {
                                    actionSelectionFlag === "manage-subscription" && (isManageSubscriptionType === "Legacy") &&
                                    <ManageSubscriptionLegacy actionSelectionData={actionSelectionData} />
                                }
                            </div>
                        </Col>
                        <div className="col p-0" style={{
                            flex: !showPanel ? "1 0" : "1",
                            overflow: "hidden",
                            opacity: !showPanel ? "1" : "1",
                            width: !showPanel ? "30%" : "30%",
                            // width: !showPanel ? "100%" : "100",
                            // height: !showPanel ? "auto" : "0px",
                            position: !showPanel ? "absolute" : "absolute",
                            left: !showPanel ? "70%" : "100%",
                            transition: "all 0.3s",
                        }}>
                            <div className="p-3">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life
                                    accusamus terry richardson ad squid. Nihil anim keffiyeh
                                    helvetica, craft beer labore wes anderson cred nesciunt sapiente
                                    ea proident.</p>
                            </div>
                        </div>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default CloudServiceManagement
