import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api } from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_RENEWAL_SUBSCRIPTION } from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';

const GWRenewalSettings = (props) => {
    const [columns, setColumns] = useState([]);
    const [table, setTable] = useState([]);
    let ColumnName = ["Renewal_Plan", "Description"];
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    let renewalPlanUpdated="";

    async function GetManageSubscriptionTableData() {
        setLoading(true);
        console.log("started");
        await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_RENEWAL_SUBSCRIPTION).then(resp => {
            // handle success
            console.log("Result", resp.data);

            console.log()
            let f = [];
            console.log("Error");
            if (resp.data.length > 1) {
                (resp.data).map((td) => {
                    f.push
                        (
                            {

                                "Description": td.description,
                                "Renewal_Plan": td.renewalPlan,
                            }
                        );
                })
            }
            else {
                f.push
                    (
                        {

                            "Renewal_Plan": resp.data.renewalPlan,
                            "Description": resp.data.description,
                        }
                    );
            }

            console.log("Error");
            console.log("f : ", f);
            setTable(f);         // Setting Table Data
            let d = [];
            ColumnName.map((td) => {
                d.push({
                    id: `${td}`,
                    name: `${td}`,
                    // selector: row => row[columnNameRow],
                    selector: row => row[`${td}`],
                    // sortable: true,
                    center: true,
                    wrap: true,
                })
            })
            setColumns(d);     // Setting Column Data
            console.log("------", d);
            setLoading(false);
        })
            .catch(error => {
                // handle error
                console.log("Error");
                setLoading(false);
            });
    };

    useEffect(() => {
        GetManageSubscriptionTableData();
    }, [props.actionSelectionData.SubscriptionId]);

    async function PostManageSubscriptionTableData() {
       
        let postData = {
            "subscriptionId": props.actionSelectionData.SubscriptionId,
            "autoRenewalName": renewalPlanUpdated
        }
        console.log("POST DATA :",postData);

        const config = { headers: { 'Content-Type': 'application/json' } };
        await api.post(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_RENEWAL_SUBSCRIPTION, postData, config)
            .then(response => {
                console.log(response);
                GetManageSubscriptionTableData();
            })
            .catch(error => {
                console.log("Catch");
            });

        console.log("Post Updated")
    }



    const handleChange = (rows) => {
        console.log("Rows",rows);
        if(rows.selectedCount>0){
            console.log(rows.selectedRows[0]["Description"]);
            renewalPlanUpdated = rows.selectedRows[0]["Renewal_Plan"];
        }
        // console.log(rows.selectedRows[0]["Description"]);
        // renewalPlanUpdated = rows.selectedRows[0]["Renewal_Plan"];
        // console.log(renewalPlanUpdated,rows.selectedRows[0]["Renewal_Plan"]);
        }

    const rowSelectCritera = (row) => {
        // console.log("Renewal Plan", row["Renewal Plan"],props.actionSelectionData.RenewalPlan);
        // console.log(row["Renewal Plan"] === props.renewalSetting);

        if (row["Renewal_Plan"] === props.actionSelectionData.RenewalPlan) {
                //  console.log(row["Renewal_Plan"])
            return row["Renewal_Plan"];
        } else {

        }
    }


    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    const customStyles = {
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '600',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#29286a',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: 'white',
                textTransform: 'capitalize'
            },
        },
    };

    return (
        <div>
            <Container className='mb-5 mt-5'>
                <center><h1 className='mt-10'>GW Renewal Subsription</h1></center>
                <h2>{props.renewalSetting}</h2>
                {
                    <DataTable
                        columns={columns}
                        data={table}
                        theme="solarized"
                        customStyles={customStyles}
                        striped={true}
                        // fixedHeaderScrollHeight="60vh"
                        progressPending={loading}
                        fixedHeader
                        selectableRows
                        selectableRowSelected={rowSelectCritera}
                        onSelectedRowsChange={handleChange}
                        selectableRowsHighlight
                        selectableRowsSingle
                    />
                }

                <Button variant='primary' className="my-3" onClick={PostManageSubscriptionTableData} >
                    Change Renewal Settings
                </Button>
            </Container>
        </div>
    )
}

export default GWRenewalSettings
