import React , {useState,useEffect} from 'react';
import {api} from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION ,
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY , 
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION , 
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION , 
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL , 
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ADD_ONS , 
        MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS} from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import Popup from 'reactjs-popup';
import './styles.scss';
import 'reactjs-popup/dist/index.css';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';


const ManageSubscriptionNCEBase = (props) => {

  const [tableSub, setTableSub] = useState([]);
  let ColumnName = ["Plan Name (SKU) / Subscription ID", "Billing Term" , "Commintment Term" , "RRP (Inc. GST)" , "Price (Ex.GST)" , "	Auto Renew" ,  "Qty", "Actions"];
  const [loading, setLoading] = useState(false);

  const [updatedQuantity, setUpdatedQuantity] = useState();
  const [editQuantityError, setEditQuantityError] = useState(false);
  const [isSwitchOnState, setIsSwitchOnState] = useState(false);
  const [isSwitchOnStateAutoRenew, setIsSwitchOnStateAutoRenew] = useState(false);
  const [minQuantity, setMinQuantity] = useState();
  const [maxQuantity, setMaxQuantity] = useState();
  const [statusConfirmationBox, setStatusConfirmationBox] = useState("");
  const [statusConfirmationBoxAutoRenew, setStatusConfirmationBoxAutoRenew] = useState("");
  const [statusConfirmationBoxCheck, setStatusConfirmationBoxCheck] = useState("");


  const [columnsAddOns, setColumnsAddOns] = useState([]);
    const [tableAddOns, setTableAddOns] = useState([]);
    let ColumnNameAddOns = ["Plan Name", "SKU","Billing Term","Commitment Term", "RRP (Inc. GST)","Price (Ex. GST)","Auto Renew","Qty"];
    const [loadingAddOns, setLoadingAddOns] = useState(false);
    const [loadingAddonTable, setLoadingAddonTable] = useState(false);
    const [tableAddOnsNew, setTableAddOnsNew] = useState([]);
    const [tableAddOnsAutoRenewToggle, setTableAddOnsAutoRenewToggle] = useState(true);
    const [quantityAddOnColumn, setQuantityAddOnColumn] = useState([]);
    const [isPurchaseAddOnButton, setIsPurchaseAddOnButton] = useState(false);
    const [isPurchaseAddOnCheckFlag, setIsPurchaseAddOnCheckFlag] = useState(false);

    const [isPurchaseAddOnConfirmFlag, setIsPurchaseAddOnConfirmFlag] = useState(false);

    let keyArray = [];
    let valueArray = [];
    let autoRenewValueArray = [];
    // let addonsPostRequestUpdatedData = [];
    const [addonsPostRequestUpdatedData, setAddonsPostRequestUpdatedData] = useState([]);
    


  const CheckSubscriptionStatus = (a) => {
    console.log(a);
    if(a==="ACTIVE"){
        // isSwitchOn=(true);
        // setStatusConfirmationBox("deactivate-subscription");
        setStatusConfirmationBoxCheck("deactivate-subscription")
        }
    else if(a==="SUSPENDED"){
        // isSwitchOn=(false);
        // setStatusConfirmationBox("activate-subscription");
        setStatusConfirmationBoxCheck("activate-subscription")
    }
  };

  const CheckSubscriptionStatusAutoRenew = (a) => {
    console.log(a);
    if(a===true){
        // isSwitchOn=(true);
        setIsSwitchOnStateAutoRenew(true);
        }
    else if(a===false){
        // isSwitchOn=(false);
        setIsSwitchOnStateAutoRenew(false);
    }
  };


    const onSwitchActionAutoRenew = (e) => {
      if(isSwitchOnStateAutoRenew==false){
          setStatusConfirmationBoxAutoRenew("disable-auto-renew")
      }
      else{
          setStatusConfirmationBoxAutoRenew("enable-auto-renew")
      }
    };

  useEffect(() => {
    console.log(isSwitchOnState)
    console.log(isSwitchOnStateAutoRenew)
  }, [isSwitchOnState])


  const GetManageSubscriptionTableData = async () => {
    setLoading(true);
    console.log("started");
    await api.get(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION + props.actionSelectionData.SubscriptionId).then(resp => {
        // handle success
        console.log("Result",resp.data);
        setUpdatedQuantity(resp.data.licenseCount);
        setMinQuantity(resp.data.minQuantity);
        setMaxQuantity(resp.data.maxQuantity)
        CheckSubscriptionStatus(resp.data.subscriptionStatus);
        CheckSubscriptionStatusAutoRenew(resp.data.licenseAutoRenewal);

        setTableSub({
            "PlanName": resp.data.planName,
            "SubscriptionID": resp.data.vendorSubscriptionId,
            "BillingTerm": resp.data.billingTerm,
            "CommintmentTerm": resp.data.termDuration,
            "RRP": resp.data.rrpInclusiveGST,
            "Price": resp.data.customerBuyPrice,
            "Qty": resp.data.licenseCount,
            "Status": resp.data.subscriptionStatus,
            "Plancode":resp.data.planCode,
        })
        console.log("Table Sub",tableSub,resp.data);
        setLoading(false);
        // if(resp.data.planType != ("iam" || "add-on")){
        //     GetManageSubscriptionAddOns();
        // }
      })
      .catch(error => {
        // handle error
        console.log("Error");
        setLoading(false);
      });
    };

    useEffect(() => {
        GetManageSubscriptionTableData();
    }, [props.actionSelectionData.SubscriptionId]);


    async function GetManageSubscriptionAddOns() {
        setLoadingAddonTable(true);
        setLoadingAddOns(true);
        await api.get(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ADD_ONS , {
            params: {
                subscriptionId: props.actionSelectionData.SubscriptionId,
                pageNo: 1,
                pageSize: 100,
            }
        }).then(resp => {
                console.log("New Data Table :" ,resp.data.content);
                let f = [];
                setTableAddOnsNew(resp.data.content);
                setLoadingAddOns(false);
        })
        .catch(error => {
        // handle error
        console.log(error);
        setLoadingAddOns(false);
        });
      
    }


    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
      });
      
      const customStyles = {
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '600',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#29286a',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                color: 'white',
                textTransform: 'capitalize'
            },
        },
      };


      const addOnQuantityChange = (e) => {
        console.log("ID OF SELECTED ONE : ",e.target.id);
        console.log("VALUE : ",e.target.value);
        let key=e.target.id;
        let value=e.target.value;
        if(keyArray.length===0){
            keyArray.push(key);
            valueArray.push(value);
            autoRenewValueArray.push(true);
            setIsPurchaseAddOnButton(true);
        }
        else{
            console.log("Key in Key Array :",keyArray.includes(key))
            if(keyArray.includes(key)){
                let index = keyArray.indexOf(key);
                valueArray[index]=value
            }
            else{
                keyArray.push(key);
                valueArray.push(value);
                autoRenewValueArray.push(true);
            }
        }
        console.log( "New Key and Value Add On Inside : ", keyArray , " Value Array : " , valueArray , "Toggle : " , autoRenewValueArray)
        // quantityaddon = [{planId:Value},{planId:Value}]
        // [{1:34},{2:45}] quantityaddon[0].planId
        // [70321,70322,70323] [1,1,1]
        
      };


      const onSwitchActionAutoRenewAddOns = (e) => {
        // if(tableAddOnsAutoRenewToggle == true){
        //     setTableAddOnsAutoRenewToggle(!tableAddOnsAutoRenewToggle);
        // }
        // if(tableAddOnsAutoRenewToggle == false){
        //     setTableAddOnsAutoRenewToggle(!tableAddOnsAutoRenewToggle);
        // }
        console.log(e.target.checked);
        let value = e.target.checked;
        let key = e.target.id;
        console.log("Key in Key Array :",keyArray.includes(e.target.id))
        if(keyArray.includes(key)){
            let index = keyArray.indexOf(key);
            autoRenewValueArray[index]=value;
        }
        else{
            autoRenewValueArray.push(true);
            keyArray.push(key);
            valueArray.push(0);
        }
        console.log( "New Key and Value Add On Inside : ", keyArray , " Value Array : " , valueArray , "Toggle : " , autoRenewValueArray)

      }


      const purchaseAddOn = async () => {
        let errorAddOnCheckFlag = false;
       for (let index = 0; index < keyArray.length; index++) {
        if(Number(valueArray[index])>=minQuantity && Number(valueArray[index])<=maxQuantity){
                setAddonsPostRequestUpdatedData(current => [...current,{
                    "licenseAutoRenewal": autoRenewValueArray[index],
                    "planId": Number(keyArray[index]),
                    "purchaseOrderNumber": "",
                    "quantity": Number(valueArray[index])
                }]
                    )
        }
        else{
            console.log("This cannot be added since qty is out of bound range");
            setIsPurchaseAddOnCheckFlag(true);
            errorAddOnCheckFlag=true;
        }
       }
       console.log("Final Array : ",addonsPostRequestUpdatedData);
       setIsPurchaseAddOnConfirmFlag(true);
       if(!errorAddOnCheckFlag && addonsPostRequestUpdatedData.length>0){
            setIsPurchaseAddOnCheckFlag(false);
            console.log("No Error Found");
            const data = {
                "subscriptionId": props.actionSelectionData.SubscriptionId,
                "addonPlans": addonsPostRequestUpdatedData
            }
            const config = { headers: { 'Content-Type': 'application/json' } };
            await api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS, data, config)
            // await api.post("MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS", data, config)
                    .then(resp => {
                    console.log( "Condition : ",resp.data.isSuccess);
                    alert("Add On Added Successfully");
                    window.location.reload();
                    })
                    .catch(error => {
                    //   setLoadingConfirmOrder(false);
                    console.log(error);
                    });
        }
      }


  return (
    <>
      <Container>
        <br/><br/>
        <center><h1 className='mt-10'>NCE Base Microsoft CSP Manage Subscription {props.actionSelectionData.SubscriptionId}</h1></center>
          { !loading &&
              <>
                <br/><br/>
                <Table responsive striped hover>
                    <thead className='manage-subscription-table-head'>
                        <tr>
                        {ColumnName.map((td)=>{
                            return(
                                <th className='manage-subscription-table-head-sub'>{td}</th>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='manage-subscription-table-data'>{tableSub.PlanName + "("+ tableSub.Plancode +")" }<br/> {tableSub.SubscriptionID}</td>
                            <td className='manage-subscription-table-data'>{tableSub.BillingTerm}</td>
                            <td className='manage-subscription-table-data'>{tableSub.CommintmentTerm}</td>
                            <td className='manage-subscription-table-data'>$ {tableSub.RRP}</td>
                            <td className='manage-subscription-table-data'>$ {tableSub.Price}</td>
                            <td className='manage-subscription-table-data'>
                                <Form.Check 
                                    type="switch"
                                    checked={isSwitchOnStateAutoRenew}
                                    onChange={() => onSwitchActionAutoRenew()}
                                    id="custom-switch"
                                />
                            </td>
                            <td className='manage-subscription-table-data'>{tableSub.Qty}&nbsp;&nbsp;&nbsp;
                            <Popup
                                trigger={<Button>Edit</Button>}
                                modal
                                nested
                                contentStyle={{ width: '25%' }}
                            >
                                {close => (
                                <div className="modal">
                                    <button className="close" onClick={close}>
                                    &times;
                                    </button>
                                    <div className="header"> Edit Quantity </div>
                                    <div className="content">
                                        <center><Form.Control className='quantity-input-field' type="text"  defaultValue={updatedQuantity} 
                                        onChange={(e)=>{
                                            setUpdatedQuantity(e.target.value);
                                        }
                                        }/></center>
                                        <br />
                                        <center><b>{editQuantityError && <p className='edit-quantity-paragraph text-danger'>Number should be between {minQuantity} & {maxQuantity}</p>}</b></center>
                                        {!editQuantityError && <p className='empty-edit-quantity-paragraph'></p>}
                                    </div>
                                    <div className="actions">
                                    <Button
                                        className="button me-3"
                                        onClick={() => {
                                        if(updatedQuantity>=minQuantity && updatedQuantity<=maxQuantity){
                                            setEditQuantityError(false);
                                            const config = { headers: { 'Content-Type': 'application/json' } };
                                            let updatedQuantityData = {
                                                "subscriptionResourceList": [
                                                    {
                                                      "subscriptionId": props.actionSelectionData.SubscriptionId,
                                                      "quantity": updatedQuantity,
                                                    }
                                                  ]
                                            }
                                            api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY,updatedQuantityData,config).then(resp => {
                                                console.log(resp);
                                                // GetManageSubscriptionTableData();
                                                close();
                                                window.location.reload();
                                            })
                                            .catch(error => {
                                                console.log(error);
                                                });
                                        }   
                                        else{
                                            setEditQuantityError(true);
                                            console.log("Quantity is out of range bond");
                                            // alert("Error");
                                        }            
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        className="button"
                                        onClick={() => {
                                        console.log('modal closed ');
                                        close();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    </div>
                                </div>
                                )}
                            </Popup>
                            </td>
                            <td className='manage-subscription-table-data'>
                                {/* {tableSub.Status} */}
                                {/* {isSwitchOnState?"ACTIVE":"INACTIVE"} */}
                                <Form.Select aria-label="Default select example" size="sm" onChange={(e)=>{
                                    if((e.target.value) === "activate-subscription"){
                                        setStatusConfirmationBox("deactivate-subscription");
                                    }
                                    if((e.target.value) === "deactivate-subscription"){
                                        setStatusConfirmationBox("deactivate-subscription");
                                        setStatusConfirmationBox("activate-subscription");
                                    }
                                    if((e.target.value) === "purchase-add-ons"){
                                        GetManageSubscriptionAddOns();
                                    }
                                }}>
                                    <option>Select Action</option>
                                    <option value="1">Upgrade Subscription</option>
                                    <option value="2">Manage Renewal</option>
                                    <option value="purchase-add-ons">Purchase Add-ons</option>
                                    <option value="4">Change Quantity</option>
                                    {statusConfirmationBoxCheck==="activate-subscription" && <option value="activate-subscription">Activate Subscription</option>}
                                    {statusConfirmationBoxCheck==="deactivate-subscription" && <option value="deactivate-subscription">Suspend Subscription</option>}
                                </Form.Select>
                            </td>
                        </tr>
                    </tbody>
                </Table>
              </>
          }
          { loading &&
              <center><br/><br/><b><h4>Loading . . .</h4></b></center>
          }
            {
                loadingAddonTable && tableAddOnsNew.length>0 &&
                <>
                <br/><br/>
                    <center><h1>Add-ons available to the Subscription</h1></center>
                    <br />
                    <div className='purchase-add-on-table'>
                        <Table responsive striped hover >
                            <thead className='manage-subscription-table-head'>
                                <tr>
                                {ColumnNameAddOns.map((td)=>{
                                    return(
                                        <th className='manage-subscription-table-head-sub'>{td}</th>
                                    )
                                })}
                                </tr>
                            </thead>
                            {/* {
                                !tableAddOnsNew &&
                                <p>Loading . . .</p>
                            } */}
                                <tbody>
                                    {tableAddOnsNew.map((addOnTable)=>{
                                        return(<tr id={addOnTable.planId}>
                                            <td className='manage-subscription-table-data'>{addOnTable.planName}</td>
                                            <td className='manage-subscription-table-data'>{addOnTable.planCode}</td>
                                            <td className='manage-subscription-table-data'>{addOnTable.billingTerm}</td>
                                            <td className='manage-subscription-table-data'>{addOnTable.duration}</td>
                                            <td className='manage-subscription-table-data'>$ {addOnTable.rrpInclusiveTax}</td>
                                            <td className='manage-subscription-table-data'>$ {addOnTable.rrpExclusiveTax}</td>
                                            <td className='manage-subscription-table-data'>
                                                <Form.Check 
                                                    type="switch"
                                                    // checked={tableAddOnsAutoRenewToggle}
                                                    defaultChecked={true}
                                                    onChange={(e) => onSwitchActionAutoRenewAddOns(e)}
                                                    id={addOnTable.planId}
                                                />
                                            </td>
                                            <td>
                                                <InputGroup >
                                                    <Form.Control
                                                        placeholder="Quantity"
                                                        defaultValue={0}
                                                        aria-label="Quantity"
                                                        aria-describedby="basic-addon1"
                                                        className='nce-base-add-ons-quantity-input-field'
                                                        id={addOnTable.planId}
                                                        onChange={(e)=>addOnQuantityChange(e)}
                                                    />
                                                </InputGroup>
                                            </td>
                                        </tr>);
                                    })
                                }
                                </tbody>
                        </Table>
                    </div>
                    <br /><br />
                    {isPurchaseAddOnCheckFlag && <center><b><p className='purchase-add-on-paragraph text-danger'>The quantity added cannot be less than {minQuantity} and more than {maxQuantity}</p></b></center>}
                    {!isPurchaseAddOnCheckFlag && <center><p className='purchase-add-on-paragraph'></p></center>}
                    <br />
                    {isPurchaseAddOnButton && !isPurchaseAddOnConfirmFlag && <Button className="float-right" onClick={()=>{purchaseAddOn()}}>Purchase Add Ons</Button>}
                    {isPurchaseAddOnButton && isPurchaseAddOnConfirmFlag && <Button className="float-right" onClick={()=>{purchaseAddOn()}}>Confirm</Button>}  {/*  Added Confirm Button */}
                    {!isPurchaseAddOnButton && <Button className="float-right" disabled>Purchase Add Ons</Button>}
                    <br/><br/>
                </>
            }
      </Container>
              <Popup
                  open={statusConfirmationBox==="activate-subscription"}
                  modal
                  contentStyle={{ width: '25%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                      &times;
                      </button>
                      <div className="header"> Deactivate Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Deactivate Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Dectivate Subscription");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let subscriptionId = props.actionSelectionData.SubscriptionId
                              let dataDeactivateSubscription = {
                                    subscriptionId
                                }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION,dataDeactivateSubscription,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnState(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBox("activate-subscription");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBox("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>

              <Popup
                  open={statusConfirmationBox==="deactivate-subscription"}
                  modal
                  contentStyle={{ width: '25%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBox("");}}>
                      &times;
                      </button>
                      <div className="header"> Activate Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Activate Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Activate Subscription");
                              let subscriptionId = props.actionSelectionData.SubscriptionId
                              let dataActivateSubscription = {
                                    subscriptionId
                                }
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION,dataActivateSubscription,config).then(resp => {
                                  setIsSwitchOnState(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBox("deactivate-subscription");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBox("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>
              <Popup
                  open={statusConfirmationBoxAutoRenew==="enable-auto-renew"}
                  modal
                  contentStyle={{ width: '40%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBoxAutoRenew("");}}>
                      &times;
                      </button>
                      <div className="header"> Disable Auto Renewal Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Disable Auto Renew Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Disable Auto Renew");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let autoRenewalData = {
                                "subscriptionResourceList": [
                                  {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId,
                                    "licenseAutoRenewal": false
                                  }
                                ]
                              }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL,autoRenewalData,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnStateAutoRenew(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBoxAutoRenew("");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBoxAutoRenew("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>

              <Popup
                  open={statusConfirmationBoxAutoRenew==="disable-auto-renew"}
                  modal
                  contentStyle={{ width: '40%' }}
              >
                  {close => (
                  <div className="modal">
                      <button className="close" onClick={()=>{close();setStatusConfirmationBoxAutoRenew("");}}>
                      &times;
                      </button>
                      <div className="header"> Auto Renewal Subscription </div>
                      <div className="content">
                          <center>
                              Are you sure you want to Auto Renew Subscription ?
                          </center>
                      </div>
                      <div className="actions">
                      <Button
                          className="button me-3"
                          onClick={() => {
                              console.log("Auto Renewal Subscription");
                              const config = { headers: { 'Content-Type': 'application/json' } };
                              let autoRenewalData = {
                                "subscriptionResourceList": [
                                  {
                                    "subscriptionId": props.actionSelectionData.SubscriptionId,
                                    "licenseAutoRenewal": true
                                  }
                                ]
                              }
                              api.post(MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL,autoRenewalData,config).then(resp => {
                                  console.log(resp);
                                  setIsSwitchOnStateAutoRenew(prev => !prev);
                                  close();
                              })
                              .catch(error => {
                                  console.log(error);
                                  setStatusConfirmationBoxAutoRenew("");
                                });
                          }              
                          }
                      >
                          Yes
                      </Button>
                      <Button
                          className="button"
                          onClick={() => {
                          console.log('modal closed ');
                          setStatusConfirmationBoxAutoRenew("");
                          close();
                          }}
                      >
                          No
                      </Button>
                      </div>
                  </div>
                  )}
              </Popup>
    </>
  )
}

export default ManageSubscriptionNCEBase