// export const MANAGEMENT_CONSOLE_ACCOUNTS_URL="/management-console/accounts/";
// export const MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT="/management-console/subscriptions/";
// export const PROFILE_DETAILS="/user/profile/";
// export const PROFILE_SEND_ACTIVATION_LINK = "/forgot-password/";
// export const PROFILE_RESET_PASSWORD = "/reset-password/";
// export const PROFILE_CHANGE_PASSWORD = "/change-password/";
// export const USER_FEEDBACK  =   "/user/feedback/";
// export const LANDING_PAGE_CMS_DATA="/tenant2/api/graphql/";
// export const SUPPORT_TICKET_GET_ALL_SUBSCRIPTION="/management-console/all-subscriptions/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION = "/management-console/subscriptions/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_RENEWAL_SUBSCRIPTION = "/google-workspace/renewal-settings/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_EDIT_QUANTITY = "/google-workspace/change-quantity/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_SUSPEND = "/google-workspace/suspend-subscription/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ACTIVATE = "/google-workspace/activate-subscription/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ADD_ONS = "/google-workspace/addon-plans/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS = "/google-workspace/purchase-addon/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION = "/google-workspace/upgrade-plans/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION_NEW_PLAN_TABLE = "/google-workspace/purchasable-offers/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION_CONFIRM_ORDER = "/google-workspace/upgrade-changeoffer/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION = "/google-workspace/downgrade-plans/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_NEW_PLAN_TABLE = "/google-workspace/purchasable-offers/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_CONFIRM_ORDER = "/google-workspace/downgrade-subscription/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_OFFERS_DETAILS = "/google-workspace/offers-by-planname/";
// export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_START_PAID_SERVICE = "/google-workspace/start-paidservice/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION = "/management-console/subscriptions/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY = "/microsoft-csp/change-quantity/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION = "/microsoft-csp/activate-subscription/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION = "/microsoft-csp/suspend-subscription/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL = "/microsoft-csp/auto-renewal/";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ADD_ONS = "/microsoft-csp/addon-plans";
// export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS = "/microsoft-csp/purchase-addon";
// export const MANAGEMENT_CONSOLE_SUBSCRIPTION_ADD_ONS ="/management-console/subscription-add-ons/";
// export const MANAGEMENT_CONSOLE_SUPPORT_TICKET = "management-console/raise-ticket/";


export const VALIDATE_USER="/b2b2c-user/api/validate-user/";
export const VALIDATE_OTP="/b2b2c-user/api/validate-otp/";
// export const GET_TOKEN="/b2b2c-user/connect/token/";
export const FORGOT_PASSWORD="/b2b2c-user/api/forgot-password/";
export const RESET_PASSWORD="/b2b2c-user/api/reset-password/";
export const MANAGEMENT_CONSOLE_ACCOUNTS_URL="/b2b2c-mc/api/cloudaccounts/";
export const MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT="/b2b2c-mc/api/subscriptions/";
export const PROFILE_DETAILS="/b2b2c-customer/api/profile/";
export const MFA_UPDATE="/b2b2c-customer/api/profile/mfa/";
export const PROFILE_SEND_ACTIVATION_LINK = "/b2b2c-user/api/forgot-password/";
export const PROFILE_RESET_PASSWORD = "/b2b2c-user/api/reset-password/";
export const PROFILE_CHANGE_PASSWORD = "/b2b2c-user/api/change-password/";
export const USER_FEEDBACK  =   "/b2b2c/mc/user/feedback/";
export const LANDING_PAGE_CMS_DATA="/b2b2c-cms/";
export const SUPPORT_TICKET_GET_ALL_SUBSCRIPTION="/b2b2c-mc/api/subscriptions/all/";
export const EDIT_QUANTITY="/b2b2c-order/api/orders/changequantity/";
export const TERMS_AND_CONDITION_ACCEPT="/b2b2c-customer/api/profile/accept-terms/";
// export const SUPPORT_TICKETS = "/b2b2c-customer/support-tickets/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION = "/b2b2c-mc/api/subscriptions/";
// For Future Purpose
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_RENEWAL_SUBSCRIPTION = "/b2b2c-mc/google-workspace/renewal-settings/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_EDIT_QUANTITY = "/b2b2c-mc/google-workspace/change-quantity/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_SUSPEND = "/b2b2c-mc/google-workspace/suspend-subscription/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ACTIVATE = "/b2b2c-mc/google-workspace/activate-subscription/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_ADD_ONS = "/b2b2c-mc/google-workspace/addon-plans/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS = "/b2b2c-mc/google-workspace/purchase-addon/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION = "/b2b2c-mc/google-workspace/upgrade-plans/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION_NEW_PLAN_TABLE = "/b2b2c-mc/google-workspace/purchasable-offers/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_UPGRADE_SUBSCRIPTION_CONFIRM_ORDER = "/b2b2c-mc/google-workspace/upgrade-changeoffer/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION = "/b2b2c-mc/google-workspace/downgrade-plans/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_NEW_PLAN_TABLE = "/b2b2c-mc/google-workspace/purchasable-offers/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_CONFIRM_ORDER = "/b2b2c-mc/google-workspace/downgrade-subscription/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_OFFERS_DETAILS = "/b2b2c-mc/google-workspace/offers-by-planname/";
export const MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_CONVERT_TO_PAID_START_PAID_SERVICE = "/b2b2c-mc/google-workspace/start-paidservice/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION = "/b2b2c-mc/management-console/subscriptions/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_CHANGE_QUANTITY = "/b2b2c-mc/microsoft-csp/change-quantity/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ACTIVATE_SUBSCRIPTION = "/b2b2c-mc/microsoft-csp/activate-subscription/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_SUSPEND_SUBSCRIPTION = "/b2b2c-mc/microsoft-csp/suspend-subscription/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_AUTO_RENEWAL = "/b2b2c-mc/microsoft-csp/auto-renewal/";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_ADD_ONS = "/b2b2c-mc/microsoft-csp/addon-plans";
export const MANAGEMENT_CONSOLE_MICROSOFT_CSP_MANAGE_SUBSCRIPTION_PURCHASE_ADD_ONS = "/b2b2c-mc/microsoft-csp/purchase-addon";
// END Future Purpose
export const MANAGEMENT_CONSOLE_SUBSCRIPTION_ADD_ONS ="/b2b2c-mc/api/subscriptions/addons/";
export const MANAGEMENT_CONSOLE_SUPPORT_TICKET = "/b2b2c-customer/api/support-tickets/";
export const LOGOUT = "/b2b2c-customer/api/profile/logout/";
export const SUPPORT_TICKET_DETAILS = "/b2b2c-customer/api/support-tickets/";
export const SUPPORT_TICKET_DETAILS_PRIORITIES = "/b2b2c-customer/api/support-tickets/priorities";
export const SUPPORT_TICKET_DETAILS_SUBJECTS = "/b2b2c-customer/api/support-tickets/subjects";
export const ORDER_HISTORY = "/b2b2c-order/api/orders/";
export const ORDER_HISTORY_DOWNLOAD = "/b2b2c-order/api/orders/download";
export const ORDER_HISTORY_STATUS_TYPES = "/b2b2c-order/api/orders/status-types";