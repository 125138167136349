import React from 'react';
import "./styles.scss";
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const EmptyCloudServices = (
    {
        EmptyCloudServicesIllustration,
        setActiveLocalKey,
    }
) => {

  const navigate = useNavigate();

  return (
    <div className="table-body-height-responsive empty-cloud-services-table-body-height-responsive">
        <img src={EmptyCloudServicesIllustration} className="empty-cloud-services-svg" />
        <h2 className="mt-4 mb-3">No Cloud Service is currently available under this Profile</h2>
        <p className="mb-3">There are no subscriptions available due to absence of any cloud service attached to this user profile. Please raise a support ticket to add any cloud service.</p>
        <Button
        className="btn btn-lg btn-primary"
        onClick={() => {
            setActiveLocalKey("ticketDetails");
            navigate("/create-support-ticket", {
            state: { accountId: "", subId: "", planName: "", isGlobal: true }
            }
            );
        }}
        >
        Support Tickets
        </Button>
    </div>
  )
}

export default EmptyCloudServices
