import React from 'react'
import {Container,Button,Row,Col} from "react-bootstrap";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg , BoxArrowUpRight } from "react-bootstrap-icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Eye from "../../../../Components/Eye";

const CSPViewCloudPortalDetails = (
    {
        serviceName,
        customerData,
        copyToClipboard,
        copied,
        setActiveLocalKey,
        startDate,
        domainName,
        portal,
        passwordType,
        initialPassword,
        setPasswordType
    }
) => {

    const navigate = useNavigate();

  return (
    <Popup
        className="custom-modal custom-modal-md"
        trigger={
            <a className="cloud-portal">
                <span className="d-none d-md-block">View Cloud Portal Details</span>
                <span className="d-block d-md-none">View Details</span>
            </a>
        }
        modal
        nested
        >
        {(close) => (
            <div className="modal">
            <div className="header">
                <h2>Cloud Portal Details</h2>
                <XLg
                size={18}
                className="cpointer text-strong"
                onClick={close}
                color="#6A6A6A"
                />
            </div>
            <Container>
                <Row>
                <Row>
                    <Col className="mb-3">
                    <label className="text-medium">Cloud Service</label>
                    <p>
                        <strong>{serviceName}</strong>
                    </p>
                    </Col>
                </Row>
                <Col md={12} className="mb-3">
                    <label className="text-medium">Customer</label>
                    <p>
                    <strong className="text-medium">
                        {customerData}
                    </strong>
                    </p>
                </Col>
                <Col md={12} className="mb-3">
                    <label className="text-medium">Start Date</label>
                    <p>
                    <strong className="text-medium">
                        {startDate}
                    </strong>
                    </p>
                </Col>
                <Col md={12} className="mb-3">
                    <label className="text-medium">Domain Name</label>
                    <p>
                    <strong className="text-medium">
                        {domainName}
                        <span className="clicktocopy">
                        <span
                            type="button"
                            class="copy cpointer ml-2"
                            onClick={copyToClipboard}
                        >
                            &nbsp;
                        </span>
                        <span className="copystatus">{copied}</span>
                        </span>
                    </strong>
                    </p>
                </Col>
                <Col md={12} className="mb-3">
                    <label className="text-medium">Portal</label>
                    <p>
                    <strong className="text-medium">
                        <a href={portal} target="_blank">
                        {portal} {" "}
                        <strong>
                            <span className='external-link cpointer ml-2'></span>
                        </strong>
                        </a>
                    </strong>
                    </p>
                </Col>
                {/* <Col md={8} className="mb-3">
                    <label className="text-medium">
                    Initial Password
                    </label>
                    <p>
                    <span className="d-flex justify-content-between col-lg-8 text-medium">
                        <input
                        type={passwordType[0]}
                        value={initialPassword}
                        readOnly
                        className="plain-text text-strong"
                        disabled
                        style={{ fontWeight: "bold", color: "black" }}
                        />
                        <Eye
                        setPasswordType={setPasswordType}
                        passwordType={passwordType}
                        tabindex="0"
                        />
                    </span>
                    </p>
                </Col> */}
                <Col md={12} className="text-center">
                    <Button
                    className="btn btn-lg m-auto px-5"
                    tabindex="1"
                    onClick={() => {
                        setActiveLocalKey("ticketDetails");
                        navigate("/create-support-ticket", {
                        state: {
                            accountId: "",
                            subId: "",
                            planName: "",
                            isGlobal: true,
                        },
                        });
                    }}
                    >
                    Support Tickets
                    </Button>
                </Col>
                </Row>
            </Container>

            <div className="actions"></div>
            </div>
        )}
    </Popup>
  )
}

export default CSPViewCloudPortalDetails
