import React from 'react'
import {Container,Button,Row,Col} from "react-bootstrap";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg , BoxArrowUpRight } from "react-bootstrap-icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const GWSViewCloudPortalDetails = (
    {
        serviceName,
        customerData,
        cloudIdentity,
        startDate,
        domainName,
        copyToClipboard,
        copied,
        setActiveLocalKey
    }
) => {

    const navigate = useNavigate();

  return (
    <Popup
        className="custom-modal custom-modal-md"
        trigger={
            <a className="cloud-portal">
                <span className="d-none d-md-block">View Cloud Portal Details</span>
                <span className="d-block d-md-none">View Details</span>
            </a>
        }
        modal
        nested
        >
        {(close) => (
            <div className="modal">
            <div className="header">
                <h2>Cloud Portal Details</h2>
                <XLg
                size={18}
                className="cpointer text-strong"
                onClick={close}
                color="#6A6A6A"
                />
            </div>
            <Container>
                <Row>
                <Col className="mb-3">
                    <label className="text-medium">Cloud Service</label>
                    <p>
                    <strong>{serviceName}</strong>
                    </p>
                </Col>
                </Row>
                <Row>
                <Col lg={12} className="mb-3">
                    <label className="text-medium">Customer</label>
                    <p className="text-medium">
                    <strong>
                        {customerData}
                    </strong>
                    </p>
                </Col>
                <Col lg={12} className="mb-3">
                    <label className="text-medium">
                    Cloud Identity Id
                    </label>
                    <p className="text-medium">
                    <strong>{cloudIdentity}</strong>
                    </p>
                </Col>
                <Col lg={12} className="mb-3">
                    <label className="text-medium">Start Date</label>
                    <p className="text-medium">
                    <strong>{startDate}</strong>
                    </p>
                </Col>
                <Col lg={12} className="mb-3">
                    <label className="text-medium">Domain Name</label>
                    <p className="text-medium">
                    <strong>
                        {domainName}
                        <span className="clicktocopy">
                        <span
                            type="button"
                            class="copy cpointer ml-2"
                            onClick={copyToClipboard}
                        >
                            &nbsp;
                        </span>
                        <span className="copystatus">{copied}</span>
                        </span>
                    </strong>
                    </p>
                </Col>
                <Col lg={12} className="mb-3">
                    <label className="text-medium">Portal</label>
                    <p className="mb-0">
                    <strong className="text-medium">
                        <a
                        href={
                            "https://admin.google.com/u/0/ac/home?ecid=" +
                            cloudIdentity
                        }
                        target="_blank"
                        >
                        Admin Console{" "}
                        <span className='external-link cpointer ml-2'></span>
                        </a>
                    </strong>
                    <br />
                    </p>
                </Col>
                <Col md={12} className="text-center">
                    <Button
                    className="btn btn-lg m-auto px-5"
                    onClick={() => {
                        setActiveLocalKey("ticketDetails");
                        navigate("/create-support-ticket", {
                        state: {
                            accountId: "",
                            subId: "",
                            planName: "",
                            isGlobal: true,
                        },
                        });
                    }}
                    >
                    Support Tickets
                    </Button>
                </Col>
                </Row>
            </Container>
            <div className="actions"></div>
            </div>
        )}
    </Popup>
  )
}

export default GWSViewCloudPortalDetails
