import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { api } from "../../../../../Utils/API";
import { MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION , 
  MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION , 
  MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_NEW_PLAN_TABLE , 
  MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_CONFIRM_ORDER} from "../../../../../Utils/GlobalConstants";
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import './styles.scss';

const GWDowngradeSubscription = (props) => {
  const [columns, setColumns] = useState([]);
  const [table, setTable] = useState([]);
  let ColumnName = ["Plan Name", "Description"];
  const [loading, setLoading] = useState(false);
  const [skuNameKey, setSkuNameKey] = useState("");
  const [firstNextPage, setFirstNextPage] = useState(false);
  const [secondNextPage, setSecondNextPage] = useState(false);
  const [thirdNextPage, setThirdNextPage] = useState(false);
  const [loadingConfirmOrder, setLoadingConfirmOrder] = useState(false);



  const [currentPlantable, setCurrentPlanTable] = useState([]);
  let ColumnNameCurrentPlantable = ["Offer Name", "Edition Name","RRP (INC)", "Customer Price (Ex)","Billing Period"];
  const [columnsCurrentPlanTable, setColumnsCurrentPlanTable] = useState([]);

  const [newPlantable, setNewPlanTable] = useState([]);
  let ColumnNameNewPlantable = ["Offer Name", "Edition Name","RRP (INC)", "Customer Price (Ex)","Billing Period"];
  const [columnsNewPlanTable, setColumnsNewPlanTable] = useState([]);

  const [loadingCurrentPlanTable, setLoadingCurrentPlanTable] = useState(false);
  const [loadingNewPlanTable, setLoadingNewPlanTable] = useState(false);
  const [loadingReviewOrder, setLoadingReviewOrder] = useState(false);
  

  const [offerSKU, setOfferSKU] = useState("");
  const [minQuantity, setMinQuantity] = useState();
  const [maxQuantity, setMaxQuantity] = useState();
  let ColumnNameReviewOrder = ["Product", "Plan Name","License", "Reseller Total Price (Ex)","P.O Number"];


  const [reviewOrderProduct, setReviewOrderProduct] = useState("");
  const [reviewOrderPlanName, setReviewOrderPlanName] = useState("");
  const [reviewOrderLicence, setReviewOrderLicence] = useState(1);
  const [reviewOrderResellerTotalPrice, setReviewOrderResellerTotalPrice] = useState("");
  const [reviewOrderPONumber, setReviewOrderPONumber] = useState("");
  const [reviewOrderPaymentPlan, setReviewOrderPaymentPlan] = useState("");
  const [editQuantityError, setEditQuantityError] = useState(false);
  

  async function GetDowngradeSubscriptionTableData() {
    setLoading(true);
    console.log("started");
    await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION+props.actionSelectionData.SubscriptionId).then(resp => {
        // handle success
        console.log("Resp : ", resp.data.purchasableSkuDetails);
        let f = [];
        if (resp.data.purchasableSkuDetails.length > 1) {
            (resp.data.purchasableSkuDetails).map((td) => {
                f.push
                    (
                        {

                            "Description": td.planDescription,
                            "Plan Name": td.planName,
                            "Key":td.skuName,
                        }
                    );
            })
        }
        else {
            f.push
                (
                    {

                            "Description": resp.data.purchasableSkuDetails[0].planDescription,
                            "Plan Name": resp.data.purchasableSkuDetails[0].planName,
                            "Key":resp.data.purchasableSkuDetails[0].skuName,
                    }
                );
        }

        setTable(f);         // Setting Table Data
        let d = [];
        ColumnName.map((td) => {
            d.push({
                id: `${td}`,
                name: `${td}`,
                // selector: row => row[columnNameRow],
                selector: row => row[`${td}`],
                // sortable: true,
                center: true,
                wrap: true,
            })
        })
        setColumns(d);     // Setting Column Data
        console.log("------", d);
        setLoading(false);
    })
        .catch(error => {
            // handle error
            console.log("Error");
            setLoading(false);
        });
};

useEffect(() => {
  GetDowngradeSubscriptionTableData();
}, [props.actionSelectionData.SubscriptionId]);

createTheme('solarized', {
  text: {
      primary: '#000000',
  },
  background: {
      default: '#FFFFFF',
  },
  divider: {
      default: '#d7dadf',
  },
  striped: {
      default: '#f5f5f5',
  },
});

const customStyles = {
  cells: {
      style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
          textAlign: 'center',
          fontSize: '0.9rem',
          fontWeight: '600',
          paddingTop: '0px',
          paddingBottom: '0px',
      },
  },
  headCells: {
      style: {
          paddingLeft: '10px', // override the cell padding for head cells
          paddingRight: '10px',
          paddingTop: '10px',
          paddingBottom: '10px',
          backgroundColor: '#29286a',
          fontWeight: 'bold',
          fontSize: '1.1rem',
          color: 'white',
          textTransform: 'capitalize'
      },
  },
};

const handleChange = (rows) => {
  if(rows.selectedCount>0){
    console.log("Rows",rows.selectedRows[0]);
    setSkuNameKey(rows.selectedRows[0]["Key"]);
    setReviewOrderProduct(rows.selectedRows[0]["Plan Name"]);
    console.log("Key",skuNameKey);
    setFirstNextPage(true);
  }
  }


  async function openPurchaseOptionCurrentPlan() {
    setLoadingCurrentPlanTable(true);
    await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_MANAGE_SUBSCRIPTION + props.actionSelectionData.SubscriptionId).then(resp => {
          console.log(resp.data);
          let f = [];
              f.push
                  (
                      {

                              "Offer Name": resp.data.billingTerm,
                              "Edition Name": resp.data.planName,
                              "RRP (INC)":resp.data.rrpInclusiveGST,
                              "Customer Price (Ex)": resp.data.customerBuyPrice,
                              "Billing Period": "",
                      }
                  );

          setCurrentPlanTable(f);         // Setting Table Data
          let d = [];
          ColumnNameCurrentPlantable.map((td) => {
              d.push({
                  id: `${td}`,
                  name: `${td}`,
                  // selector: row => row[columnNameRow],
                  selector: row => row[`${td}`],
                  // sortable: true,
                  center: true,
                  wrap: true,
              })
          })
          setColumnsCurrentPlanTable(d);     // Setting Column Data
          console.log("------", d);
          setLoadingCurrentPlanTable(false);
    })
  .catch(error => {
    // handle error
    console.log(error);
    setLoadingCurrentPlanTable(false);
  });
    
    }

    async function openPurchaseOptionNewPlan() {
      setLoadingNewPlanTable(true);
      await api.get(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_NEW_PLAN_TABLE , {
        params: {
          subscriptionId: props.actionSelectionData.SubscriptionId,
          skuName: skuNameKey
        }
      }).then(resp => {
            console.log("New Data Table :" ,resp.data.purchasableOfferDetails);
            let f = [];
            if (resp.data.purchasableOfferDetails.length > 1) {
              (resp.data.purchasableOfferDetails).map((td) => {
                  f.push
                      (
                          {
                            "Offer Name": td.offerName,
                            "Edition Name": td.planName,
                            "RRP (INC)": td.rrpInclusiveTax,
                            "Customer Price (Ex)": td.customerBuyPrice,
                            "Billing Period": td.periodType,
                            "MinQuantity": td.minValue,
                            "MaxQuantity" : td.maxValue,
                            "OfferSKU" : td.offerSku,
                            "PaymentPlan":td.paymentPlan,
                          }
                      );
              })
            }
            else {
                f.push
                    (
                        {
                          "Offer Name": resp.data.purchasableOfferDetails[0]["offerName"],
                          "Edition Name": resp.data.purchasableOfferDetails[0]["planName"],
                          "RRP (INC)":resp.data.purchasableOfferDetails[0]["rrpInclusiveTax"],
                          "Customer Price (Ex)": resp.data.purchasableOfferDetails[0]["customerBuyPrice"],
                          "Billing Period": resp.data.purchasableOfferDetails[0]["periodType"],
                          "MinQuantity": resp.data.purchasableOfferDetails[0]["minValue"],
                          "MaxQuantity" : resp.data.purchasableOfferDetails[0]["maxValue"],
                          "OfferSKU" : resp.data.purchasableOfferDetails[0]["offerSku"],
                          "PaymentPlan": resp.data.purchasableOfferDetails[0]["paymentPlan"],
                        }
                    );
            }
  
            setNewPlanTable(f);         // Setting Table Data
            let d = [];
            ColumnNameNewPlantable.map((td) => {
                d.push({
                    id: `${td}`,
                    name: `${td}`,
                    // selector: row => row[columnNameRow],
                    selector: row => row[`${td}`],
                    // sortable: true,
                    center: true,
                    wrap: true,
                })
            })
            setColumnsNewPlanTable(d);     // Setting Column Data
            console.log("------", d);
            setLoadingNewPlanTable(false);
      })
    .catch(error => {
      // handle error
      console.log(error);
      setLoadingNewPlanTable(false);
    });
      
      }

      const handleChangeNewPlanTable = (rows) => {
        if(rows.selectedCount>0){
          console.log("Rows",rows.selectedRows[0]);
          setOfferSKU(rows.selectedRows[0]["OfferSKU"]);
          setMaxQuantity(rows.selectedRows[0]["MaxQuantity"]);
          setMinQuantity(rows.selectedRows[0]["MinQuantity"]);
          setReviewOrderPlanName(rows.selectedRows[0]["Offer Name"]);
          setReviewOrderResellerTotalPrice(rows.selectedRows[0]["Customer Price (Ex)"]);
          setReviewOrderPaymentPlan(rows.selectedRows[0]["PaymentPlan"])
          setSecondNextPage(true);
        }
        }

        
        const quantityReviewOrderDowngrade = (e) => {
          let quantity = e.target.value;
          setReviewOrderLicence(e.target.value);
          if(minQuantity<=quantity && maxQuantity>=quantity){
            console.log("Correct");
          }
          }

          async function confirmOrder() {
            if(minQuantity<=reviewOrderLicence && maxQuantity>=reviewOrderLicence){
              setLoadingConfirmOrder(true);
              const config = { headers: { 'Content-Type': 'application/json' } };
              let confirmOrderData = {
                  "offerName": reviewOrderPlanName,
                  "offerSku": offerSKU,
                  "paymentPlan": reviewOrderPaymentPlan,
                  "planName": reviewOrderProduct,
                  "poNumber": reviewOrderPONumber,
                  "quantity": reviewOrderLicence,
                  "skuName": skuNameKey,
                  "subscriptionId": props.actionSelectionData.SubscriptionId
              }
              api.post(MANAGEMENT_CONSOLE_GOOGLE_WORKSPACE_DOWNGRADE_SUBSCRIPTION_CONFIRM_ORDER,confirmOrderData,config).then(resp => {
                  console.log(resp);
                  setLoadingConfirmOrder(false);
                  alert(resp.data.statusMessage);
                  if(resp.data.isSuccess){
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  }
              })
              .catch(error => {
                  console.log(error);
                  setLoadingConfirmOrder(false);
                });
          }   
          else{
            setEditQuantityError(true);
              setTimeout(() => {
                setEditQuantityError(false); 
              }, 3000);
              console.log("Quantity is out of range bond");
              // alert("Error");
          }   
            
            }


  return (
    <div>
      <Container>
        <br/><br/>
      <center><h1>GW Downgrade Subscription</h1></center>
      <br/>
      {
        <>
          <DataTable
              columns={columns}
              data={table}
              theme="solarized"
              customStyles={customStyles}
              striped={true}
              // fixedHeaderScrollHeight="60vh"
              progressPending={loading}
              fixedHeader
              selectableRows
              // selectableRowSelected={rowSelectCritera}
              onSelectedRowsChange={handleChange}
              selectableRowsHighlight
              selectableRowsSingle
          />
          <br />
          <br />
            {!firstNextPage && <Button className='float-right' disabled>Next</Button>}
            {firstNextPage && <Button className='float-right' onClick={()=>{openPurchaseOptionCurrentPlan();openPurchaseOptionNewPlan();}}>Next</Button>}
        </>
      }
                <br/><br/>
                {currentPlantable.length>0 &&
                  <>
                    <center><h1>Current Plan Table</h1></center>
                    <br />
                    <DataTable
                        columns={columnsCurrentPlanTable}
                        data={currentPlantable}
                        theme="solarized"
                        customStyles={customStyles}
                        striped={true}
                        // fixedHeaderScrollHeight="60vh"
                        progressPending={loadingCurrentPlanTable}
                        fixedHeader
                    />
                  </>
                }
                <br/><br />
                {currentPlantable.length>0 &&
                  <>
                    <center><h1>New Plan Table</h1></center>
                    <br />
                    <DataTable
                        columns={columnsNewPlanTable}
                        data={newPlantable}
                        theme="solarized"
                        customStyles={customStyles}
                        striped={true}
                        // fixedHeaderScrollHeight="60vh"
                        progressPending={loadingNewPlanTable}
                        fixedHeader
                        selectableRows
                        onSelectedRowsChange={handleChangeNewPlanTable}
                        selectableRowsHighlight
                        selectableRowsSingle
                    />
                    <br />
                {!secondNextPage && <Button className='float-right' disabled>Next</Button>}
                {secondNextPage && <Button className='float-right' onClick={()=>{console.log("New Values :",minQuantity,maxQuantity,offerSKU);setThirdNextPage(true);}}>Next</Button>}
                  </>
                }

            
            { !loadingReviewOrder && thirdNextPage &&
              <>
                <br/><br/> 
                <center><h3 className='mt-10'>Please verify the below details, enter the P.O Number and confirm order</h3></center><br/>
                <Table responsive striped hover>
                    <thead className='manage-subscription-table-head'>
                        <tr>
                        {ColumnNameReviewOrder.map((td)=>{
                            return(
                                <th className='manage-subscription-table-head-sub'>{td}</th>
                            )
                        })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td className='manage-subscription-table-data'>{reviewOrderProduct}</td>
                            <td className='manage-subscription-table-data'>{reviewOrderPlanName}</td>
                            <td className='manage-subscription-table-data'>
                              <Form.Control
                                type="number"
                                defaultValue={reviewOrderLicence}
                                className='quantity-downgrade-subscription-input-number'
                                onChange={quantityReviewOrderDowngrade}
                              />
                            </td>
                            <td className='manage-subscription-table-data'>{reviewOrderResellerTotalPrice*reviewOrderLicence}</td>
                            <td className='manage-subscription-table-data'>
                              <Form.Control
                                placeholder="P.O Number"
                                defaultValue={reviewOrderPONumber}
                                onChange={(e)=>{setReviewOrderPONumber(e.target.value)}}
                              />
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <br />
                <center><b>{editQuantityError && <p className='edit-quantity-paragraph text-danger'>Number should be between {minQuantity} & {maxQuantity}</p>}</b></center>
                {!editQuantityError && !loadingConfirmOrder && <p className='empty-edit-quantity-paragraph'></p>}
                <center><b>{loadingConfirmOrder && <p className='empty-edit-quantity-paragraph text-warning'>Confirming Ordering for Downgradation of Plan. . .</p> }</b></center>
                {thirdNextPage && !reviewOrderPONumber && <Button className='float-right' disabled>Confirm Order</Button>}
                {thirdNextPage && reviewOrderPONumber && <Button className='float-right' onClick={()=>{confirmOrder();}}>Confirm Order</Button>}
              </>
            }
            { loadingReviewOrder &&
                <center><b><h4>Loading . . .</h4></b></center>
            }
      </Container>
    </div>
  )
}

export default GWDowngradeSubscription
