import React, { useState, useEffect, useCallback } from 'react'
import { Container, Button, ButtonGroup, DropdownButton, MenuItem, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import './styles.scss'
import DataTable, { createTheme } from 'react-data-table-component';
import { api } from "../../../Utils/API";
import { useLocation } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import Form from 'react-bootstrap/Form';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { XLg } from 'react-bootstrap-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import TicketFilterBox from './TicketFilterBox';
import NoData from '../../../Components/EmptyStates/NoData';
import EmptyDataTableFilterSearch from '../../../Assets/Images/EmptyDataTableFilterSearch.svg';
import RaiseTicketIllustration from '../../../Assets/Images/raiseticketillustration.svg'
import { SUPPORT_TICKET_DETAILS, 
    SUPPORT_TICKET_DETAILS_PRIORITIES, 
    SUPPORT_TICKET_DETAILS_SUBJECTS 
} from '../../../Utils/GlobalConstants';


const TicketDetails = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showPanel, setShowPanel] = useState(true);
    const [searchIconFlag, setSearchIconFlag] = useState(false); // Search Icon 
    const [searchValueTable, setSearchValueTable] = useState(null); // Storing searching value in the search input box

    // Filter Flags
    const [filterFlag, setFilterFlag] = useState(false);
    const [showFilterBox, setShowFilterBox] = useState(false); // Showing Global Filter Box
    const [isGlobalLacalChangeAPIFlag, setIsGlobalLacalChangeAPIFlag] = useState(false);


    // Table Pagination PARAMS

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRows, setTotalRows] = useState(0);

    // Rows per page based on the total count
    let perPageCount = []


    const defaultPerPageCount = 5; // Update this number based on per page. Default is 5

    for (let i = defaultPerPageCount; i <= totalRows + defaultPerPageCount; i += defaultPerPageCount) {
        perPageCount.push(i);
    }
    // console.log(perPageCount);

    const [page, setPage] = useState(1);
    const countPerPage = defaultPerPageCount;


    const [accountId, setAccountId] = useState(props.accountIdDefault);

    // CLOUD SERVICE MANAGEMENT TABLE DATA

    const [columns, setColumns] = useState([]);
    const [table, setTable] = useState([]);
    let ColumnName = ["Ticket ID", "Created Date & Time", "Cloud Service", "Plan Name", "Subject", "Priority"];
    const [ticketDetails, setTicketDetails] = useState({
        priority: "",
        ccMembers: "",
        comments: ""
    });
    const [ticketId, setTicketId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    


    // CLOUD SERVICE MANAGEMENT CUSTOMER DETAILS

    const [additionalDetails, setAdditionalDetails] = useState("");

    const [infoLoadingPopup, setInfoLoadingPopup] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);


    const [actionSelectionFlag, setActionSelectionFlag] = useState("");
    const [actionSelectionData, setActionSelectionData] = useState();

    const [subjectFilter, setSubjectFilter] = useState([]);
    const [priorityFilter, setPriorityFilter] = useState([]);

    const [subjectAll, setSubjectAll] = useState(false);
    const [priorityAll, setPriorityAll] = useState(false);

    const [subjectListGlobal, setSubjectListGlobal] = useState([]);
    const [priorityListGlobal, setPriorityListGlobal] = useState([]);

    const [subjectListGlobalFilteredArray, setSubjectListGlobalFilteredArray] = useState(null);
    const [priorityListGlobalFilteredArray, setPriorityListGlobalFilteredArray] = useState(null);

    const [serverErrorTicketDetails, setServerErrorTicketDetails] = useState(false);
    const [emptyTicketDetails, setEmptyTicketDetails] = useState(false);
    const [emptyTicketDetailsFilters, setEmptyTicketDetailsFilters] = useState(false);
    const [emptyTicketDetailsSearch, setEmptyTicketDetailsSearch] = useState(false);

    const [isDesc, setIsDesc] = useState(true);

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // const [sortStartDateFlag, setSortStartDateFlag] = useState("DESC");

    // let SortStartDateFlag = "DESC";

    // Clear Global Function
    async function ClearGolbalFunction() {
        // console.log("Global Functions")
    }

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        sessionStorage.clear();
        localStorage.clear();
        props.setActiveLocalKey('default');
    }

        // UseEffect to run on initial load to navigate unauthorized user to Login Page
        useEffect(() => {
            // console.log("Logged in :",props.customerIsAuthenticated);
            if (props.customerIsAuthenticated === false) {
                navigate("/");
            }
        }, [])


    // Header Authorization for API
    const config = {
        headers: {
            "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
        },
    };


    const GetTableDataDetails = async (ticketId) => {
        setInfoLoadingPopup(true);
        // console.log("Test Data calling Init", ticketId);
        await api.get(SUPPORT_TICKET_DETAILS + ticketId, config)
            .then(resp => {
                // console.log("Test Data", resp.data);
                setAdditionalDetails(resp.data);
                setInfoLoadingPopup(false);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
            }

            );
    };

    useEffect(() => {
        if (ticketId) {
            // console.log("Cliked Ticket Id is", ticketId);
            GetTableDataDetails(ticketId);
        }
    }, [ticketId]);

    async function GetTableData() {
        setLoading(true);

        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${pageNo}&pageSize=${pageSize}`, config)
            .then(resp => {
                if (resp.status == 204) {
                    setEmptyTicketDetails(true);
                    // setEmptyDropdownMyCloudSrvicesflag(true);
                }
                // handle success
                else {
                    setServerErrorTicketDetails(false);
                    setEmptyTicketDetails(false);
                    // console.log("", resp.data.content);
                    // setTotalRows(resp.data.page.totalElements);
                    // console.log(resp.data.page.totalElements);
                    let f = [];
                    setTotalRows(resp.data.totalCount);
                    (resp.data.content).map((td) => {
                        // console.log("asdfasd", td.id);
                        f.push
                            (
                                {
                                    "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                    "Created Date & Time": `${td.createdDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Plan Name": td.planName,
                                    "Subject": td.subject,
                                    "Priority": td.priority,
                                    "Id": td.id,
                                }
                            );
                    })
                    // console.log("Test F", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Created Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                    // setSubjectFilter(resp.data.searchFitlers.billingTerm);
                    // setStatusFilter(resp.data.searchFitlers.status);
                    // setSubscriptionTypeFilter(resp.data.searchFitlers.subscriptionType);

                    // setCloudServiceFilter(["Microsoft CSP","Google Workspace"]);
                    // ["Microsoft CSP","Google Workspace"].map((item) => 
                    // {
                    //     setCloudServiceListGlobal((prev) => ({
                    //         cloudServiceGlobal: {
                    //         ...prev.cloudServiceGlobal, [item]: false
                    //         }
                    //     }));
                    // });
                    // console.log("Reached");
                    setLoading(false);
                    // console.log(d);
                }
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    setServerErrorTicketDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };

    const FilterPriorityData = async (ticketId) => {
        setInfoLoadingPopup(true);
        // console.log("Test Data calling Init", ticketId);
        await api.get(SUPPORT_TICKET_DETAILS_PRIORITIES, config)
            .then(resp => {
                // console.log("Test Data", resp.data);
                setPriorityFilter(resp.data);
                resp.data.map((item) => {
                    setPriorityListGlobal((prev) => ({
                        priorityGlobal: {
                            ...prev.priorityGlobal, [item]: false
                        }
                    }));
                });

            });
    };

    const SubjectPriorityData = async (ticketId) => {
        setInfoLoadingPopup(true);
        // console.log("Test Data calling Init", ticketId);
        await api.get(SUPPORT_TICKET_DETAILS_SUBJECTS, config)
            .then(resp => {
                // console.log("Test Data", resp.data);
                setSubjectFilter(resp.data);
                resp.data.map((item) => {
                    setSubjectListGlobal((prev) => ({
                        subjectGlobal: {
                            ...prev.subjectGlobal, [item]: false
                        }
                    }));
                });
            });
    };

    useEffect(() => {
        if (subjectListGlobal) {
            let local = subjectListGlobal;
            let localSubjectBoolean = [];
            // console.log("Subject List Global : ", subjectListGlobal);
            if (local.subjectGlobal) {
                // console.log("Billing Term List Global : ", local)
                subjectFilter.map((item) => {
                    // console.log(item);
                    if (subjectListGlobal.subjectGlobal[item] === true) {
                        localSubjectBoolean.push(true);
                    }
                    else {
                        localSubjectBoolean.push(false);
                    }
                })
                // console.log("Local :", localSubjectBoolean);
                if (localSubjectBoolean.includes(false)) {
                    setSubjectAll(false);
                }
                else {
                    setSubjectAll(true);
                }
            }
        }
    }, [subjectListGlobal])

    useEffect(() => {
        if (priorityListGlobal) {
            let local = priorityListGlobal;
            let localPriorityBoolean = [];
            // console.log("Priority List Global : ", priorityListGlobal);
            if (local.priorityGlobal) {
                // console.log("Billing Term List Global : ", local)
                priorityFilter.map((item) => {
                    // console.log(item);
                    if (priorityListGlobal.priorityGlobal[item] === true) {
                        localPriorityBoolean.push(true);
                    }
                    else {
                        localPriorityBoolean.push(false);
                    }
                })
                // console.log("Local :", localPriorityBoolean);
                if (localPriorityBoolean.includes(false)) {
                    setPriorityAll(false);
                }
                else {
                    setPriorityAll(true);
                }
            }
        }
    }, [priorityListGlobal])

    useEffect(() => {
        // console.log("Page is called");
        // GetTableData();
        FilterPriorityData();
        SubjectPriorityData();
    }, []);

    useEffect(() => {
        if (searchValueTable != null) {
            const getData = setTimeout(() => {
                GetTableDataBySearch();
            }, 800);
            return () => clearTimeout(getData);
        }
    }, [searchValueTable]);

    useEffect(() => {
        if (table.length > 0 && showFilterBox === false && priorityListGlobalFilteredArray === null && subjectListGlobalFilteredArray === null) {
            FilterPriorityData();
            SubjectPriorityData();
            setSubjectAll(false);
            setPriorityAll(false);
        }
    }, [showFilterBox])


    async function GetTableUpdatedData(page, newPerPage) {
        setLoading(true);
        // console.log("starteddddddddddddd");
        let prioritySub;
        if (priorityListGlobalFilteredArray === null || priorityListGlobalFilteredArray === undefined) {
            prioritySub = ""
        }
        else {
            prioritySub = priorityListGlobalFilteredArray;
        }
        let subjectSub;
        if (subjectListGlobalFilteredArray === null || subjectListGlobalFilteredArray === undefined) {
            subjectSub = ""
        }
        else {
            subjectSub = subjectListGlobalFilteredArray
        }
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }

        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${page}&pageSize=${newPerPage}&ticketId=${searchValueTableSub}&priorities=${prioritySub}&subjects=${subjectSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setServerErrorTicketDetails(false);
                // handle success
                // console.log("", resp.data.content);
                // setTotalRows(resp.data.page.totalElements);
                // console.log(resp.data.page.totalElements);
                let f = [];
                setTotalRows(resp.data.totalCount);
                (resp.data.content).map((td) => {
                    // console.log("asdfasd", td.id)
                    f.push
                        (
                            {
                                "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                "Created Date & Time": `${td.createdDate}`,
                                "Cloud Service": td.serviceName,
                                "Plan Name": td.planName,
                                "Subject": td.subject,
                                "Priority": td.priority,
                                "Id": td.id,
                            }
                        );
                })
                // console.log("Test F", f);
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 50);
                let d = [];
                ColumnName.map((td) => {
                    if (td === "Created Date & Time") {
                        d.push({
                            id: `${td}`,
                            name: <div>
                                {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                            </div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }

                })
                setColumns(d);     // Setting Column Data
                setLoading(false);
                // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    setServerErrorTicketDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };

    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        // fetchUsers(page);
        // console.log("Page Changed");
        // console.log("Current Page : ", pageNo);
        GetTableUpdatedData(pageNo, pageSize);
        setPageNo(pageNo);
    };


    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        // console.log("Number of rows changed");
        // console.log("New Per Page", newPerPage);
        // console.log("Current Page", page);
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableUpdatedData(page, newPerPage);
    };


    async function GetGlobalFilterData(priority, subject) {
        let prioritySub;
        if (priority === null || priority === undefined) {
            prioritySub = ""
        }
        else {
            prioritySub = priority;
        }
        let subjectSub;
        if (subject === null || subject === undefined) {
            subjectSub = ""
        }
        else {
            subjectSub = subject
        }
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${1}&pageSize=${pageSize}&ticketId=${searchValueTableSub}&priorities=${prioritySub}&subjects=${subjectSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setServerErrorTicketDetails(false);
                if (resp.status == 204) {
                    setEmptyTicketDetailsFilters(true);
                    setServerErrorTicketDetails(false);
                    setFilterFlag(true);
                    setShowFilterBox(!showFilterBox);
                    // setEmptyDropdownMyCloudSrvicesflag(true);
                }
                else {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setEmptyTicketDetailsFilters(false);
                    setTotalRows(resp.data.totalCount);
                    setPageNo(1);
                    // handle success
                    // console.log("Search Data", resp.data.content);
                    // setTotalRows(resp.data.page.totalElements);
                    // console.log(resp.data.page.totalElements);
                    let f = [];

                    (resp.data.content).map((td) => {
                        // console.log("Ticket ID", td.ticketId)
                        f.push
                            (
                                {
                                    "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                    "Created Date & Time": `${td.createdDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Plan Name": td.planName,
                                    "Subject": td.subject,
                                    "Priority": td.priority,
                                    "Id": td.id,
                                }
                            );
                    })
                    // console.log("Test F Search", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Created Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                    setLoading(false);
                    setFilterFlag(true);
                    setShowFilterBox(!showFilterBox);
                    // console.log("Done Filtering")
                    // console.log(d);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                    setServerErrorTicketDetails(true);
                    setLoading(false);
                }

            });
    };

    async function GetClearFilterData(priority, subject) {
        let prioritySub;
        if (priority === null || priority === undefined) {
            prioritySub = ""
        }
        else {
            prioritySub = priority;
        }
        let subjectSub;
        if (subject === null || subject === undefined) {
            subjectSub = ""
        }
        else {
            subjectSub = subject
        }
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        FilterPriorityData();
        SubjectPriorityData();
        setSubjectAll(false);
        setPriorityAll(false);
        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${1}&pageSize=${pageSize}&ticketId=${searchValueTableSub}&priorities=${prioritySub}&subjects=${subjectSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setResetPaginationToggle(!resetPaginationToggle);
                setEmptyTicketDetailsFilters(false);
                setServerErrorTicketDetails(false);
                setFilterFlag(false);
                setTotalRows(resp.data.totalCount);
                setPageNo(1);
                // handle success
                // console.log("Search Data", resp.data.content);
                // setTotalRows(resp.data.page.totalElements);
                // console.log(resp.data.page.totalElements);
                let f = [];

                (resp.data.content).map((td) => {
                    // console.log("Ticket ID", td.ticketId)
                    f.push
                        (
                            {
                                "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                "Created Date & Time": `${td.createdDate}`,
                                "Cloud Service": td.serviceName,
                                "Plan Name": td.planName,
                                "Subject": td.subject,
                                "Priority": td.priority,
                                "Id": td.id,
                            }
                        );
                })
                // console.log("Test F Search", f);
                setTimeout(() => {
                    setTable(f);         // Setting Table Data
                }, 50);
                let d = [];
                ColumnName.map((td) => {
                    if (td === "Created Date & Time") {
                        d.push({
                            id: `${td}`,
                            name: <div>
                                {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                            </div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            // sortable: true,
                            // allowOverflow: true,
                        })
                    }

                })
                setColumns(d);     // Setting Column Data
                setLoading(false);
                // console.log("Done Filtering")
                // console.log(d);
            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    setServerErrorTicketDetails(true);
                    // console.log(error);
                    setLoading(false);
                }

            });
    };


    // Table Data by Search

    async function GetTableDataBySearch() {
        let prioritySub;
        if (priorityListGlobalFilteredArray === null || priorityListGlobalFilteredArray === undefined) {
            prioritySub = ""
        }
        else {
            prioritySub = priorityListGlobalFilteredArray;
        }
        let subjectSub;
        if (subjectListGlobalFilteredArray === null || subjectListGlobalFilteredArray === undefined) {
            subjectSub = ""
        }
        else {
            subjectSub = subjectListGlobalFilteredArray
        }
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        setLoading(true);
        // console.log("Search Priority : ", priorityListGlobalFilteredArray);
        // console.log("Search Subject : ", subjectListGlobalFilteredArray);
        // console.log("Search Value : ", searchValueTable);

        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${1}&pageSize=${pageSize}&ticketId=${searchValueTableSub}&priorities=${prioritySub}&subjects=${subjectSub}&isDesc=${isDesc}`, config)
            .then(resp => {
                setServerErrorTicketDetails(false);
                if (resp.status == 204) {
                    setEmptyTicketDetailsSearch(true);
                    // setEmptyDropdownMyCloudSrvicesflag(true);
                }
                else {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setEmptyTicketDetailsSearch(false);
                    setTotalRows(resp.data.totalCount);
                    setPageNo(1);
                    // handle success
                    // console.log("Search Data", resp.data.content);
                    // setTotalRows(resp.data.page.totalElements);
                    // console.log(resp.data.page.totalElements);
                    let f = [];

                    (resp.data.content).map((td) => {
                        // console.log("Ticket ID", td.ticketId)
                        f.push
                            (
                                {
                                    "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                    "Created Date & Time": `${td.createdDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Plan Name": td.planName,
                                    "Subject": td.subject,
                                    "Priority": td.priority,
                                    "Id": td.id,
                                }
                            );
                    })
                    // console.log("Test F Search", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Created Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                    setLoading(false);
                    // console.log(d);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    // console.log(error);
                    setServerErrorTicketDetails(true);
                    setLoading(false);
                }

            });
    };

    // Sort Date Column in Subscription Table

    async function SortStartDate() {
        setInitialLoading(true);
        let prioritySub;
        if (priorityListGlobalFilteredArray === null || priorityListGlobalFilteredArray === undefined) {
            prioritySub = ""
        }
        else {
            prioritySub = priorityListGlobalFilteredArray;
        }
        let subjectSub;
        if (subjectListGlobalFilteredArray === null || subjectListGlobalFilteredArray === undefined) {
            subjectSub = ""
        }
        else {
            subjectSub = subjectListGlobalFilteredArray
        }
        let searchValueTableSub;
        if (searchValueTable === null || searchValueTable === undefined) {
            searchValueTableSub = ""
        }
        else {
            searchValueTableSub = searchValueTable
        }
        // console.log("Search Priority : ", priorityListGlobalFilteredArray);
        // console.log("Search Subject : ", subjectListGlobalFilteredArray);
        // console.log("Search Value : ", searchValueTable);

        await api.get(`${SUPPORT_TICKET_DETAILS}?pageNo=${1}&pageSize=${pageSize}&ticketId=${searchValueTableSub}&priorities=${prioritySub}&subjects=${subjectSub}&isDesc=${isDesc}`, config)
            .then(resp => {

                if (resp.status == 204) {
                    setEmptyTicketDetails(true);
                    setInitialLoading(true);
                    // setEmptyDropdownMyCloudSrvicesflag(true);
                }
                // handle success
                else {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setServerErrorTicketDetails(false);
                    setEmptyTicketDetails(false);
                    setTotalRows(resp.data.totalCount);
                    setPageNo(1);
                    // handle success
                    // console.log("Search Data", resp.data.content);
                    // setTotalRows(resp.data.page.totalElements);
                    // console.log(resp.data.page.totalElements);
                    let f = [];

                    (resp.data.content).map((td) => {
                        // console.log("Ticket ID", td.ticketId)
                        f.push
                            (
                                {
                                    "Ticket ID": <span className="ticketid-color-underline" onClick={() => { setTicketId(td.id); setModalOpen(true); }} >{td.ticketId}</span>, //"PICT00000235",
                                    "Created Date & Time": `${td.createdDate}`,
                                    "Cloud Service": td.serviceName,
                                    "Plan Name": td.planName,
                                    "Subject": td.subject,
                                    "Priority": td.priority,
                                    "Id": td.id,
                                }
                            );
                    })
                    // console.log("Test F Search", f);
                    setTimeout(() => {
                        setTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnName.map((td) => {
                        if (td === "Created Date & Time") {
                            d.push({
                                id: `${td}`,
                                name: <div>
                                    {isDesc === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(true) }}></span></>}
                                    {isDesc === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { setIsDesc(false) }}></span></>}
                                </div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                // sortable: true,
                                // allowOverflow: true,
                            })
                        }

                    })
                    setColumns(d);     // Setting Column Data
                    setInitialLoading(false);
                    setLoading(false);
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                else {
                    setServerErrorTicketDetails(true);
                    // console.log(error);
                    setInitialLoading(true);
                    setLoading(false);
                }

            });

    };

    useEffect(() => {
        SortStartDate();
    }, [isDesc]);

    // CSS for Customer Cloud Service Management Data Table

    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    const customStyles = {
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                textAlign: 'left',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '10px', // override the cell padding for head cells
                paddingRight: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                textAlign: 'left',
            },
        },
    };

    return (
        <>
            <div className="customer-management-console-main-div mb-5">
                <h1 className="d-block d-md-none mobile-h1">Support Tickets</h1>
                <div className="mgmt-console-table-row">
                    <div className="my-3 d-flex">
                        <Popup
                            open={modalOpen}
                            onClose={() => { setModalOpen(false); }}
                            className="custom-modal custom-modal-md custom-modal-ticket-details"
                            modal
                            nested
                        >
                            {(close) => (
                                <div className="modal">
                                    <div className="header">
                                        <h2>Ticket Details</h2>
                                        <XLg
                                            size={24}
                                            className="cpointer close-icon-popup"
                                            onClick={close}
                                        />
                                    </div>
                                    <Container>
                                        {
                                            infoLoadingPopup &&
                                            <div className='ticket-table-additional-information-popup-loading'>
                                                <p>Loading . . .</p>
                                            </div>
                                        }
                                        {!infoLoadingPopup && (additionalDetails) && <Row>
                                            {additionalDetails.ticketId && <Col md={6} className="mb-4">
                                                <label className="text-medium">Ticket ID</label>
                                                <p><strong>{additionalDetails.ticketId}</strong></p>
                                            </Col>}
                                            {additionalDetails.createdDate && <Col md={6} className="mb-4">
                                                <label className="text-medium">Created Date</label>
                                                <p><strong>{additionalDetails.createdDate}</strong></p>
                                            </Col>}
                                            {additionalDetails.serviceName && <Col md={6} className="mb-4">
                                                <label className="text-medium">Cloud Service</label>
                                                <p><strong>{additionalDetails.serviceName}</strong></p>
                                            </Col>}
                                            {additionalDetails.planName && <Col md={6} className="mb-4">
                                                <label className="text-medium">Plan Name</label>
                                                <p><strong>{additionalDetails.planName}</strong></p>

                                            </Col>}
                                            {additionalDetails.subject && <Col md={6} className="mb-4">
                                                <label className="text-medium">Subject</label>
                                                <p><strong>{additionalDetails.subject}</strong></p>
                                            </Col>}
                                            {additionalDetails.priority && <Col md={6} className="mb-4">
                                                <label className="text-medium">Priority</label>
                                                <p><strong>{additionalDetails.priority}</strong></p>
                                            </Col>}
                                            {additionalDetails.comments && <Col md={12} className="mb-4">
                                                <label className="text-medium">Message</label>
                                                <p className="ticket-message"><strong>{additionalDetails.comments}</strong></p>
                                            </Col>}
                                            {additionalDetails.ccMembers && <Col xs={12} className="mb-4">
                                                <label className="text-medium">Members Added</label>
                                                <p><strong>{additionalDetails.ccMembers}</strong></p>
                                            </Col>}
                                        </Row>}
                                    </Container>
                                </div>
                            )}
                        </Popup>
                        <Col className="subscription-table py-0 ticket-details-table">
                            <div className="subscription-table-inner table-details">
                                <Row className="my-0 table-heading align-items-center">
                                    <Col className={`pl-1 ${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                        <h5 className="py-3 mb-0 mobile-padding-search-open subscription-heading-table-header">Tickets</h5>
                                    </Col>
                                    {!initialLoading && <Col className="px-1">
                                        <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                            {!serverErrorTicketDetails && !emptyTicketDetails && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">
                                                {!searchIconFlag &&
                                                    <span className="mx-3">&nbsp;</span>
                                                }
                                                {!searchIconFlag && !emptyTicketDetailsFilters &&
                                                    <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                }
                                                {searchIconFlag && !emptyTicketDetailsFilters &&
                                                    <InputGroup className="search-input-box">
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                            <span className="searchgrey cpointer" onClick={() => { GetTableDataBySearch() }}>
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Enter Ticket ID"
                                                            aria-label="Enter Ticket ID"
                                                            aria-describedby="basic-addon2"
                                                            className="search-input-box"
                                                            value={searchValueTable}
                                                            onChange={(e) => setSearchValueTable(e.target.value)}
                                                        />
                                                        <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                            onClick={() => {
                                                                setSearchValueTable("");
                                                                setSearchIconFlag(!searchIconFlag);
                                                            }}>
                                                            <span className="closebtn">
                                                                &nbsp;
                                                            </span>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                }

                                                {!filterFlag && !emptyTicketDetailsSearch && <span className="filter-black cpointer" id="filterblock" onClick={() => {
                                                    setShowFilterBox(!showFilterBox);
                                                    if (showFilterBox) { setIsGlobalLacalChangeAPIFlag(true); ClearGolbalFunction(); }
                                                }}></span>}
                                                {filterFlag && !emptyTicketDetailsSearch && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                <Button className='ml-5 px-3 d-none d-md-inline-block create-ticket-btn' onClick={() => navigate("/create-support-ticket", { state: { accountId: "", subId: "", planName: "", isGlobal: true } })}>Create Ticket</Button>
                                            </Col>}
                                        </Row>
                                    </Col>}
                                </Row>
                                {showFilterBox &&
                                    <TicketFilterBox
                                        subjectFilter={subjectFilter} subjectListGlobal={subjectListGlobal} setSubjectListGlobal={setSubjectListGlobal} setSubjectAll={setSubjectAll} subjectAll={subjectAll}
                                        priorityAll={priorityAll} setPriorityListGlobal={setPriorityListGlobal} priorityFilter={priorityFilter} setPriorityAll={setPriorityAll} priorityListGlobal={priorityListGlobal}
                                        setSubjectListGlobalFilteredArray={setSubjectListGlobalFilteredArray} setPriorityListGlobalFilteredArray={setPriorityListGlobalFilteredArray} GetGlobalFilterData={GetGlobalFilterData}
                                        GetClearFilterData={GetClearFilterData} setShowFilterBox={setShowFilterBox} />
                                }

                                <Container fluid className="mb-5 p-0">
                                    <Row>
                                        <Col>
                                            <div className="table-responsive support-ticket-history-table">
                                                {serverErrorTicketDetails &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                    </div>
                                                }
                                                {emptyTicketDetailsSearch &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Ticket Found.</h2>
                                                    </div>
                                                }
                                                {emptyTicketDetailsFilters &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={EmptyDataTableFilterSearch} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                    </div>
                                                }
                                                {emptyTicketDetails &&
                                                    <div className='no-data-support-tickets'>
                                                        <img src={RaiseTicketIllustration} className="empty-subscription-table-svg" />
                                                        <h2 className="mt-4 mb-3">No Support Tickets Found.</h2>
                                                        <p className="mb-3">Please create a new support ticket for Support Ticket History.</p>
                                                        <Button
                                                            className="btn btn-lg btn-primary px-3"
                                                            onClick={() => {
                                                                props.setActiveLocalKey("ticketDetails");
                                                                navigate("/create-support-ticket", {
                                                                    state: { accountId: "", subId: "", planName: "", isGlobal: true }
                                                                }
                                                                );
                                                            }}
                                                        >Create Ticket</Button>
                                                    </div>
                                                }
                                                {!serverErrorTicketDetails && !emptyTicketDetailsFilters && !emptyTicketDetails && !emptyTicketDetailsSearch && <DataTable
                                                    columns={columns}
                                                    data={table}
                                                    theme="solarized"
                                                    customStyles={customStyles}
                                                    // striped={true}
                                                    persistTableHead
                                                    noDataComponent={<div className='loading-support-tickets'><p><b>Loading your Support Tickets . . .</b></p></div>}
                                                    width="100%"
                                                    fixedHeaderScrollHeight="60vh"
                                                    // progressPending={loading}
                                                    fixedHeader
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={pageSize}
                                                    paginationRowsPerPageOptions={[5,10,20,50,100]}
                                                    onChangePage={(pageNo) => handlePageChange(pageNo, totalRows)}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    paginationResetDefaultPage={resetPaginationToggle}
                                                />}
                                            </div>
                                        </Col>
                                        {!initialLoading && !serverErrorTicketDetails && !emptyTicketDetailsFilters && !emptyTicketDetails && !emptyTicketDetailsSearch && <Button className='ml-5 px-3 d-md-none d-inline-block create-ticket-btn' onClick={() => navigate("/create-support-ticket", { state: { accountId: "", subId: "", planName: "", isGlobal: true } })}>Create Ticket</Button>}
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketDetails
