import { Container, Row, Col } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { LANDING_PAGE_CMS_DATA } from "../../Utils/GlobalConstants";
import { cmsApi } from "../../Utils/API";
import CmsDetailsContext from "../../Context/CmsDetails/CmsDetailsContext";

const Footer = (props) => {

  // UseStates Defination
  const [footerLinks, setFooterLinks] = useState();
  const [socialMediaLinks, setSocialMediaLinks] = useState();
  const globalData = useContext(CmsDetailsContext)

  // Function for calling Footer item data from Orchid Core CMS
  async function GetLandingPageData() {
    // console.log("Footer Data Fetching Initiated");
    const data = `{
            footerContent {
              footerLinks {
                contentItems {
                  ... on ExternalItemLink {
                    displayText
                    linkUrl
                  }
                }
              }
              socialMedia {
                contentItems {
                  ... on SocialMediaLink {
                    displayText
                    socialMediaLogo {
                      urls
                      paths
                    }
                    socialMediaUrl
                  }
                }
              }
            }
          }`;

    // Defining Header Configuration
    const config = {
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/graphql",
      },
    };

    // Axios Post Request
    await cmsApi
      .post(LANDING_PAGE_CMS_DATA, data, config)
      .then((resp) => {
        // handle success
        // console.log(resp.data.data.footerContent[0]);
        setFooterLinks(
          resp.data.data.footerContent[0].footerLinks.contentItems
        );
        setSocialMediaLinks(
          resp.data.data.footerContent[0].socialMedia.contentItems
        );
      })
      .catch((error) => {
        // handle error
        // console.log(error);
      });
  }

  // UseEffect Defination to call the get footer items function from CMS
  useEffect(() => {
    GetLandingPageData();
  }, []);

  return (
    <>
      <Container fluid className={`footer ${props.customClass}`}>
        <Container fluid>
          <Row>
            <Col md={7} xs={8}>      {/* Getting Footer First section ( Footer Links ) links */}
              {footerLinks && (
                <ul className="footer-links grid-container">
                  {footerLinks.slice(0, 6).map((td) => {
                    return (
                      <li key={td.linkUrl}>
                        <a href={td.linkUrl} target="_blank">
                          {td.displayText}
                        </a>
                      </li>
                    );
                  })}
                  {/* <li><label onClick={() => { navigate("/contact-us/"); }}>Contact Us</label></li> */}
                </ul>
              )}
            </Col>
            <Col md={5} xs={4}>       {/* Getting Footer Second (Social Media) section links */}
              {socialMediaLinks && globalData && (
                <div className="social-links float-right">
                  <label>Follow Us</label>
                  <div className="social-icons-row">
                    {socialMediaLinks.slice(0, 5).map((td) => {
                      return (
                        <a
                          key={td.socialMediaUrl}
                          href={td.socialMediaUrl}
                          rel="noreferrer"
                          target="_blank"
                          className="social-icon"
                        >
                          <img
                            src={globalData.baseUrl + td.socialMediaLogo.urls}
                            alt={td.displayText}
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <Container fluid className="footer d-flex d-md-none">
          <Col className="w-100">
            <p className="text-center mb-0">
              <span className="lock">&nbsp;</span> synnex.au
            </p>
          </Col>
      </Container> */}
    </>
  );
};

export default Footer;