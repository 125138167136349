import React, { useState, useEffect, useContext } from 'react'
import CmsDetailsContext from "../../../Context/CmsDetails/CmsDetailsContext";
import './styles.scss';
import { Container, Button, Row, Col, Alert, Form, OverlayTrigger, Tooltip, NavDropdown } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { api } from "../../../Utils/API";
import { SUPPORT_TICKET_GET_ALL_SUBSCRIPTION, MANAGEMENT_CONSOLE_SUPPORT_TICKET, MANAGEMENT_CONSOLE_ACCOUNTS_URL, MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT } from "../../../Utils/GlobalConstants";
import { Check2Circle, XCircle, XLg } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import TermsAndConditions from '../TermsAndConditions';

const RaiseATicket = (props) => {
    const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;  // Email Regular Expression
    const globalData = useContext(CmsDetailsContext); // Use Context Global Data

    const navigate = useNavigate();
    let raiseAticketSubject = [
        "Upgrade Subscription",
        "Convert Trial to Paid",
        "Downgrade Subscription",
        "Reactivate Subscription",
        "Suspend Subscription",
        "Cancel Subscription",
        "Purchase Add-Ons Request",
        "Other"
    ];
    let ticketPriority = ['High', 'Medium', 'Low'];
    const addMemeberField = useRef(""); // Add Member Field Value
    const location = useLocation(); // React Router UseState Passed initialization
    // const [accountId, setAccountId] = useState(location.state !== null ? location.state.accountId : "");
    const [subId, setSubId] = useState(location.state !== null ? location.state.subId : "");  // Subscription Id
    const [planName, setPlanName] = useState(location.state !== null ? location.state.planName : "");  // Plan Name preselected if any
    const [isGlobal, setIsGlobal] = useState(location.state !== null ? location.state.isGlobal : "");  // Local vs Global Click
    const [cloudServices, setCloudServices] = useState(location.state !== null ? location.state.cloudServices : ""); // Cloud Services preselected if any
    const [dropdownData, setDropdownData] = useState([]);  // Plan Name Dropdown Data
    const [dropdownDataCloudServices, setDropdownDataCloudServices] = useState([]); // Cloud Services Dropdown Data
    const [accountIdPlanNameAPI, setAccountIdPlanNameAPI] = useState(location.state !== null ? location.state.accountId : ""); // Account Id of preselected Value
    const [showTermsAndConditionFlag, setShowTermsAndConditionFlag] = useState(); // Terms and condition Popup Check

    // Support ticket Use States for Input Values
    const [selectCloudService, setSelectCloudService] = useState("Select Cloud Service");
    const [selectCloudPlanName, setSelectCloudPlanName] = useState("Select Plan Name");
    const [selectCloudSubject, setSelectCloudSubject] = useState("Select Subject");
    const [selectCloudPriority, setSelectCloudPriority] = useState("Select Priority");
    const [subject, setSubject] = useState("");
    const [otherSubject, setOtherSubject] = useState("");
    const [comments, setComments] = useState("");
    const [priority, setPriority] = useState("");
    // Members Name UseStates
    const [memberName, setMemberName] = useState("")
    const [members, setMembers] = useState([]);
    const [maxErrorMemberList, setMaxErrorMemberList] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    const [ticketError, setTicketError] = useState(false);

    // Header Configuration for API Call with Authorization Token
    const config = {
        headers: {
            "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
        },
    };

    // Logout Function on error 410
    function Logout() {
        props.setCustomerIsAuthenticated(false);
        if (props.customerIsAuthenticated) { navigate("/") };
        sessionStorage.clear();
        localStorage.clear();
        props.setActiveLocalKey('default');
    }

    // UseEffect to run on initial load to navigate unauthorized user to Login Page
    useEffect(() => {
        // console.log("Logged in :",props.customerIsAuthenticated);
        if (props.customerIsAuthenticated === false) {
            navigate("/");
        }
    }, [])

    // Function to show overlay when hover on i icon in add member
    const renderTooltip = (props) => (
        <Tooltip {...props}>
            {props.infoText}
        </Tooltip>
    );

    // Get Cloud Services Data
    async function GetDataCloudServices() {
        // console.log("started");
        await api.get(MANAGEMENT_CONSOLE_ACCOUNTS_URL, config).then(resp => {
            // console.log("Raise a Ticket Dropdown Plans", resp.data);
            setDropdownDataCloudServices(resp.data);
        })
            .catch(error => {
                // handle error
                console.log(error);
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
            });
    };

    // Get Plan Names Dropdown Data
    async function GetData() {
        if (accountIdPlanNameAPI) {
            // console.log("started");
            await api.get(`${SUPPORT_TICKET_GET_ALL_SUBSCRIPTION}${accountIdPlanNameAPI}`, config).then(resp => {
                // console.log("Raise a Ticket Dropdown Plans", resp.data);
                resp.data.forEach((item) => {
                    if (item.accountId == accountIdPlanNameAPI) {
                        setDropdownData((prev) => [...prev, item]);
                    }
                })

            })
                .catch(error => {
                    // handle error
                    // console.log(error);
                });
        };
    }

    // Final Raise a Ticket API Function
    const raiseATicket = async () => {
        let ccMember;
        members.map(member => {
            if (ccMember) {
                ccMember = ccMember + ';' + member;
            } else {
                ccMember = member;
            }

        })
        // console.log("CC Members", ccMember);
        const data = {
            "subscriptionId": planName !== "" ? subId : null,
            // "planName": planName,
            "subject": subject,
            "otherSubject": otherSubject,
            "comments": comments,
            "priority": priority,
            "ccMembers": ccMember,
            "cloudAccountId": accountIdPlanNameAPI !== "" ? accountIdPlanNameAPI : null,
        }

        // console.log("Details", data);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`,
            }
        };

        await api.post(MANAGEMENT_CONSOLE_SUPPORT_TICKET, data, config)
            .then(resp => {
                // console.log(resp);
                setTicketSuccess(true);
                resetForm();
                setTimeout(() => {
                    setTicketSuccess(false);
                    navigate("/support-tickets");
                }, 3000);
            })
            .catch(error => {
                // console.log(error.message);
                setTicketError(true);
                resetForm();
                setTimeout(() => {
                    setTicketError(false);
                }, 3000);
            });
    }

    const getCloudServicesDetails = (e) => {
        setCloudServices(e.target.value);
        setSelectCloudService(e.target.getAttribute("value"));
        setAccountIdPlanNameAPI(e.target.getAttribute("id"));
        setSelectCloudPlanName("Select Plan Name");
    }

    const getSubscriptionDetails = (e) => {
        // console.log("Plan Name", planName);
        setSubId(e.target.getAttribute("id"));
        // setAccountId(e.target.value.split(",")[0]);
        // setPlanName(e.target.value.split(",")[1]);
        setSelectCloudPlanName(e.target.getAttribute("value"));
        setPlanName(e.target.getAttribute("value"));
        // console.log("Plan Name", e.target.getAttribute("value"));
        // console.log(e.target.value.split(",")[0])
    }

    // Subject On Change Function
    const subjectChange = (e) => {
        let newSubject = e.target.getAttribute("value");
        setSelectCloudSubject(newSubject);
        setSubject(newSubject);
        // console.log(newSubject, subject);
    }

    // Priority On Change Function
    const priorityChange = (e) => {
        let newPriority = e.target.getAttribute("value");
        setSelectCloudPriority(newPriority);
        setPriority(newPriority);
        // console.log(newPriority, subject);
    }

    // Member on Change Function to check whether correct email used
    const createMember = (e) => {
        if (emailRegEx.test(e.target.value)) {
            setMemberName(e.target.value)
        } else {
            setMemberName("");
        }
    }

    // Add Member to member list
    const addMember = (e) => {
        if (memberName.length > 0 && (e.key === 'Enter')) {
            if (members.length < 4) {
                setMembers((prevState) => ([...prevState, memberName]));
                setMemberName('');
                e.target.value = "";
            }
            else {
                setMaxErrorMemberList(true);
            }
        }
    };

    // Delete Member from member list
    const MemberListDelete = (e) => {
        // console.log(e.target.id);
        const memberNewDeletedArray = members.filter(
            (item) => item !== (e.target.id)
        );
        setMembers(memberNewDeletedArray);
    };

    // Reset Raise a ticket Values
    const resetForm = () => {
        setSelectCloudService("Select Cloud Service");
        setAccountIdPlanNameAPI(null);
        setSelectCloudPlanName("Select Plan Name");
        setSubject("");
        setSelectCloudSubject("Select Subject");
        setOtherSubject("");
        setComments("");
        setPriority("");
        setSelectCloudPriority("Select Priority");
        setMembers([]);
        if (isGlobal) {
            setPlanName("");
            setCloudServices("");
        }
        addMemeberField.current.value = "";
    }

      // Function to add data in View Cloud Portal Details Popup
        async function GetSubscriptionDetails() {
            // console.log("View Cloud portal details api initiated");
            await api
                .get(MANAGEMENT_CONSOLE_CLOUD_SERVICE_MANAGEMENT + "details/" + subId, config)
                .then((resp) => {
                setAccountIdPlanNameAPI(resp.data.accountId);
                })
                .catch((error) => {
                // handle error
                if (error.response.status == 401) {
                    // console.log("Error 401");
                    Logout();
                }
                });
        }

    // Function to check to show terms and condition or not
    useEffect(() => {
        if (globalData) {
            setShowTermsAndConditionFlag(globalData.termsConditionsVersion);
        }
    }, [globalData]);

    // Get Cloud Services Data on Initial Load
    useEffect(() => {
        if (props.customerIsAuthenticated) {
            GetDataCloudServices();
        }
    }, []
    );

    // Get Plan Names under a Cloud services on choosing one
    useEffect(() => {
        setDropdownData([]);
        GetData();
    }, [accountIdPlanNameAPI]
    );

    useEffect(() => {
    if(accountIdPlanNameAPI==="1"){
        GetSubscriptionDetails();
    }
    }, [accountIdPlanNameAPI]);

    useEffect(() => {
        let ddValue = document.getElementById("cloud-service-dropdown").innerHTML;
        console.log(ddValue);
    }, [])

    return (
        <>
            {(showTermsAndConditionFlag != sessionStorage.getItem("Accepted_Version")) && <TermsAndConditions />}

            {/* Toast Declarations */}
            <div className={`raise-ticket-alert ${ticketSuccess ? "active" : ""}`}>
                <Alert variant="success">
                    <div className='d-flex align-items-center'>
                        <Check2Circle size={24} color="#1D874C" />
                        <span className="mx-3 text-medium">
                            You have raised the ticket successfully.
                        </span>
                    </div>
                </Alert>
            </div>
            <div className={`raise-ticket-alert ${ticketError ? "active" : ""}`}>
                <Alert variant="danger">
                    <div className='d-flex align-items-center'>
                        <XCircle size={24} color="#9C3B35" />
                        <span className="mx-3 text-medium">
                            There is an error in creating ticket. Please try creating it again.
                        </span>
                    </div>
                </Alert>
            </div>

            <Container fluid className="main-content">
                <div className="raise-ticket">
                    <div className="raiseticketheading">
                        <h1 className='mb-2'>
                            Support Tickets
                        </h1>
                        <div className="inner-block px-5 py-3 mb-5 mt-5 sub-pages-block-inner">
                            <Form onSubmit={e => { e.preventDefault(); }}>
                                <Col lg={8}>
                                    <Row>
                                        <Col md={6}>
                                            {!dropdownDataCloudServices && isGlobal &&
                                                <Form.Group
                                                    className="form-group w-100"
                                                >
                                                    <Form.Label>Cloud Services</Form.Label>

                                                    <NavDropdown title="No Cloud Services Available" id="sevices-nav-dropdown"
                                                        className="custom-dropdown">
                                                        <NavDropdown.Item className="w-auto">
                                                            No Cloud Services Available
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </Form.Group>
                                            }
                                            {dropdownData && dropdownDataCloudServices && isGlobal &&
                                                <Form.Group
                                                    className="form-group"
                                                >
                                                    <Form.Label>Cloud Services</Form.Label>
                                                    <NavDropdown title={selectCloudService} id="cloud-service-dropdown"
                                                        className={`custom-dropdown ${selectCloudService === "Select Cloud Service" ? "" : "selected-color"}`}>

                                                        <NavDropdown.Item
                                                            id="Cloud Services"
                                                            value="Select Cloud Service"
                                                            className={selectCloudService === "Select Cloud Service" ? "selected" : ""}
                                                            onClick={(e) => {
                                                                setCloudServices("Select Cloud Service");
                                                                setSelectCloudService("Select Cloud Service");
                                                                setAccountIdPlanNameAPI(null);
                                                                setSelectCloudPlanName("Select Plan Name");
                                                            }}

                                                        >
                                                            Select Cloud Service
                                                        </NavDropdown.Item>

                                                        {dropdownDataCloudServices.map((arrayItems) => {
                                                            return (<NavDropdown.Item
                                                                key={arrayItems.integrationCode}
                                                                id={arrayItems.accountId}
                                                                value={[arrayItems.serviceName]}
                                                                className={selectCloudService === arrayItems.serviceName ? "selected" : ""}
                                                                onClick={getCloudServicesDetails}>
                                                                {arrayItems.serviceName}
                                                            </NavDropdown.Item>);
                                                        })}
                                                    </NavDropdown>
                                                </Form.Group>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            {dropdownData && isGlobal &&
                                                <Form.Group
                                                    className="form-group"
                                                >
                                                    <Form.Label>Plan Name</Form.Label>
                                                    <NavDropdown title={selectCloudPlanName} id="cloud-service-dropdown"
                                                        className={`custom-dropdown ${selectCloudPlanName === "Select Plan Name" ? "" : "selected-color"}`}>
                                                        <NavDropdown.Item
                                                            id="planName"
                                                            value="Select Plan Name"
                                                            className={selectCloudPlanName === "Select Plan Name" ? "selected" : ""}
                                                            onClick={(e) => {
                                                                setPlanName("Select Plan Name");
                                                                setSelectCloudPlanName("Select Plan Name");
                                                                setSubId(null);
                                                                // setSelectCloudService("Select Cloud Service");
                                                                // setAccountIdPlanNameAPI(null);
                                                            }}
                                                        >
                                                            Select Plan Name
                                                        </NavDropdown.Item>

                                                        {dropdownData.map((arrayItems) => {
                                                            return (<NavDropdown.Item
                                                                key={arrayItems.subscriptionId}
                                                                id={arrayItems.subscriptionId}
                                                                value={arrayItems.planName}
                                                                className={selectCloudPlanName === arrayItems.planName ? "selected" : ""}
                                                                onClick={getSubscriptionDetails}>
                                                                {arrayItems.planName}
                                                            </NavDropdown.Item>);
                                                        })}
                                                    </NavDropdown>
                                                </Form.Group>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            {dropdownData && !isGlobal &&
                                                <>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group
                                                                className="form-group"
                                                            >
                                                                <Form.Label>Cloud Services</Form.Label>
                                                                <Form.Select aria-label="Default select"
                                                                    className="form-control" disabled>
                                                                    <option
                                                                        key={cloudServices}
                                                                        id={cloudServices}
                                                                        value={cloudServices}
                                                                    >{cloudServices}
                                                                    </option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group
                                                                className="form-group"
                                                            >
                                                                <Form.Label>Plan Name</Form.Label>
                                                                <Form.Select aria-label="Default select"
                                                                    className="form-control" disabled>
                                                                    <option
                                                                        key={planName}
                                                                        id={planName}
                                                                        value={planName}
                                                                    >{planName}
                                                                    </option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </Col>
                                        <Form.Group
                                            className="form-group col-md-6"
                                            controlId="subject"
                                        >
                                            <Form.Label>Subject <span className="red">*</span></Form.Label>

                                            <NavDropdown title={selectCloudSubject} id="cloud-service-dropdown"
                                                className={`custom-dropdown ${selectCloudSubject === "Select Subject" ? "" : "selected-color"}`}>

                                                {raiseAticketSubject.map((option) => {
                                                    return (<NavDropdown.Item
                                                        key={option}
                                                        id={option}
                                                        value={option}
                                                        className={selectCloudSubject === option ? "selected" : ""}
                                                        onClick={subjectChange}>
                                                        {option}
                                                    </NavDropdown.Item>);
                                                })}
                                            </NavDropdown>
                                        </Form.Group>
                                        {subject === 'Other' ?
                                            <Form.Group className="form-group col-md-6" controlId="other">
                                                <Form.Label>Other <span className="red">*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Subject"
                                                    value={otherSubject}
                                                    onChange={e => (e.target.value.length <= 200 ? setOtherSubject(e.target.value) : "")}
                                                />
                                            </Form.Group> : ""
                                        }


                                        <Form.Group className="form-group col-12" controlId="comments">
                                            <Form.Label>Message <span className="red">*</span></Form.Label>
                                            <Form.Control
                                                as="textarea" rows={5}
                                                value={comments}
                                                placeholder="Type Message Here"
                                                onChange={e => (e.target.value.length <= 2000 ? setComments(e.target.value) : "")}
                                            />
                                            <span className="text-small text-right w-100 d-block mt-1">({comments.length}/2000)</span>
                                        </Form.Group>


                                        <Form.Group
                                            className="form-group col-md-6"
                                            controlId="piority"
                                        >
                                            <Form.Label>Priority <span className="red">*</span></Form.Label>

                                            <NavDropdown title={selectCloudPriority} id="cloud-service-dropdown"
                                                className={`custom-dropdown ${selectCloudPriority === "Select Priority" ? "" : "selected-color"}`}>
                                                {ticketPriority.map((option) => {
                                                    return (<NavDropdown.Item
                                                        key={option}
                                                        id={option}
                                                        value={option}
                                                        className={selectCloudPriority === option ? "selected" : ""}
                                                        onClick={priorityChange}>
                                                        {option}
                                                    </NavDropdown.Item>);
                                                })}
                                            </NavDropdown>
                                        </Form.Group>
                                        <Form.Group className="form-group col-md-6"
                                            controlId="addMembers">
                                            <Form.Label className="col-12 d-flex justify-content-between">
                                                <span className="d-flex"><span className='d-flex align-items-center add-member-raise-a-ticket'>Add Members</span>
                                                    <OverlayTrigger
                                                        placement="top" overlay={renderTooltip({ infoText: "Any members added here will be copied in the reply. Please type email address and press enter to add." })}>
                                                        <span className="infoIcon-support mx-1"></span>
                                                    </OverlayTrigger>
                                                </span>
                                                {(members.length < 4) && <span className="float-right d-flex align-items-center add-member-raise-a-ticket"><span>Max 4</span></span>}
                                                {(members.length >= 4) && <span className="float-right d-flex align-items-center text-danger add-member-raise-a-ticket">Max 4</span>}
                                            </Form.Label>
                                            {(members.length < 4) &&
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Email Address"
                                                    ref={addMemeberField}
                                                    onChange={createMember}
                                                    onKeyDown={addMember}
                                                />
                                            }

                                            {(members.length >= 4) &&
                                                <Form.Control
                                                    type="text"
                                                    placeholder=""
                                                    ref={addMemeberField}
                                                    onChange={createMember}
                                                    onKeyDown={addMember}
                                                    disabled
                                                />
                                            }

                                            <Col className="member-options">

                                                {
                                                    members && members.map((member, index) => {
                                                        return (
                                                            <label key={index}>
                                                                <span>
                                                                    {member}
                                                                </span>
                                                                <XLg id={member} className="mx-3 remove-member" onClick={MemberListDelete} />
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Form.Group>
                                    </Row>
                                </Col>

                                <Row>
                                    <Form.Group style={{ marginTop: "-1.8rem" }}>
                                        <Col className="justify-content-end d-flex">
                                            {
                                                (!isGlobal && !subject && comments === "" && !priority && addMemeberField.current.value === "" && members.length === 0) && <Button
                                                    className="btn btn-border-light btn-lg mx-3"
                                                    variant='disabled'
                                                    disabled
                                                >
                                                    Reset
                                                </Button>
                                            }
                                            {
                                                (isGlobal && !subject && comments === "" && !priority && addMemeberField.current.value === "" && members.length === 0) && <Button
                                                    className="btn btn-border-light btn-lg mx-3"
                                                    variant='disabled'
                                                    disabled
                                                >
                                                    Reset
                                                </Button>
                                            }

                                            {isGlobal && (subject || comments !== "" || priority || addMemeberField.current.value !== "" || members.length > 0) &&

                                                <Button
                                                    className="btn btn-border-light btn-lg mx-3"
                                                    variant='light'
                                                    type="reset"
                                                    onClick={resetForm}
                                                >
                                                    Reset
                                                </Button>
                                            }

                                            {!isGlobal && (subject || comments !== "" || priority || addMemeberField.current.value !== "" || members.length > 0) &&

                                                <Button
                                                    className="btn btn-border-light btn-lg mx-3"
                                                    variant='light'
                                                    type="reset"
                                                    onClick={resetForm}
                                                >
                                                    Reset
                                                </Button>
                                            }

                                            {
                                                ((subject !== "Other" && subject && comments && priority) || (subject === 'Other' && otherSubject.length > 0 && comments && priority)) && <Button
                                                    className="btn btn-lg"
                                                    onClick={raiseATicket}
                                                >
                                                    Submit
                                                </Button>
                                            }

                                            {
                                                (((!subject || (comments === "" || !priority)) || (subject === 'Other' && otherSubject.length === 0))) && <Button
                                                    className="btn btn-lg"
                                                    variant='disabled'
                                                    disabled
                                                >
                                                    Submit
                                                </Button>
                                            }
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </Form>

                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default RaiseATicket;